import {Box, Divider, IconButton, Typography} from "@mui/material";
import Stepper from "../../Stepper";
import RemoveIcon from "../../../icons/delete.svg";
import List from "../../List";
import {useContext} from "react";
import {observer} from "mobx-react-lite";
import {AppContext, CatalogContext, FavoritesContext, UserContext} from "../../../store";
import {Group} from "../../../store/cart";
import {Link} from "react-router-dom";
import {getItemsSet} from "../../../../ts-shared/promo";
import PromoPageWideCard from "../PromoPageWideCard";

type Props = {
    readonly group: Readonly<Group>;
    readonly onSetsChange: (count: number) => void;
    readonly onRemoveClick?: () => void;
    readonly onItemClick?: (it: any) => void;
}

const MyPromoCard = observer(
    (item: { item, events: { onClick?: (it: any) => void } }) => {

        const app = useContext(AppContext);
        const favorites = useContext(FavoritesContext);
        const user = useContext(UserContext);

        const isFavorite = favorites.includes(item.item.id);

        return (
            <PromoPageWideCard
                item={item.item}
                count={item.item.quantity}
                favorite={isFavorite}
                onClick={() => {
                    item.events?.onClick && item.events.onClick(item.item)
                }}
                onFavoritesClick={async () => {
                    const { orderPrice, ...cartItem } = item.item;
                    if(isFavorite) {
                        await favorites.remove(cartItem);
                        app.alert(`Удалено из избранного`);
                    }
                    else {
                        await favorites.add(cartItem);
                        app.alert(`Добавлено в избранное`);
                    }
                }}
            />
        );
    }
);

const PromoPageGroup = (p: Props) => {
    const group = p.group;
    const catalog = useContext(CatalogContext);

    const context = catalog.promoContext;

    const { maxSetsCount } = context ? getItemsSet(
        p.group.promoId,
        group.items.map(v => ({ id: v.id, count: v.quantity, stock: v.count, price: v.price })),
        1,
        context,
    ) : { maxSetsCount: 1 };

    return (<>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component={Link} variant="h6" to={`/promo/${group.promoId}`}>{group.name}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ mr: 1, display: { xs: 'none', md: 'block' }}}>Комплекты:</Box>
                <Stepper
                    value={group.count}
                    max={maxSetsCount}
                    onChange={p.onSetsChange}
                />
                {p.onRemoveClick && (
                    <IconButton
                        aria-label="remove"
                        sx={{ ml: 4 }}
                        onClick={p.onRemoveClick}
                    >
                        <RemoveIcon />
                    </IconButton>
                )}
            </Box>
        </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Box sx={{ pl: 2 }}>
            <List
                items={{ list: group.items }}
                component={MyPromoCard}
                keyResolver={it => `${it.id}_${it.price?.toFixed(2) || 0}`}
                events={{
                    onClick: p.onItemClick,
                }}
            />
        </Box>
    </>);
}

export default observer(PromoPageGroup);