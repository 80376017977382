import List from '@mui/material/List';
import { FC, useEffect, useRef } from "react";
import {
    Box,
    Skeleton, 
    Stack,
} from "@mui/material";
import CheckBox, { CheckboxProp } from "./Property/CheckBox";
import { Prop } from "./Property";
import Range, { RangeProp } from "./Property/Range";
import Expander from "./Property/Common/Expander";
import Select, { SelectProp } from './Property/Select';
import { range } from 'lodash';

export type Props = {
    readonly selected: any;
    readonly state?: Prop[],
    readonly props?: Prop[],
    readonly onDirtyValueChange?: (el: Element) => void;
    readonly onStateChange: (state: Prop[], apply: boolean) => void;
    readonly onClearClick: (id?: string) => void;
};

const stateFromSelected = (props: Props["props"], selected?: Props["selected"]) => {
    if (!selected) selected = {};
    const state = [...props];
    for (const id in selected) {
        const prop = state.find(p => p.id == id);
        prop.opened = true;
        switch (prop.type) {
            case 'checkbox':
                const sl = (selected[id] as number[]).reduce((s, p) => (s[p] = true, s), {});
                prop.isSet = !!Object.keys(sl).length;
                for (const value of prop.values)
                    value.checked = !!sl[value.id];
                break;
            case 'select':
                prop.isSet = !!selected[id];
                prop.selectedId = selected[id][0];
                

                break;
            case 'range':
                prop.isSet = Object.keys(selected[id]).length == 2;
                if (prop.isSet)
                    [prop.from, prop.to] = selected[id];
                break;
        }
        prop.opened = prop.isSet;

    }

    return state;
};

const PropSelector: FC<{
    readonly prop: Prop,
    readonly state: Prop[],
    readonly onStateChange: (el: Element, state: Prop[]) => void,
    readonly onInputComplete?: (el: Element) => void,
}> = p => {
    const { prop } = p;

    const containerRef = useRef();

    return (
        <Box ref={containerRef} >
            {prop.type === 'checkbox' ? (
                <CheckBox
                    prop={prop}
                    onInputComplete={() => {
                        p.onInputComplete && p.onInputComplete(containerRef.current);
                    }}
                    onItemClick={e => {
                        e.stopPropagation();

                        const checkboxPropIdx = p.state.findIndex(v => v.id == prop.id);
                        const checkboxProp = p.state[checkboxPropIdx] as CheckboxProp;

                        const { values } = checkboxProp;
                        const valueIdx = values.findIndex(v => v.id == e.itemId);
                        const newValue = {
                            ...values[valueIdx],
                            checked: !values[valueIdx].checked,
                        };

                        const newValues = [...values];
                        newValues[valueIdx] = newValue;
                        const newProp = {
                            ...checkboxProp,
                            values: newValues
                        } as CheckboxProp;

                        if (newValue.checked)
                            newProp.isSet = true;
                        else
                            newProp.isSet = !!newProp.values.filter(v => v.checked).length;

                        const newState = [...p.state];
                        newState[checkboxPropIdx] = newProp;
                        p.onStateChange(containerRef.current, newState);
                    }}
                />
            ) : undefined}
            {prop.type === 'select' ? (
                <Select
                    prop={prop}
                    onInputComplete={() => {
                        p.onInputComplete && p.onInputComplete(containerRef.current);
                    }}
                    onItemClick={e => {
                        e.stopPropagation();

                        const selectPropIdx = p.state.findIndex(v => v.id == prop.id);
                        const selectProp = p.state[selectPropIdx] as SelectProp;

                        const { values } = selectProp;
                        const value = values.find(v => v.id == e.itemId);


                        const newProp = {
                            ...selectProp,
                            selectedId: value?.id
                        } as SelectProp;

                        newProp.isSet = !!newProp.selectedId;

                        const newState = [...p.state];
                        newState[selectPropIdx] = newProp;
                        p.onStateChange(containerRef.current, newState);
                    }}
                />
            ) : undefined}
            {prop.type === 'range' ? (
                <Range
                    prop={prop}
                    onInputComplete={() => {
                        p.onInputComplete && p.onInputComplete(containerRef.current);
                    }}
                    onChange={(e, f, t) => {

                        const from = f == prop.settings.min ? undefined : f;
                        const to = t == prop.settings.max ? undefined : t;

                        const rangePropIdx = p.state.findIndex(v => v.id == prop.id);
                        const checkboxProp = p.state[rangePropIdx] as RangeProp;

                        const newState = [...p.state];
                        newState[rangePropIdx] = {
                            ...checkboxProp,
                            isSet: !!(from || to),
                            from,
                            to,
                        };
                        p.onStateChange(containerRef.current, newState);
                    }}
                />
            ) : undefined}
        </Box>
    );
};

const MyExpander: FC<{
    prop: Prop,
    state: Prop[],
    onStateChange: (state: Prop[]) => void,
    onInputComplete?: (el: Element) => void,
    onClearClick: (e: React.MouseEvent) => void;
}> = (p) => {
    const ref = useRef();

    return (
        <Box ref={ref}>
            <Expander
                key={p.prop.id}
                label={p.prop.name}
                opened={p.prop.opened}
                clearable={p.prop.isSet}
                onCollapseClick={() => {

                    const propIdx = p.state.findIndex(_p => _p.id == p.prop.id);
                    const prop = p.state[propIdx] as Prop;
                    const newState = [...p.state];
                    newState[propIdx] = {
                        ...prop,
                        opened: !prop.opened
                    };
                    p.onStateChange(newState);
                }}
                onClearClick={p.onClearClick}
            >
                <PropSelector
                    prop={p.prop}
                    state={p.state}
                    onStateChange={(el, s) => {
                        p.onStateChange(s);
                    }}
                    onInputComplete={p.onInputComplete && (() => p.onInputComplete(ref.current))}
                />
            </Expander>
        </Box>
    );
}

const Content: FC<Props> = (p) => {
    useEffect(() => {
        p.onStateChange(p.props ? stateFromSelected(p.props, p.selected) : undefined, false);
    }, [p.selected, p.props]);

    if (!p.state) return (<>
        {range(13).map(i => (
            <Box key={i} sx={{ mb: 2 }}>
                <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                />
            </Box>
        ))}
    </>);

    return (<>
        <List spacing={2} component={Stack}>
            {p.state.map(pr => (
                <MyExpander
                    key={pr.id}
                    prop={pr}
                    state={p.state}
                    onInputComplete={p.onDirtyValueChange}
                    onStateChange={s => {
                        p.onStateChange(s, false);
                    }}
                    onClearClick={e => {
                        e.stopPropagation();
    
                        p.onClearClick(pr.id);
                    }}
                />
            ))}
        </List>
    </>);
};

export default Content;