import {FC, ReactNode} from "react";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    useTheme
} from "@mui/material";
import {Link} from "react-router-dom";
import PdfIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ExcelIcon from "@mui/icons-material/TextSnippetOutlined";
import ContextPopover from "../ContextPopover";
import {Dayjs} from "dayjs";

type Props = {
    readonly id: string;
    readonly status: number;
    readonly payUntil?: Dayjs;
    readonly placeholder?: ReactNode;
};



const GetOrderInvoiceLink: FC<Props> = (p) => {
    const theme = useTheme();

    const showLink = [1,2,3,5,6,7].includes(p.status);

    if(!showLink) 
        return <>{p.placeholder}</>;

    return (<>
        <ContextPopover
            component={Link}
            sx={{ ml: '5px', alignSelf: 'center' }}
            to={`/order/${p.id}/invoice/pdf`}
            target="_blank"
            color={theme.palette.warning.main}
            content={
                <List
                    subheader={
                        <ListSubheader component="div">
                            Тип документа
                        </ListSubheader>
                    }
                >
                    <ListItem disablePadding>
                        <ListItemButton
                            href={`/order/${p.id}/invoice/pdf`}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <PdfIcon />
                            </ListItemIcon>
                            <ListItemText primary="PDF (с подписью)" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            href={`/order/${p.id}/invoice/xls`}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <ExcelIcon />
                            </ListItemIcon>
                            <ListItemText primary="XLS" />
                        </ListItemButton>
                    </ListItem>
                </List>
            }
        >
            Счет на оплату
        </ContextPopover>

    </>);

}

export default GetOrderInvoiceLink;