import { FC, useContext, useState } from "react";
import Card from "../Card";
import { Item } from "../../../common/item";
import { Button } from "@mui/material";
import { BonusContext } from "../../../store";
import { observer } from "mobx-react-lite";
import { ItemVariants } from "../../../store/catalog";

type Props = {
    readonly item: Item;
    readonly inCartCount: number;
    readonly favorite: boolean;
    readonly onAddToCartClick: (count: number, variant: Item) => Promise<any>;
    readonly onFavoritesClick: () => void;
    readonly onClick?: () => void;
    readonly alwaysExpanded?: boolean;
    readonly variant?: Item;
    readonly variants?: ItemVariants;
    readonly currentVariantId?: number;
    readonly onCurrentVariantIdChange?: (value: number) => void;
}

const CatalogCard: FC<Props> = (p) => {
    const count = p.item.quantity || 1;
    const [hover, setHover] = useState(false);

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: p.item.quantity || 1,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });


    const item = p.variant || p.item;

    return (
        <Card
            id={item.id}
            variants={p.item.variants}
            variantsFull={p.variants}
            aliasFull={item.aliasFull}
            imgSrc={item.imgCard}
            label={item.label}
            count={count}
            stock={item.count}
            price={item.price}
            oldPrice={item.oldPrice}
            outOfStock={!item.count}
            articl={item.articl}
            cnpCode={item.cnp_code}
            packageSize={item.pkg}
            favorite={p.favorite}
            expanded={hover}
            alwaysExpanded={p.alwaysExpanded}
            onHoverChange={setHover}
            inCartCount={p.inCartCount}
            onFavoritesClick={p.onFavoritesClick}
            onClick={p.onClick}
            bonusValue={bonusValue}
            currentVariantId={p.currentVariantId}
            variantName={item.variantName}
            onCurrentVariantIdChange={p.onCurrentVariantIdChange}
            miniProps={p.item.miniProps}
            action={
                <Button
                    sx={{ flexGrow: 1 }}
                    color={p.inCartCount ? 'success' : 'info'}
                    variant="contained"
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();

                        p.onAddToCartClick(count, item);
                    }}
                >
                    {p.inCartCount ? "В корзине" : "В корзину"}
                </Button>
            }
        />
    );

}

export default observer(CatalogCard);