import { Dayjs } from "dayjs";
import * as dayjs from 'dayjs';
import axios, { AxiosResponse } from "axios";
import Fingerprint from "../fingerprint";

export type Response = AxiosResponse<{
    readonly accessToken: string;
}>;

const accessToken = (() => {
    let accessToken: string|undefined;
    let expiresAt: Dayjs|undefined;
    let process: Promise<string>|undefined;
    let processInterrupted = false;

    const obtainToken: () => Promise<string> = () => 
    Fingerprint
    .get()
    .then(fingerprint => {
        processInterrupted = false;

        return axios.put<Response>(
            '/auth/session',
            undefined,
            { 
                withCredentials: true,
                headers: {
                    Authorization: fingerprint
                }
            }
        );
    })
    .then(({ data }) => {
        if(processInterrupted)
            return obtainToken();
            
        accessToken = data.data.accessToken;
        const [ , payloadStr ] = accessToken.split('.');
        const { exp } = JSON.parse(window.atob(payloadStr));
        expiresAt = dayjs.unix(exp).subtract(10, 'second');
        
        return accessToken;
    })
    .catch(e => {
        accessToken = undefined;
        expiresAt = undefined;
        return Promise.reject(e);
    })
    .finally(() => {
        process = undefined;
        processInterrupted = false;
    });

    return {
        invalidate: () => {
            expiresAt = undefined;
            if(process)
                processInterrupted = true;
        },
        get: async (): Promise<string> => {
            if(process) return process;
            const expiring = expiresAt ? expiresAt.isBefore(dayjs()) : true;
            if(!expiring) return Promise.resolve(accessToken!);

            processInterrupted = false;
            process = obtainToken();

            return process;
        },
    };
})();

export default accessToken;