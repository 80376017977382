
const fingerprint = (() => {

    return {
        // TODO: Fingerpint.js постоянно дает разные результаты, поэтому она отключена.
        // Нужно найти другие способы получения fingerprint или вообще отказаться от него
        get: async () => 'fCDmXK7qX75Ads9wQsMl',
    };
})();

export default fingerprint;