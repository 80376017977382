import { FormControl, MenuItem } from "@mui/material";
import { BaseProp } from "../";
import { FC } from "react";
import { Select as MuiSelect } from "@mui/material";

type SelectValue = {
    id: string;
    value: string;
};

interface ItemClickEvent extends React.MouseEvent {
    itemId: null|string;
}

export type SelectProp = BaseProp & {
    type: 'select';
    values?: SelectValue[];
    selectedId?: null|string;
};

type Props = {
    readonly prop: SelectProp;
    readonly onItemClick?: (e: ItemClickEvent) => void;
    readonly onScroll?: () => void;
    readonly onInputComplete?: () => void;
};

const Select: FC<Props> = (props) => {
    const { values, selectedId } = props.prop;
    const postfix = props.prop.postfix || '';

    return (
        <FormControl fullWidth>
            <MuiSelect
                displayEmpty
                value={selectedId || ''}
                onChange={_e => {
                    if (!props.onItemClick) return;

                    const e: ItemClickEvent = _e as any;
                    e.itemId = _e.target.value || null;

                    props.onItemClick(e);
                    props.onInputComplete && props.onInputComplete();
                }}
            >
                <MenuItem value="">
                    <em>Не выбрано</em>
                </MenuItem>
                {values.map(({ id, value }) => (
                    <MenuItem key={id} value={id} children={`${value}${postfix}`} />
                ))}
            </MuiSelect>
        </FormControl>
    );
};

export default Select;