import {FC, useContext, useState} from "react";
import WideCard from "../WideCard";
import WideCardOverviewAction from "../WideCardOverviewAction";
import {OrderItem} from "../../../store/order";
import {BonusContext, CartContext, CatalogContext} from "../../../store";
import WideCardStepperAction from "../WideCardStepperAction";
import { observer } from "mobx-react-lite";

type Props = {
    readonly item: OrderItem;
    readonly inCart: boolean;
    readonly favorite: boolean;
    readonly onAddToCartClick: () => void;
    readonly onFavoritesClick: () => void;
    readonly onCountChange?: (count: number) => void;
    readonly onRemoveClick?: () => void;
    readonly outOfStock?: boolean;
    readonly editMode?: boolean;
    readonly removed?: boolean;
    readonly bonus?: {
        value: number;
        deactivated: boolean;
    };
}

const OrderWideCard: FC<Props> = (p) => {
    const cart = useContext(CartContext);
    const bonus = useContext(BonusContext);
    const bonusValue = p.editMode ? bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: (p.item.item?.count || 0) <= 0,
        price: p.item.item?.price,
        quantity: (p.item.item?.quantity || p.item.snapshot.quantity) || 1,
        bonusFactor: p.item.item.bonusFactor,
        maxDiscountFactor: p.item.item.maxBonusDiscountFactor,
    }) : 0;

    
    const catalog = useContext(CatalogContext);
    const variants = catalog.getItemVariants(p.item.id);

    const [currentVariantId, setCurrentVariantId] = useState(p.item.item?.variants ? 0 : undefined);

    const action = p.editMode 
        ? (
            <WideCardStepperAction
                isPromotional={!!p.item.item.isPromotional}
                count={(p.item.item.quantity || p.item.snapshot.quantity) || 1}
                price={p.item.item.price}
                oldPrice={p.item.item.oldPrice}
                stock={p.item.item.count}
                onCountChange={p.onCountChange}
                onRemoveFromCartClick={p.onRemoveClick}
                outOfStock={p.item.item.count <= 0}
                bonusValue={bonusValue}
                variants={p.item.item?.variants || []}
                variantsFull={variants}
                currentVariantId={currentVariantId}
            />
        )
        : (
            <WideCardOverviewAction
                count={p.item.snapshot.quantity}
                oldCount={p.item.snapshot.prevQuantity}
                price={p.item.snapshot.price}
                oldPrice={p.item.snapshot.prevPrice}
                outOfStock={!p.item.snapshot.inStock}
                inCart={p.inCart}
                onAddToCartClick={p.item.item && p.onAddToCartClick}
                bonus={p.bonus || { value: 0, deactivated: false }}
            />
        )
    ;

    return (
        <WideCard
            id={p.item.id}
            action={action}
            imgSrc={p.item.item?.imgList}
            label={p.item.snapshot.label}
            highlighted={p.item.snapshot.state === 1}
            faded={p.removed || p.item.snapshot.state === 2}
            favorite={p.favorite}
            articl={p.item.item?.articl}
            cnpCode={p.item.item?.cnp_code}
            packageSize={p.item.item?.pkg}
            stock={p.item.item?.count}
            onFavoritesClick={p.onFavoritesClick}
            inCartCount={cart.itemsCount(p.item.id)}
            count={p.item.snapshot.quantity}
            aliasFull={p.item.item?.aliasFull || ''}
            variants={p.item.item?.variants || []}
            variantsFull={variants}
            currentVariantId={currentVariantId}
            onCurrentVariantIdChange={setCurrentVariantId}
        />
    );
}

export default observer(OrderWideCard);