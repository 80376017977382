import * as React from "react";
import {FC, useContext, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {AppContext} from "../../../store";
import ErrorPageTemplate from "../../ErrorPageTemplate";

type Props = {

};

const NotFoundView: FC<Props> = () => {
    const app = useContext(AppContext);

    useEffect(() => {
        app.setTitle('Не найдено');
    });

    return <ErrorPageTemplate code={404} message="Страница не найдена" />;
};

export default observer(NotFoundView);