import { FC, useContext } from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import Date from "../../Date";
import { formatPrice as $ } from "../../../utils";
import GetOrderInvoiceLink from "../../GetOrderInvoiceLink";
import { status } from "../ListTable";
import { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import BonusBadge from "../../bonus/BonusInfo/BonusBadge";
import { BonusContext, UserContext } from "../../../store";

type Props = {
    readonly id: string;
    readonly date: Dayjs;
    readonly payUntil?: Dayjs;
    readonly price: number;
    readonly comment?: string;
    readonly status: number;
    readonly recipient?: {
        readonly id: number;
        readonly name: string;
    };
    readonly bonus?: {
        readonly added: number;
        readonly subtracted: number;
        readonly deactivated: number;
    }
};

const Card: FC<Props> = (p) => {
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);

    const bonusValue = (p.bonus?.added || 0) - (p.bonus?.subtracted || 0);

    const bonusSubstracted = -p.bonus?.subtracted || 0;

    return (
        <Box
            sx={{ ...{ display: 'flex', width: '100%' } }}
            onClick={() => navigate(`/order/${p.id}`)}
        >
            <Box sx={{ background: '#F0EFFB', p: 2, borderRadius: '8px 0 0 8px' }}>
                <Typography component={Stack} spacing={1} variant="body1" color="text.secondary">
                    <span>Номер заказа</span>
                    <span>Дата</span>
                    <span>Сумма</span>
                    {!user.user.childrenCount ? undefined : (
                        <span>Грузополучатель</span>
                    )}
                    {bonus.status?.enabled && (
                        <span>Бонусы</span>
                    )}
                    <span>Статус</span>
                    {p.comment && (
                        <span>Комментарий</span>
                    )}
                </Typography>
            </Box>
            <Paper elevation={0} sx={{ p: 2, flexGrow: 2, borderRadius: '8px 0 0 8px' }}>
                <Typography
                    component={Stack}
                    spacing={1}
                    variant="body1"
                    fontWeight="bold"
                    sx={{
                        overflow: 'hidden',
                        wordBreak: 'break-word',
                        wordWrap: 'wrap',
                    }}
                >
                    <span>{p.id}</span>
                    <span><Date date={p.date} /></span>
                    <span>{$(p.price)}</span>
                    {!user.user.childrenCount ? undefined : (
                        <span>{p.recipient?.name || '-'}</span>
                    )}
                    {!(bonus.status?.enabled && bonusValue) ? undefined : (
                        <Box display="flex">
                            <BonusBadge
                                mode="order"
                                faded={!!p.bonus?.deactivated}
                                value={bonusValue}
                            />
                            {bonusSubstracted < 0 && (
                                <Box>
                                    <BonusBadge
                                        mode="order"
                                        faded={!!p.bonus?.deactivated}
                                        value={bonusSubstracted}
                                    />
                                </Box>
                            )}
                        </Box>


                    )}
                    <Box >
                        {p.status === 0 && (
                            <Typography sx={{ color: "#6C5DD3" }}>{status[p.status]}</Typography>
                        )}
                        {p.status === 1 && (
                            <Typography sx={{ color: "#244CB2" }}>{status[p.status]}</Typography>
                        )}
                        {p.status === 2 && (
                            <Typography sx={{ color: "#22C232" }}>{status[p.status]}</Typography>
                        )}
                        {p.status === 3 && (
                            <Typography sx={{ color: "#FF9A7B" }}>{status[p.status]}</Typography>
                        )}
                        {/*  */}
                        {p.status === 4 && (
                            <Typography sx={{ color: "#f70b0b" }}>{status[p.status]}</Typography>
                        )}
                        {p.status === 5 && (
                            <Typography sx={{ color: "#FF754C" }}>{status[p.status]}</Typography>
                        )}
                        {p.status === 6 && (
                            <Typography sx={{ color: "#FF754C" }}>{status[p.status]}</Typography>
                        )}
                        {p.status === 7 && (
                            <Typography sx={{ color: "#22C232" }}>{status[p.status]}</Typography>
                        )}
                        {p.status === 8 && (
                            <Typography sx={{ color: "#FF754C" }}>{status[p.status]}</Typography>
                        )}
                        {p.status === 9 && (
                            <Typography sx={{ color: "#f70b0b" }}>{status[p.status]}</Typography>
                        )}
                    </Box>
                    <span>{p.comment || ''}</span>
                    <GetOrderInvoiceLink id={p.id} status={p.status} payUntil={p.payUntil} />
                </Typography>
            </Paper>
        </Box>
    );
}

export default Card;