import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import BonusInfo from "../../../bonus/BonusInfo";
import { BonusContext } from "../../../../store";
import { Item as BonusItem, calculateOrderBonusInfo } from "../../../../../ts-shared/bonus";

type Props = {
    readonly useBonuses: boolean;
    readonly onUseBonusChange: (useBonuses: boolean) => void;
    readonly items: BonusItem[];
}

const OrderApprovalBonus: FC<Props> = (p) => {
    const bonus = useContext(BonusContext);

    const balance = bonus.status?.balance;

    const info = calculateOrderBonusInfo(p.items, balance);

    return (
        <BonusInfo
            mode="cart"
            available={info.available}
            toGive={info.toGive}
            useBonuses={p.useBonuses}
            onUseBonusesChange={p.onUseBonusChange}
        />
    );
}

export default observer(OrderApprovalBonus);