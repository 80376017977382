import {userMatrixCodeNotEq, userTypeNotEq, userIdNotEq, userMatrixCodeEq, userIdEq, userBonusesEnabled} from "./user";

export type User = {
    id: number;
    type: string;
    matrixCode: number;
    parentPriceCode?: string;
    bonusesEnabled: boolean;
}

export type UserChecker = {
    id: UserPredicateId;
    args: [];
}[];

export type UserPredicateId = string;
export type UserPredicate = {
    name: string;
    description?: string;
    fn: (user: Readonly<User>, ...args) => boolean;
}

const predicates: Record<UserPredicateId, UserPredicate> = {
    userIdEq: {
        name: 'Id пользователя равен',
        fn: userIdEq,
    },
    userIdNotEq: {
        name: 'Id пользователя НЕ равен',
        fn: userIdNotEq,
    },
    userTypeNotEq: {
        name: 'Тип пользователя НЕ равен',
        fn: userTypeNotEq,
    },
    userMatrixCodeNotEq: {
        name: 'Код матрицы пользователя НЕ равен',
        fn: userMatrixCodeNotEq,
    },
    userMatrixCodeEq: {
        name: 'Код матрицы пользователя равен',
        fn: userMatrixCodeEq,
    },
    userBonusesEnabled: {
        name: 'Бонусы пользователя включены',
        fn: userBonusesEnabled,
    }
};

export function getPredicates(): Readonly<Record<UserPredicateId, Readonly<UserPredicate>>> {
    return predicates;
}

export function getPredicate(id: UserPredicateId): Readonly<UserPredicate> {
    return predicates[id];
}

export function executePredicates(user: Readonly<User>, checker?: null|ReadonlyArray<Readonly<UserChecker[0]>>): boolean {
    if(!checker) return true; // Если нет никаких условий, то значит нет и никаких ограничений.

    return checker.every(p => {
        const predicate = getPredicate(p.id);
        if(!predicate) {
            console.error(`Предикат с именем ${p.id} не найден`);
            return false;
        }

        return predicate.fn(user, ...p.args);
    });
}