import { FC } from "react";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import LineThrough from "../../LineThrough";
import BatchItemsPrice from "../BatchItemsPrice";
import BonusBadge from "../../bonus/BonusInfo/BonusBadge";

type Props = {
    readonly isPromotional?: boolean;
    readonly inCart: boolean;
    readonly count: number;
    readonly oldCount?: number;
    readonly outOfStock?: boolean;
    readonly price: number;
    readonly oldPrice?: number;
    readonly bonus?: {
        value: number;
        deactivated: boolean;
    };
    readonly onAddToCartClick?: () => void;
};

const WideCardOverviewAction: FC<Props> = (p) => {
    const bonusValue = p.bonus?.value;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="end">
            <Box sx={{ display: 'flex', alignItems: "center", pr: 1 }}>
                {p.oldCount && (
                    <LineThrough sx={{ px: 1, mr: 1 }}>
                        <Typography variant="body1">
                            {p.oldCount}
                        </Typography>
                    </LineThrough>
                )}
                {!(!p.outOfStock && bonusValue) ? undefined : (
                    <BonusBadge
                        mode="catalogItem"
                        customMessage={p.bonus.deactivated ? `Баллы начислятся после оплаты заказа` : `Начислено за товар`}
                        faded={p.bonus.deactivated}
                        value={bonusValue}
                    />
                )}
                <Chip label={p.count} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "end", flexDirection: "column" }}>
                {p.isPromotional && (<>
                    <Chip label="АКЦИЯ" color="warning" sx={{ color: 'white' }} />
                </>)}
                {!p.isPromotional && (
                    !p.outOfStock ? (
                        <BatchItemsPrice
                            count={p.count}
                            price={p.price}
                            oldPrice={p.oldPrice}
                        />
                    ) : (
                        <Box>
                            <Typography variant="subtitle1" color="error">
                                Под заказ
                            </Typography>
                            <Typography variant="body2" color="info">
                                По стоимости и срокам доставки уточняйте у оператора
                            </Typography>
                        </Box>
                    )
                )}
                {p.onAddToCartClick ? (
                    <Button
                        sx={{
                            whiteSpace: 'nowrap',
                            backgroundColor: p.inCart && !p.outOfStock ? '#FF754C' : '#3F8CFF',
                            border: "none",
                            '&:hover': {
                                color: "white",
                                border: "none",
                                backgroundColor: "#FF754C"
                            }
                        }}
                        color="info"
                        size="small"
                        variant="outlined"
                        onClick={e => {
                            e.stopPropagation();
                            p.onAddToCartClick();
                        }}
                    >
                        {p.inCart && !p.outOfStock ? "В корзине" : (p.outOfStock ? "Под заказ" : "В корзину")}
                    </Button>
                ) : (
                    <Button disabled
                        sx={{ whiteSpace: 'nowrap' }}
                        size="small"
                        variant="outlined"
                        color="info"
                    >
                        Недоступен
                    </Button>
                )}
            </Box>

        </Stack>
    );
}

export default WideCardOverviewAction;