import {FC, useContext, useState} from "react";
import {
    Stack,
    TextField, Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { AppContext, OrderContext } from "../../store";
import { Item } from "../../common/item";

type Props = {
    readonly item: Item;
};

const QuickBuyDialog: FC<Props> = (p) => {
    let [ name, setName ] = useState('');
    let [ phone, setPhone ] = useState('');

    const app = useContext(AppContext);
    const order = useContext(OrderContext);

    const [callProcessing, setCallProcessing] = useState(false);

    return (
        <Stack
            sx={{ maxWidth: '300px' }}
            onSubmit={e => {
                e.preventDefault();
                name = name.trim();
                phone = phone.trim();
                if(!name.length && !phone.length) return;

                setCallProcessing(true);
                order.createOrder([{ item: p.item, count: 1 }], phone, name)
                    .then(() => {
                        app.closeDialog();
                        app.alert('Спасибо за заказ! Скоро мы Вам перезвоним', { severity: 'success' });
                    })
                    .finally(() => setCallProcessing(false))
                ;
            }} 
            component="form" 
            spacing={2}
        >
            <Typography variant="h5" >
                Быстрый заказ
            </Typography>
            <Typography variant="body2">
                Оставьте Ваш номер телефона и мы свяжемся с Вами для подтверждения заказа
            </Typography>
            <TextField
                size="small"
                variant="outlined"
                placeholder="Ваше имя"
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <TextField required
                size="small"
                variant="outlined"
                placeholder="Ваш телефон *"
                value={phone}
                onChange={e => setPhone(e.target.value)}
            />
            <LoadingButton loading={callProcessing} type="submit" sx={{ width: '100%' }} variant="contained" color="primary">Позвоните мне</LoadingButton>
        </Stack>
    );
}

export default QuickBuyDialog;