import {FC} from "react";
import {Box, BoxProps, Divider, useTheme} from "@mui/material";
import * as React from "react";

type Props = BoxProps & {

}

const LineThrough: FC<Props> = (p) => {
    const theme = useTheme();

    return (
        <Box {...p} sx={{ position: 'relative', display: 'flex', alignItems: 'center', ...p.sx }}>
            {p.children}
            <Divider sx={{position: 'absolute', left: '-5%', top: '50%', width: '110%'}}
                     color={theme.palette.warning.main} variant="fullWidth"/>
        </Box>
    );
}

export default LineThrough;