export type Permission = keyof typeof permissionsRecord;

const permissionsRecord = {
    itemsUploads_read: 1,
    itemsUploads_write: 1,

    items_read: 1,
    items_write: 1,

    products_read: 1,
    products_write: 1,
    
    usersAdmins_read: 1,
    usersAdmins_write: 1,

    role_read: 1,
    role_write: 1,
    
    maintenance_read: 1,
    maintenance_write: 1,


    // promo_read: 1,
    // promo_write: 1,

    // discount_read: 1,
    // discount_write: 1,

    // banners_read: 1,
    // banners_write: 1,

    // users_read: 1,
    // users_write: 1,

    // stats_read: 1,
    // stats_write: 1,

    // reports_read: 1,
    // reports_write: 1,

    // searchEngine_read: 1,
    // searchEngine_write: 1,

    // bonusTransaction_read: 1,
    // bonusTransaction_write: 1,
};


export const PERMISSIONS: ReadonlyArray<Permission> = Object.keys(permissionsRecord) as any;