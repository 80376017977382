import {createContext} from "react";
import {action, computed, makeObservable, observable, toJS} from "mobx";
import {merge, Store} from "./index";
import {request} from "../utils";
import dayjs, {Dayjs} from "dayjs";
import getDatabase from "../services/DexieDB";
import {debounce} from "lodash";

export type IntegrationAPISessionScope = 'cart_read' | 'cart_write';

export type IntegrationAPISession = {
    readonly id: number;
    readonly accessTokenPart: string;
    readonly name: string;
    readonly scopes: IntegrationAPISessionScope[];
    readonly createdAt: Dayjs;
};

export type State = {
    readonly integration?: {
        readonly apiSessions: {
            readonly list: IntegrationAPISession[];
        };
    };
    readonly parent?: null | {
        readonly id: number;
        readonly name: string;
    };
    readonly children?: {
        readonly id: number;
        readonly name: string;
    }[];
    readonly feedback?: {
        readonly text?: null|string;
    }
};

const scopes = { cart_write: true };
export type Scope = keyof typeof scopes;
export const SCOPES = Object.keys(scopes);

export class Profile {
    _store: Store = undefined;
    _state: State = {};

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            integration: computed,
            feedbackText: computed,
            setState: action,
        })
    }

    setState(state: State | undefined) {
        const list = state.integration?.apiSessions.list;
        if(list) {
            for(let i = 0; i < list.length; i++) {
                if(typeof list[i].createdAt === 'string')
                    (list[i] as any).createdAt = dayjs(list[i].createdAt);
            }
        }

        this._state = merge(this._state, state || {});
    }

    createAccessToken(name: string, scopes: Scope[]): Promise<{ accessToken: string }> {

        const fd = new FormData();
        fd.append('name', name);
        for(const scope of scopes)
            fd.append('scopes[]', scope);

        return request({
            method: 'POST',
            url: `/integrations/access_token`,
            data: fd
        })
        .then(res => {
            const data = res.data.data as { id: number, accessToken: string, createdAt: string };
            const { id, accessToken } = data;
            const createdAt = dayjs(data.createdAt);

            const tokens = toJS(this._state.integration.apiSessions.list);
            tokens.push({
                id, scopes, name,
                accessTokenPart: accessToken.substring(accessToken.length - 4),
                createdAt,
            });

            this._store.setState({ profile: { integration: { apiSessions: { list: tokens } } } });
            return { accessToken };
        });
    }

    removeAccessToken(id: number) {

        return request({
            method: 'DELETE',
            url: `/integrations/access_token/${id}`,
        })
            .then(() => {
                const tokens = toJS(this._state.integration.apiSessions.list);
                tokens.splice(tokens.findIndex(t => t.id === id), 1);

                this._store.setState({ profile: { integration: { apiSessions: { list: tokens } } } });
            })
        ;
    }

    renameAccessToken(id: number, name: string) {

        const fd = new FormData();
        fd.append('name', name);

        return request({
            method: 'POST',
            url: `/integrations/access_token/${id}`,
            data: fd
        })
            .then(() => {
                const tokens = toJS(this._state.integration.apiSessions.list);
                (tokens.find(t => t.id === id) as any).name = name;

                this._store.setState({ profile: { integration: { apiSessions: { list: tokens } } } });
            })
        ;
    }

    get integration() {

        if(!this._state?.integration) {

            request({
                method: 'GET',
                url: `/profile/integration`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state?.integration;
    }

    get parent() {
        if(!this._state?.parent) {

            request({
                method: 'GET',
                url: `/profile/parent`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
                .then(res => {
                    this._store.setState(res.data.state);
                });
        }

        return this._state?.parent;
    }

    get children() {
        if(!this._state?.children) {

            request({
                method: 'GET',
                url: `/profile/children`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
                .then(res => {
                    this._store.setState(res.data.state);
                });
        }

        return this._state?.children;
    }

    private saveFeedbackTextDebounced = debounce(
        (userId: number, value: string) => {
            getDatabase(userId).keyValue.put({ key: 'profileFeedback', value });
        },
        1000
    );

    setFeedbackText(value: null|string) {
        this._store.setState({
            profile: {
                feedback: {
                    text: value,
                }
            }
        });

        const userId = this._store.user.user?.id;
        if(!userId) return;
        this.saveFeedbackTextDebounced(userId, value);
    }

    get feedbackText() {
        if(this._state?.feedback?.text === undefined) {
            const userId = this._store.user.user?.id;
            if(!userId) return undefined;

            getDatabase(userId).keyValue
            .get('profileFeedback')
            .then(it => {
                this._store.setState({
                    profile: {
                        feedback: {
                            text: it?.value || null
                        }
                    }
                });
            });
        }

        return this._state?.feedback?.text;
    }

    sendFeedback() {
        const userId = this._store.user.user?.id;
        if(!userId) throw new Error('Пользователь не найден');
        const text = this._state?.feedback?.text?.trim();
        if(!text) return;

        const fd = new FormData();
        fd.append('text', text);

        return request({
            method: 'POST',
            url: `/profile/feedback`,
            data: fd,
        })
        .then(() => {
            this._store.setState({
                profile: {
                    feedback: {
                        text: null,
                    }
                }
            });
        });
    }

}

export const Context = createContext<Profile>(undefined);