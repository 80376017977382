import ItemImageBase, { Props as BaseProps } from "../ItemImageBase";

type Props = BaseProps;

const ItemImageCard = (p: Props) => {

    return (
        <ItemImageBase
            alt={p.alt || "Картинка товара"}
            src={p.src}
            width={p.width || '100%'}
            height={p.height || 200}
            sx={p.sx}
        />
    );
}

export default ItemImageCard;