import {FC, useContext, useState} from "react";
import {
    Link,
    Stack,
    TextField, Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { AppContext, UserContext } from "../../store";
import { CallMeData } from "../../store/user";

type Props = {};

export type Data = {
    readonly name: string;
    readonly phone: string;
    readonly from: number;
    readonly to: number;
};

const CallMeDialog: FC<Props> = () => {
    let [ name, setName ] = useState('');
    let [ phone, setPhone ] = useState('');

    const app = useContext(AppContext);
    const user = useContext(UserContext);

    const [callProcessing, setCallProcessing] = useState(false);

    const submitCallRequest = (data: CallMeData) => {
        setCallProcessing(true);
        user.requestCall(data)
            .then(() => {
                app.closeDialog();
                app.alert('Спасибо за заказ! Скоро мы Вам перезвоним', { severity: 'success' });
            })
            .finally(() => setCallProcessing(false))
        ;
    }

    return (
        <Stack
            sx={{ maxWidth: '300px' }}
            onSubmit={e => {
                e.preventDefault();
                name = name.trim();
                phone = phone.trim();
                if(!name.length && !phone.length) return;

                submitCallRequest({ name, phone });
            }} 
            component="form" 
            spacing={2}
        >
            <Typography variant="h5" >
                Заказать звонок
            </Typography>
            <Typography variant="body2">
                Оставьте Ваше сообщение и контактные данные, 
                и наши специалисты свяжутся с Вами в ближайшее 
                рабочее время для решения Вашего вопроса.
            </Typography>
            <TextField
                size="small"
                variant="outlined"
                placeholder="Ваше имя"
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <TextField required
                size="small"
                variant="outlined"
                placeholder="Ваш телефон *"
                value={phone}
                onChange={e => setPhone(e.target.value)}
            />
            <LoadingButton loading={callProcessing} type="submit" sx={{ width: '100%' }} variant="contained" color="primary">Позвоните мне</LoadingButton>
            <Typography variant="body2">
                Или позвоните нам по номеру:
            </Typography>
            <Link href="tel:+79688626690" children="8 (968) 862-66-90" variant="h5" underline="none" />
        </Stack>
    );
}

export default CallMeDialog;