import { Container } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import FooterView from "../../../views/FooterView";

type Props = {};

const SimpleStore: FC<Props> = () => {

    return (<>
        <Container maxWidth="xl">
            <Outlet />
        </Container>
        <FooterView sx={{ mt: 4 }} />
    </>);
}

export default SimpleStore;