import { FC, PropsWithChildren, ReactNode, SyntheticEvent } from "react";
import { FormControl, InputLabel, MenuItem, Paper, Select, Stack, SxProps, Typography } from "@mui/material";
import { ItemVariant } from "../../../common/item";
import { Link } from "react-router-dom";
import LoadingPaper from "../../LoadingPaper";
import ItemImage from "../../ItemImage";

type Props = PropsWithChildren & {
    readonly id: string;
    readonly aliasFull: string;
    readonly isPromotional?: boolean;
    readonly imgSrc: string;
    readonly label: string;
    readonly action: ReactNode;
    readonly highlighted?: boolean;
    readonly faded?: boolean;
    readonly packageSize?: number;
    readonly articl?: string;
    readonly cnpCode?: string;
    readonly stock?: number;
    readonly count: number;
    readonly inCartCount: number;
    readonly onClick?: () => void;
    readonly variants?: string[];
    readonly variantsFull?: ItemVariant[];
    readonly currentVariantId?: number;
    readonly onCurrentVariantIdChange?: (value: number) => void;

    readonly favorite?: boolean;
    readonly onFavoritesClick?: () => void;

    readonly sx?: SxProps;
}

const c = (e: SyntheticEvent, fn?: CallableFunction) => (e.stopPropagation(), e.preventDefault(), fn && fn());

const WideCard: FC<Props> = p => {

    const onClick = p.isPromotional ? undefined : p.onClick;

    const currentLink = `/catalog/${p.id}`;

    const isLoading = p.variantsFull === undefined && p.currentVariantId;

    return (<>
        {isLoading ? <LoadingPaper /> : (
            <Paper
                component={p.onClick ? undefined : Link}
                to={p.onClick ? undefined : currentLink}
                onClick={p.onClick && (e => c(e, p.onClick))}
                sx={{
                    p: "15px",
                    background: p.highlighted ? '#ebffeb' :
                        (p.faded && '#f1f1f1'),
                    position: 'relative',
                    cursor: onClick && 'pointer',
                    ...(p.sx || {})
                }}
            >
                <Stack direction="row" spacing={1}>
                    <ItemImage
                        variant="list"
                        src={p.imgSrc}
                        alt={p.label}
                    />
                    <Stack justifyContent="space-between" flexGrow={1}>
                        <Typography pb={1} overflow="hidden" height={50}>{p.label}</Typography>
                        {p.variants && (
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Вариант</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={p.currentVariantId}
                                    label="Age"
                                    onChange={e => p.onCurrentVariantIdChange(+e.target.value)}
                                    onClick={e => e.stopPropagation()}
                                >
                                    {p.variants.map((v, i) => (
                                        <MenuItem key={i} value={i}>{v}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {p.action}
                    </Stack>
                </Stack>
                {p.children}
            </Paper >
        )}
    </>);
}

export default WideCard;