import List from "../../List";
import {useContext} from "react";
import NotificationEmailSetup from "../../NotificationEmailSetup";
import {
    AppContext,
    CartContext,
    CatalogContext,
    FavoritesContext,
    NotificationsContext,
    UserContext,
    WaitListContext
} from "../../../store";
import CatalogCard from "../CatalogCard";
import { Item } from "../../../common/item";
import {observer} from "mobx-react-lite";
import ItemCardModal from "../../ItemCardModal";
import { CacheableWithPagination } from "../../../common/caching";

type Props = {
    readonly itemCode: string;
    readonly items?: CacheableWithPagination & { list: Item[] };
};

const MyCatalogCard = observer((item: { item }) => {
    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);
    const waitList = useContext(WaitListContext);
    const user = useContext(UserContext);
    const notifications = useContext(NotificationsContext);
    const notify = notifications.notify;

    const favorite = favorites.includes(item.item.id);

    return (
        <CatalogCard
            item={item.item}
            inCartCount={cart.itemsCount(item.item.code)}
            favorite={favorite}
            onFavoritesClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                if(favorite) {
                    await favorites.remove(cartItem);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem);
                    app.alert(`Добавлено в избранное`);
                }

            }}
            onAddToCartClick={async count => {
                const { orderPrice, ...cartItem } = item.item;
                await cart.addWithAmount(cartItem, count);

                if(cartItem.count <= 0 && user.user?.type != 'chepAZS') {
                    await waitList.add(cartItem);
                    if(!notify.email)
                        app.showDialog(<NotificationEmailSetup />);
                    app.alert(`Добавлено в корзину и лист ожидания`);
                }
                else {
                    app.alert(`Добавлено в корзину`);
                }
            }}
        />
    );
});

const SimilarGrid = (p: Props) => {
    const app = useContext(AppContext);
    const catalog = useContext(CatalogContext);

    return (
        <List compact
            variant="grid"
            component={MyCatalogCard}
            items={p.items}
            loadNext={() => catalog.getSimilarNextPage(p.itemCode)}
            scrollParentRef={app.dialogRef}
        />
    );
}

export default observer(SimilarGrid);