import * as React from "react";
import {FC, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {AppContext, CartContext, FavoritesContext, SaleoutContext, UserContext} from "../../../store";
import List from "../../List";
import Heading from "../HeadingView";
import {SaleoutWideCard} from "../../Item";
import PromoCarousel from "../../PromoCarousel";
import {Typography} from "@mui/material";

type Props = {
};

const MyWideCard = observer((item: { item }) => {

    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);
    const user = useContext(UserContext);

    const favorite = favorites.includes(item.item.code);
    const [count, setCount] = useState(1);

    return (
        <SaleoutWideCard
            item={item.item}
            inCart={cart.includes(item.item.code)}
            defaultFavorite={favorite}
            count={count}
            onCountChange={setCount}
            onFavoritesClick={async (favorite) => {
                const { orderPrice, ...cartItem } = item.item;
                if(favorite) {
                    await favorites.remove(cartItem);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem);
                    app.alert(`Добавлено в избранное`);
                }

            }}
            onAddToCartClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                await cart.addWithAmount(cartItem, count);
                setCount(1);
                app.alert(`Добавлено в корзину`);
            }}
        />
    );

});

const SaleoutView: FC<Props> = () => {
    const saleout = useContext(SaleoutContext);
    const items = saleout.items;

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Акции');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Акции' },
        ])
    });

    return (<>
        <Heading/>
        <PromoCarousel variant="promo" sx={{ mb: 3 }} />
        <Typography variant="h6" sx={{ mb: 2 }}><strong>Распродажа</strong></Typography>
        <List
            items={items}
            loadNext={() => saleout.loadNextPage()}
            component={MyWideCard}
        />
    </>);
};

export default observer(SaleoutView);