import { Box, Stack, StackProps, styled } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrevArrow from '@mui/icons-material/ArrowBackIosNewRounded';
import NextArrow from '@mui/icons-material/ArrowForwardIosRounded';
import ItemImage from "../ItemImage";


type Props = {
    readonly images: { src: string | null }[];
    readonly width?: string | number;
    readonly height?: string | number;
    readonly sx?: StackProps['sx'];
};

const Slider: FC<any> = styled(SlickSlider as any)({
    margin: '0 auto',
    '.slick-slide': {
        cursor: 'pointer',
        background: 'white',
    },
    '.slick-prev': {
        zIndex: 1,
        left: 0,
    },
    '.slick-next': {
        right: 0,
    },
    '.slick-arrow': {
        color: 'black',
    },
    '.slick-disabled': {
        visibility: 'hidden',
    },
}) as any;

const SlickButtonFix = (
    props: {
        children: JSX.Element;
        slideCount?: number;
        currentSlide?: number;
    }
) => {
    const { children, currentSlide, slideCount, ...others } = props;
    return (
        <span {...others}>
            {children}
        </span>
    );
};

const Carousel = (p: Props) => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1: any = useRef(null);
    let sliderRef2: any = useRef(null);

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    const type = p.images.length > 1 ? 'full' : 'image';

    return (
        <Stack
            sx={p.sx}
            spacing={1}
        >
            <Box
                sx={{
                    display: type == 'full' ? 'block' : 'none',
                }}
            >
                <Slider
                    infinite={false}
                    arrows
                    asNavFor={nav2}
                    ref={slider => (sliderRef1 = slider)}
                    slidesToShow={1}
                    prevArrow={<SlickButtonFix><Box component={PrevArrow} /></SlickButtonFix>}
                    nextArrow={<SlickButtonFix><Box component={NextArrow} /></SlickButtonFix>}
                >
                    {p.images.map((v, i) => <div key={i}>
                        <ItemImage
                            variant="page"
                            src={v.src}
                        />
                    </div>)}
                </Slider>
                <Slider
                    sx={{
                        '.slick-slide': {
                            opacity: '0.5',
                        },
                        '.slick-current': {
                            opacity: '1',
                            borderBottom: '3px solid black'
                        }
                    }}
                    infinite={false}
                    slidesToShow={Math.min(5, p.images.length)}
                    asNavFor={nav1}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    ref={slider => (sliderRef2 = slider)}
                    prevArrow={<SlickButtonFix><Box component={PrevArrow} /></SlickButtonFix>}
                    nextArrow={<SlickButtonFix><Box component={NextArrow} /></SlickButtonFix>}
                >
                    {p.images.map((v, i) => <div key={i}>
                        <ItemImage
                            key={i}
                            variant="thumbnail"
                            src={v.src}
                        />
                    </div>)}
                </Slider>
            </Box>

            {type == 'image' ? (
                <ItemImage
                    variant="page"
                    src={p.images[0].src}
                />
            ) : undefined}
        </Stack>
    );
}

export default Carousel;