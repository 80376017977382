import {Item} from "../../index";
import {AlgorithmPassResult} from "../index";

type ItemsRecord<T={}> = Record<number, T & {
    requiredCount: number;
}>;

export type Params = {
    readonly items: ItemsRecord;
    readonly itemsToGive: ItemsRecord<{
        readonly price: number;
    }>;
};

export const getIncludedItems = (params: Readonly<Params>): { id: string }[] => {
    const byId: Record<number, true> = {};
    for(const id in params.items)
        byId[id] = true;
    for(const id in params.itemsToGive)
        byId[id] = true;

    const items: {id: string}[] = Object.keys(params.itemsToGive).map(id => ({ id }));
    for (const id in params.items) {
        if(params.itemsToGive[id]) continue;
        items.push({ id });
    }

    return items;
}

export const getSampleSet = (items: ReadonlyArray<Readonly<{ id: string, stock: number }>>, params: Readonly<Params>): { id: string, count: number }[] => {

    let neededCount = 0;
    for(const id in params.items)
        neededCount += params.items[id].requiredCount;
    for(const id in params.itemsToGive)
        neededCount += params.itemsToGive[id].requiredCount;

    let minTotalCount = 0;
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id] && !params.itemsToGive[item.id]) continue;

        if(params.items[item.id]) {
            if(params.itemsToGive[item.id]) {
                if(item.stock < (params.items[item.id].requiredCount + params.itemsToGive[item.id].requiredCount))
                    return [];

                result.push({ id: item.id, count: params.items[item.id].requiredCount + params.itemsToGive[item.id].requiredCount });

                minTotalCount += params.items[item.id].requiredCount + params.itemsToGive[item.id].requiredCount;
            }
            else {
                if(item.stock < params.items[item.id].requiredCount)
                    return [];
                result.push({ id: item.id, count: params.items[item.id].requiredCount });

                minTotalCount += params.items[item.id].requiredCount;
            }
        }
        else {
            if(item.stock < params.itemsToGive[item.id].requiredCount)
                return [];
            result.push({ id: item.id, count: params.itemsToGive[item.id].requiredCount });

            minTotalCount += params.itemsToGive[item.id].requiredCount;
        }
    }
    if(minTotalCount < neededCount)
        return [];

    return result;
}

export const getItemsSet = (items: ReadonlyArray<Readonly<{ id: string, count: number, stock: number }>>, setsCount: number, params: Readonly<Params>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} => {

    let neededCount = 0;
    for(const id in params.items)
        neededCount += params.items[id].requiredCount;
    for(const id in params.itemsToGive)
        neededCount += params.itemsToGive[id].requiredCount;
    neededCount *= setsCount;

    let totalCount = 0;
    let maxSetsCount: null|number = null;
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id] && !params.itemsToGive[item.id]) continue;

        let count: number|undefined;

        if(params.items[item.id]) {
            if(params.itemsToGive[item.id])
                count = params.items[item.id].requiredCount + params.itemsToGive[item.id].requiredCount;
            else
                count = params.items[item.id].requiredCount;
        }
        else
            count = params.itemsToGive[item.id].requiredCount;

        if(item.stock < count * setsCount) continue;
        result.push({ id: item.id, count: count * setsCount });

        if(maxSetsCount === null) {
            maxSetsCount = Math.floor(item.stock / count);
        }
        else {
            maxSetsCount = Math.min(
                maxSetsCount,
                Math.floor(item.stock / count),
            );
        }

        totalCount += count * setsCount;
    }

    if(totalCount < neededCount)
        return { maxSetsCount: 0, items: [] };

    return { maxSetsCount, items: result };
}

export const getTargetPrices = (items: ReadonlyArray<{ id: string, price: number }>, params: Readonly<Params>): {
    id: string,
    price: number,
}[] => {

    return items.map(v => ({id: v.id, price: params.itemsToGive[v.id]?.price || v.price }));
}


const ItemsSetPlusOne = (items: ReadonlyArray<Readonly<Item>>, params: Readonly<Params>): AlgorithmPassResult => {
    if(!items.length) return { affected: [], untouched: [], groupsCount: 0 };

    let minNeededCount = 0;
    for(const id in params.items)
        minNeededCount += params.items[id].requiredCount;
    for(const id in params.itemsToGive)
        minNeededCount += params.itemsToGive[id].requiredCount;

    const untouched: Item[] = items.map(v => ({...v}));

    const countedIndices: number[] = [];
    let setsCount: number = null;

    const merged: Mutable<ItemsRecord> = {};
    for(const r of [params.items, params.itemsToGive]) {
        for(const k in r) {
            if(!merged[k])
                { merged[k] = {...r[k]}; continue; }
            merged[k].requiredCount += r[k].requiredCount;
        }
    }

    let minTotalCount = 0;
    for(let idx = 0; idx < items.length; idx++) {
        const item = items[idx];
        if(!merged[item.id]) continue;

        const itemCount = Math.min(item.count, item.stock);
        const baseCount = merged[item.id].requiredCount;

        if(itemCount < baseCount) continue;

        if(setsCount === null)
            setsCount = Math.floor(itemCount / baseCount);
        else
            setsCount = Math.min(setsCount, Math.floor(itemCount / baseCount));

        countedIndices.push(idx);
        minTotalCount += baseCount;
    }
    if(minTotalCount < minNeededCount)
        return { affected: [], untouched, groupsCount: 0 };

    const affected: Item[] = [];
    let deletedCount = 0;
    for(let idx of countedIndices) {
        idx = idx - deletedCount;

        const item = untouched[idx];
        const requiredItemCount = setsCount * merged[item.id].requiredCount;
        if(item.count > requiredItemCount) {
            item.count -= requiredItemCount;
            item.stock -= requiredItemCount;
        }
        else {
            untouched.splice(idx, 1);
            deletedCount++;
        }

        affected.push({
            ...item,
            count: requiredItemCount,
        });
    }

    const itemsToGive: Item[] = [];
    for(const item of affected) {
        if(params.items[item.id]) {
            if(params.itemsToGive[item.id]) {
                const giveCount = params.itemsToGive[item.id].requiredCount * setsCount;
                item.count -= giveCount;
                itemsToGive.push({
                    ...item,
                    count: giveCount,
                    price: params.itemsToGive[item.id].price,
                });
            }
            continue;
        }

        item.price = params.itemsToGive[item.id].price;
    }
    if(itemsToGive.length)
        affected.push(...itemsToGive);

    return { affected, untouched, groupsCount: setsCount };
};

export default ItemsSetPlusOne;