import React from "react";
import {Typography} from "@mui/material";

const EmptyView = () => {

    return (
        <Typography variant="h4" color="error">
            Акционный товар закончился
        </Typography>
    );
}

export default EmptyView;