import ItemImageBase, { Props as BaseProps } from "../ItemImageBase";

type Props = BaseProps;

const ItemImageWideCard = (p: Props) => {

    return (
        <ItemImageBase
            alt={p.alt || "Картинка товара"}
            src={p.src}
            width={p.width || 150}
            height={p.height || 150}
            sx={p.sx}
        />
    );
}

export default ItemImageWideCard;