import { FC, PropsWithChildren, ReactElement, forwardRef, useEffect, useMemo, useState } from "react";
import Content, { Props as ContentProps } from './Content';
import { Box, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Popper, Slide, Stack, useMediaQuery, useTheme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import ShowFiltersIcon from '@mui/icons-material/Visibility';
import { Prop } from "./Property";

export type Props = PropsWithChildren & {
    readonly props?: Prop[];
    readonly selected: any;
    readonly onSelect: (selected: any) => void;
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getFiltersObject = (state: Prop[]) => {
    let filters = {};

    for (const prop of state) {
        if (!prop.isSet) continue;

        const selected: (string | number)[] = [];
        switch (prop.type) {
            case 'checkbox':
                selected.push(...prop.values.filter(v => v.checked).map(v => v.id));
                break;
            case 'select':
                selected.push(prop.selectedId);
                break;
            case 'range':
                if (prop.from || prop.to)
                    selected.push(prop.from, prop.to);
                break;
        }

        if (!selected.length) continue;

        filters[prop.id] = selected;
    }
    if (!Object.keys(filters).length)
        filters = undefined;

    return filters;
};

type MobileProps = ContentProps & {
    readonly state?: Prop[];
    readonly onStateChange: (state: Prop[]) => void;
    readonly onApplyClick: () => void;
    readonly onClearClick: (id?: string) => void;
}

const MobileContent = (p: MobileProps) => {
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onApplyClick = () => {
        setOpen(false);
        p.onApplyClick();
    }

    // TODO: НЕ РАБОТАЕТ. Что-то с состоянием
    // const count = useMemo(() => {
    //     return p.state?.reduce((count, p) => count + (p.isSet ? 1 : 0), 0) || 0;
    // }, [ p.state ]);
    const count = 0;

    return (<>
        <Fab
            variant="extended"
            color="primary"
            onClick={() => setOpen(true)}
            sx={{
                position: 'fixed',
                left: '50%', transform: 'translateX(-50%)',
                bottom: '10px',
            }}
        >
            <ShowFiltersIcon sx={{ mr: 1 }} />
            Фильтры {count ? `(${count})` : ''}
        </Fab>
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle id="scroll-dialog-title">
                Фильтры
            </DialogTitle>
            <Button
                onClick={() => {
                    onClose();
                    p.onClearClick();
                }}
                color="error"
                sx={{
                    position: 'absolute',
                    right: 20,
                    top: 15,
                }}
                children="Сбросить"
            />
            <DialogContent>
                <Content
                    selected={p.selected}
                    state={p.state}
                    props={p.props}
                    onStateChange={p.onStateChange}
                    onClearClick={p.onClearClick}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button variant="contained" onClick={onApplyClick}>Применить</Button>
            </DialogActions>
        </Dialog>
    </>);
}

type DesktopProps = ContentProps & {
    readonly state?: Prop[];
    readonly onStateChange: (state: Prop[]) => void;
    readonly onApplyClick: () => void;
    readonly onClearClick: (id?: string) => void;
}

const DesktopContent = (p: DesktopProps) => {
    const [popoverIsShown, setPopoverIsShown] = useState(false);
    const [anchorEl, setAnchorEl] = useState(undefined as Element);

    const onApplyClick = () => {
        setPopoverIsShown(false);
        p.onApplyClick();
    }

    return (<>
        <Content
            selected={p.selected}
            state={p.state}
            props={p.props}
            onStateChange={p.onStateChange}
            onClearClick={p.onClearClick}
            onDirtyValueChange={el => {
                setAnchorEl(el);
                setPopoverIsShown(true);
            }}
        />
        <Stack sx={{ p: 2 }}>
            <Button
                onClick={onApplyClick}
                variant="contained"
                size="medium"
                children="Применить"
            />
        </Stack>
        <Popper
            open={popoverIsShown}
            anchorEl={anchorEl}
            placement="right"
            disablePortal
            style={{ zIndex: 1000 }}
        >
            <ClickAwayListener onClickAway={() => {
                setPopoverIsShown(false);
            }}>
                <Fab
                    color="primary"
                    variant="extended"
                    onClick={onApplyClick}
                >
                    <ShowFiltersIcon sx={{ mr: 1 }} />
                    Показать
                </Fab>
            </ClickAwayListener>
        </Popper>
    </>);
}

const clearProp = (state: Prop[], propId?: string) => {

    for (let i = 0; i < state.length; i++) {
        const prop = state[i];
        if (propId && prop.id != propId) continue;

        const newProp = { ...prop };
        newProp.isSet = false;
        switch (newProp.type) {
            case 'select':
                delete newProp.selectedId;
                break;
            case 'checkbox':
                newProp.values = [...newProp.values];
                for (const value of newProp.values)
                    value.checked = false;
                break;
            case 'range':
                delete newProp.from;
                delete newProp.to;
                break;
        }

        state[i] = { ...newProp };
    }
}

const FiltersBlock: FC<Props> = (p) => {
    const theme = useTheme();
    const [state, setState] = useState<Prop[]>(p.props?.concat());
    const [flushState, setFlushState] = useState(false);

    useEffect(() => {
        if (flushState) {
            setFlushState(false);
            p.onSelect(getFiltersObject(state));
        }

    }, [flushState]);

    useEffect(() => {
        setState(p.props?.concat());

    }, [p.props]);

    const applyFilters = (e?: React.UIEvent) => {
        e?.stopPropagation();
        p.onSelect(getFiltersObject(state));
    };

    return (<>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MobileContent
                onApplyClick={applyFilters}
                state={state}
                onStateChange={setState}
                selected={p.selected}
                props={p.props}
                onClearClick={propId => {
                    const s = state.concat();
                    clearProp(s, propId);
                    setState(s);
                    if (!propId) {
                        setFlushState(true);
                    }
                }}
            />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <DesktopContent
                onApplyClick={applyFilters}
                state={state}
                onStateChange={setState}
                selected={p.selected}
                props={p.props}
                onClearClick={propId => {
                    const s = state.concat();
                    clearProp(s, propId);
                    setState(s);
                    setFlushState(true);
                }}
            />
        </Box>
    </>);
};

export default FiltersBlock;