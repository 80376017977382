import { FC, ReactElement } from "react";
import { Stack, SxProps, Typography, useTheme } from "@mui/material";
import { formatPrice as $ } from "../../../utils";

type Props = {
    readonly value: number;
    readonly compact?: boolean;
    readonly before?: ReactElement;
    readonly highlight?: boolean;
    readonly sx?: SxProps;
}

const Price: FC<Props> = ({ value, compact, before, highlight, sx }) => {
    const theme = useTheme();

    return (
        <Stack sx={{ overflow: 'hidden', ...(sx || {}) }} direction="row" justifyContent={before ? 'space-between' : 'flex-start'} spacing={1}>
            {before || undefined}
            <Typography color={highlight ? theme.palette.warning.main : (compact ? 'text.secondary' : 'text.primary')}
                sx={{
                    fontWeight: "bold",
                    fontSize: '1.2rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}>
                {$(value)}
            </Typography>
        </Stack>
    );
}

export default Price;