import {FC, useContext, useState} from "react";
import WideCard from "../WideCard";
import { Item } from "../../../common/item";
import WideCardStepperAction from "../WideCardStepperAction";
import {AppContext, BonusContext, CartContext, CatalogContext} from "../../../store";
import ItemCardModal from "../../ItemCardModal";
import { observer } from "mobx-react-lite";

type Props = {
    readonly item: Item;
    readonly inCart: boolean;
    readonly count: number;
    readonly defaultFavorite: boolean;
    readonly onAddToCartClick: () => void;
    readonly onFavoritesClick: (favorite: boolean) => Promise<any>;
    readonly onCountChange?: (value: number) => void;
}

const SaleoutWideCard: FC<Props> = (p) => {
    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    const [favorite, setFavorite] = useState(p.defaultFavorite);

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: p.count || 1,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });

    
    const catalog = useContext(CatalogContext);
    const variants = catalog.getItemVariants(p.item.id);

    const [currentVariantId, setCurrentVariantId] = useState(p.item.variants ? 0 : undefined);

    return (
        <WideCard
            id={p.item.id}
            imgSrc={p.item.imgList}
            label={p.item.label}
            favorite={favorite}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            stock={p.item.count}
            inCartCount={cart.itemsCount(p.item.id)}
            count={p.count}
            currentVariantId={currentVariantId}
            onCurrentVariantIdChange={setCurrentVariantId}
            onClick={() => {
                app.showDialog(<ItemCardModal item={p.item} />)
            }}
            onFavoritesClick={() => {
                p.onFavoritesClick(favorite)
                    .then(() => setFavorite(!favorite))
                ;
            }}
            aliasFull={p.item.aliasFull}
            variants={p.item.variants}
            variantsFull={variants}
            action={
                <WideCardStepperAction
                    count={p.count}
                    price={p.item.price}
                    oldPrice={p.item.oldPrice}
                    onCountChange={p.onCountChange}
                    stock={p.item.count}
                    inCart={p.inCart}
                    onAddToCartClick={p.onAddToCartClick}
                    outOfStock={p.item.count <= 0}
                    bonusValue={bonusValue}
                    variants={p.item.variants}
                    variantsFull={variants}
                    currentVariantId={currentVariantId}
                />
            }
        />
    );
}

export default observer(SaleoutWideCard);