import { TableRow as MuiTableRow } from "@mui/material";
import TableCell from "../../TableCell";
import { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { BonusContext, UserContext } from "../../../../../store";
import { themed } from "../../../../../theme";

type Props = {

}


const TableRow : FC<Props>= (p) => {

    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);

    return (
        <MuiTableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Номер заказа</TableCell>
            <TableCell>Дата</TableCell>
            <TableCell>Сумма</TableCell>
            {!user.user.childrenCount ? undefined : (
                <TableCell>Грузополучатель</TableCell>
            )}
            {bonus.status?.enabled && (
                <TableCell>Баллы</TableCell>
            )}
            <TableCell>Комментарий</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell>Счет</TableCell>
        </MuiTableRow>
    );
}

export default themed('OrderTableHeadRow', observer(TableRow));