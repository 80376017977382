import { FC, useEffect, useState } from "react";
import { Box, BoxProps, IconButton, TextField } from "@mui/material";
import MinusIcon from "@mui/icons-material/RemoveCircleRounded";
import PlusIcon from "@mui/icons-material/AddCircleRounded";
import * as React from "react";

type Props = Omit<BoxProps, 'onChange'> & {
    readonly value: number;
    readonly onChange: (value: number) => void;
    readonly min?: number;
    readonly max?: number;
}

const Stepper: FC<Props> = ({ value, onChange, min = 1, max = 9999999, sx, ...props }) => {
    const [strValue, setStrValue] = useState(`${value}`);

    useEffect(() => {
        setStrValue(`${value}`)
    }, [value]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                ...(sx || {})
            }}
            {...props}
        >
            <IconButton
                disabled={value <= min}
                aria-label="minus"
                size="large"
                onClick={() => onChange(value - 1)}
                sx={{ p: 0 }}
            >
                <MinusIcon sx={{ fontSize: "20px", color: "#D8DAE5" }} />
            </IconButton>
            <TextField
                value={strValue}
                onChange={e => {
                    setStrValue(e.target.value)
                    const val = parseInt(e.target.value, 10);
                    if (!isNaN(val) && val >= min && val <= max) onChange(val);
                }}
                sx={{
                    width: 55,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: "12px",
                        border: "1px solid #D8DAE5",
                    },
                    '& .MuiOutlinedInput-input': {
                        textAlign: "center",
                        padding: "5px"
                    }
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                size="small"
            />
            <IconButton
                disabled={value >= max}
                aria-label="plus"
                size="large"
                onClick={() => {
                    onChange(value + 1)
                }
                }
                sx={{ p: 0 }}
            >
                <PlusIcon sx={{ fontSize: "20px", color: "#8F95B2" }} />
            </IconButton>
        </Box>
    );
}

export default Stepper;