import {createContext} from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {merge, Store} from "./index";
import {request} from "../utils";
import {Context as PromoContext, Rule} from "../../ts-shared/promo";
import dayjs from "dayjs";
import { Cacheable, CacheableWithPagination } from "../common/caching";
import { Tree, TreeLeaf, TreeLevel, TreeLevelCategories } from "../common/tree";
import { Item, ItemVariant } from "../common/item";
import getDatabase from "../services/DexieDB";
import { difference, keys } from "lodash";
import { Prop } from "../components/FiltersBlock/Property";
import { Breadcrumb } from "../components/Breadcrumbs";

export type FilterPresets = Cacheable & {
    readonly list: { id: number, url: string, name: string }[];
}
export type FilterProps = Cacheable & {
    readonly list: Prop[];
}
export type ItemVariants = Cacheable & ItemVariant[];

export type ItemsListViewMode = 'grid'|'row';

export type State = {
    readonly tree: Tree;
    readonly itemsById: Record<string, Cacheable & {
        readonly item: Item;
        readonly breadcrumbs?: Breadcrumb[];
        readonly similar?: CacheableWithPagination & {
            readonly list: Item[];
        };
    }>;
    readonly itemsByKey: {
        readonly list: Record<string, CacheableWithPagination & {
            readonly similar?: {
                readonly list: Item[];
            },
            readonly parentAliasFull: string;
            readonly pages: [ number, Item[] ];
        }>
    };
    readonly filterPresetsByPath: Record<string, FilterPresets>;
    readonly filterPropsByPath: Record<string, FilterProps>;
    readonly variantsByMainItemId: Record<string, ItemVariants>;
    readonly breadcrumbsByPath: Record<string, Breadcrumb[]>;

    readonly promo: {
        readonly context?: PromoContext;
        readonly itemsByPromoId: Record<number, Cacheable & {
            readonly items: Item[];
        }>;
    };
    readonly preferences: {
        readonly viewMode?: ItemsListViewMode;
    }
};

export class Catalog {
    _store: Store = undefined;
    _state: State = {
        itemsById: {},
        variantsByMainItemId: {},
        tree: undefined,
        itemsByKey: { list: {} },
        filterPropsByPath: {},
        filterPresetsByPath: {},
        breadcrumbsByPath: {},
        promo: {
            context: undefined,
            itemsByPromoId: {},
        },
        preferences: {},
    };

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            tree: computed,
            setState: action,
            promoContext: computed,
            itemsViewMode: computed,
        });
    }

    setState(state: State | undefined) {
        if(state.promo?.context) {
            const context: Mutable<PromoContext> = state.promo.context;

            const list: Record<number, Rule> = {};
            for(const id in context.promoList) {
                const it = context.promoList[id];
                list[id] = {
                    ...it,
                    validSince: dayjs(it.validSince),
                    validUntil: dayjs(it.validUntil),
                };
            }
            context.promoList = list;
        }
        if(state.itemsByKey) {

            for(const key in state.itemsByKey.list) {
                const items = (state.itemsByKey.list[key] as any).pages[1];
                for(const item of items) {
                    if(!state.itemsById) (state as any).itemsById = {};
                    
                    state.itemsById[(item as Item).id] = {
                        process: { status: 'success' }, item,
                    };
                }
            }
        }

        this._state = merge(this._state, state || {});
    }

    private getTree(path?: string): undefined|TreeLeaf|null {

        const tree = this._state.tree;
        if(tree === undefined) {

            request({
                method: 'POST',
                url: `/catalog`,
                headers: {'App-Data-Only': 'yes', Accept: 'application/json'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            });

            return tree as undefined;
        }

        const rootLeaf: TreeLeaf = {
            id: null,
            parent_id: null,
            label: 'Корень',
            alias: '',
            aliasfull: '',
            children: tree.byAlias,
        }

        if(!path) return rootLeaf;

        const s = path.startsWith('/') ? 1 : 0;
        const e = path.endsWith('/') ? path.length - 1 : undefined;
        if(s || e) path = path.substring(s, e);

        const parts = path.split('/');
        let subTree = rootLeaf;
        for(const part of parts) {
            subTree = subTree.children[part];
            if(!subTree) return null;
        }

        return subTree;
    }

    getItemsVariants(itemIds: string[]) {
        const diff = difference(itemIds, keys(this._state.variantsByMainItemId));
        if(diff.length) {

            request({
                method: 'GET',
                url: `/catalog/variants`,
                params: { items: diff },
            })
            .then(res => {
                this._store.setState(res.data.data.state);
            });

            return this._state.variantsByMainItemId[diff[0]] as undefined;
        }

        return this._state.variantsByMainItemId;
    }

    getItemVariants(mainItemId: string) {
        // this.getItemsVariants([itemId]);

        return this._state.variantsByMainItemId[mainItemId];
    }

    getTreeLevel(): undefined|TreeLevelCategories;
    getTreeLevel(path: string, filters?: any, search?: string, group?: string, sort?: string, page?: number): undefined|TreeLevel;
    getTreeLevel(path?: string, filters?: any, search?: string, group?: string, sort?: string, page?: number): undefined|TreeLevel {
        if(!page) page = 1;

        const tree = this.getTree(path);
        if(tree === undefined) return tree as undefined;

        let item: any;
        let items: any;
        if(!tree) {
            item = this._state.itemsById[path]?.item;
            if(item) return { type: 'item', item };

            const params: Record<string, string> = {};

            if(search && search.length)
                params.q = search;
            else {

                if(filters) params.f = JSON.stringify(filters);
            }
            if(sort) params.s = sort;
            if(group) params.g = group;

            if(page > 1)
                params.page = ''+page;

            const key = `{}_${path}`;

            const itemsByKey = this._state.itemsByKey;
            items = itemsByKey.list[key];
            if(items?.pages[page] === undefined) {
                request({
                    method: 'POST',
                    url: `/catalog/${path}`,
                    headers: {'App-Data-Only': 'yes', Accept: 'application/json'},
                    baseURL: '',
                    params,
                })
                .then(res => {
                    this._store.setState(res.data.state);
                })

                return undefined;
            }

            const listItems: Item[] = [];
            let nextPage = page;
            while(items.pages[nextPage]) {
                listItems.push(...items.pages[nextPage]);

                nextPage++;
            }
            if(nextPage > items.pageCount)
                nextPage = null;

            const itemIds = listItems.map(v => v.mainItemId);
            this.getItemsVariants(itemIds);

            const cat = this.getTree(items.parentAliasFull);

            return {
                type: 'items',
                category: {
                    label: cat.label,
                    description: cat.description,
                    aliasFull: cat.aliasfull,
                },
                pageCount: items.pageCount,
                list: listItems,
                nextPage,
                similar: items.similar,
                filterProps: this._state.filterPropsByPath[path].list,
                filterPresets: this._state.filterPresetsByPath[path],
                breadcrumbs: this._state.breadcrumbsByPath[path],
            };
        }

        if(!search && !filters && Object.keys(tree.children).length) {
            const roots = Object.values(tree);

            if (roots.length) {

                return {
                    type: 'categories',
                    list: tree.children,
                    breadcrumbs: []
                }
            }
            if(!path) return { type: 'categories', list: {}, breadcrumbs: [] };
        }

        const params: Record<string, string> = {};

        if(search && search.length)
            params.q = search;
        else {

            if(filters) params.f = JSON.stringify(filters);
        }
        if(sort) params.s = sort;
        if(group) params.g = group;

        let key = JSON.stringify(params);
        if(path) key += `_${path}`;

        if(page > 1)
            params.page = ''+page;

        items = this._state.itemsByKey.list[key];
        if(items?.pages[page] === undefined && item === undefined) {
            request({
                method: 'POST',
                url: `/catalog/${path || ''}`,
                headers: {'App-Data-Only': 'yes', Accept: 'application/json'},
                baseURL: '',
                params,
            })
            .then(res => {
                this._store.setState(res.data.state);
            });

            return undefined;
        }
        
        if(item) return { type: 'item', item };

        const listItems: Item[] = [];
        let nextPage = page;
        while(items.pages[nextPage]) {
            listItems.push(...items.pages[nextPage]);

            nextPage++;
        }
        if(nextPage > items.pageCount)
            nextPage = null;

        const itemIds = listItems.map(v => v.mainItemId);
        this.getItemsVariants(itemIds);

        return {
            type: 'items',
            category: {
                label: tree.label,
                description: tree.description,
                aliasFull: tree.aliasfull,
            },
            pageCount: items.pageCount,
            list: listItems,
            nextPage,
            similar: items.similar,
            filterProps: this._state.filterPropsByPath[path]?.list,
            filterPresets: this._state.filterPresetsByPath[path],
            breadcrumbs: this._state.breadcrumbsByPath[path],
        };
    }
    loadNextPage(page: number, path?: string, filters?: any, search?: string, group?: string, sort?: string) {
        const params: Record<string, string> = {};

        if(search && search.length) {
            params.q = search;
        }
        else {

            if(filters) {
                params.f = JSON.stringify(filters);
            }
        }
        if(sort) params.s = sort;
        if(group) params.g = group;

        let key = JSON.stringify(params);
        if(path) key += `_${path}`;
        
        params.page = ''+page;

        request({
            method: 'POST',
            url: `/catalog/${path}`,
            headers: {'App-Data-Only': 'yes', Accept: 'application/json'},
            baseURL: '',
            params,
        })
        .then(res => {
            this._store.setState(res.data.state);
        });
    }

    /** @deprecated */
    getFilterProps(path?: string, filters?: any, search?: string, group?: string, sort?: string): undefined|FilterProps {
        if(!path) return null;

        const url = [];
        const params: Record<string, string> = {};

        if(path) url.push(path);

        if(filters) params.f = JSON.stringify(filters);
        if(sort) params.s = sort;
        if(group) params.g = group;

        let key = JSON.stringify(params);
        if(path) key += `_${path}`;

        const props = this._state.filterPropsByPath[key];
        if(!props) {
            request({
                method: 'POST',
                url: `/catalog/filters/${url.join('/')}/`,
                headers: {'App-Data-Only': 'yes', Accept: 'application/json'},
                params,
            })
            .then(res => {
                this._store.setState(res.data.state);
            })
        }

        return props;
    }

    getItemByIdForList(id: string) {

        if(!this._state.itemsById[id]?.item) {
            request({
                method: 'POST',
                url: `/catalog/${id}`,
                headers: {'App-Data-Only': 'yes', Accept: 'application/json'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            })
        }

        return this._state.itemsById[id]?.item;
    }

    getItemByFullPath(fullPath: string) {

        const res = this._state.itemsById[fullPath];

        if(!res || !res.breadcrumbs) {
            request({
                method: 'POST',
                url: `/catalog/${fullPath}`,
                headers: {'App-Data-Only': 'yes', Accept: 'application/json'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            })
        }

        return res;
    }

    promoGetIncludedItems(promoId: number) {
        if(!this._state.promo.itemsByPromoId[promoId]) {
            request({
                method: 'POST',
                url: `/promo/${promoId}`,
                headers: {'App-Data-Only': 'yes', Accept: 'application/json'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state.promo.itemsByPromoId[promoId]?.items;
    }

    getSimilar(fullPath: string) {
        if(!this._state.itemsById[fullPath]) {
            this.getItemByFullPath(fullPath);
        }

        return this._state.itemsById[fullPath]?.similar;
    }

    getSimilarNextPage(fullPath: string) {
        request({
            method: 'GET',
            url: `/catalog/${fullPath}/similar`,
            params: {
                page: this._state.itemsById[fullPath].similar.nextPage,
            }
        })
        .then(res => {
            this._store.setState(res.data.data.state);
        });
    }

    get tree() {
        return this._state.tree;
    }

    get promoContext() {
        if(!this._state.promo.context) {
            request({
                method: 'GET',
                url: `/promo/context`,
            })
            .then(res => {
                this._store.setState(res.data.data.state);
            });
        }

        return this._state.promo.context;
    }

    get itemsViewMode(): 'grid'|'row' {
        const user = this._store.user.user;
        if(user === undefined) return user as undefined;

        if(this._state.preferences.viewMode === undefined) {
            getDatabase(user?.id || null).keyValue
            .get('catalogItemsViewMode')
            .then(it => {
                this._store.setState({
                    catalog: {
                        preferences: {
                            viewMode: it?.value || 'grid',
                        }
                    }
                } as any);
            });
        }

        return this._state.preferences.viewMode;
    }

   
    setItemsViewMode(value: 'grid'|'row') {
        const user = this._store.user.user;
        if(user === undefined) return user as undefined;

        this._store.setState({
            catalog: {
                preferences: {
                    viewMode: value,
                }
            }
        } as any);
        getDatabase(user?.id || null).keyValue
        .put({ key: 'catalogItemsViewMode', value });
    }

}

export const Context = createContext<Catalog>(undefined);