import ItemImageCard from "./ItemImageCard";
import ItemImageWideCard from "./ItemImageWideCard";
import ItemImageFull from "./ItemImageFull";
import ItemImageThumb from "./ItemImageThumb";
import { SxProps } from "@mui/material";

type Props = {
    readonly variant: 'card' | 'list' | 'page' | 'thumbnail';
    readonly src?: string;
    readonly alt?: string;
    readonly sx?: SxProps;
};

const ItemImage = (p: Props) => {

    if(p.variant == 'card')
        return <ItemImageCard src={p.src} alt={p.alt} />;


    if(p.variant === 'list')
        return <ItemImageWideCard src={p.src} alt={p.alt} />;

    if(p.variant === 'thumbnail')
        return <ItemImageThumb src={p.src} alt={p.alt} />;


    return <ItemImageFull src={p.src} alt={p.alt} />
}

export default ItemImage;