import {Item} from "../../index";
import {AlgorithmPassResult} from "../index";

type ItemsRecord<T={}> = Record<number, T & {
    requiredCount: number;
}>;

export type Params = {
    readonly requiredTotalCount: number;
    readonly items: Record<number, {}>;
    readonly itemsToGive: ItemsRecord<{
        readonly price: number;
    }>;
};

export const getIncludedItems = (params: Readonly<Params>): { id: string }[] => {
    const byId: Record<number, true> = {};
    for(const id in params.items)
        byId[id] = true;
    for(const id in params.itemsToGive)
        byId[id] = true;

    const items: {id: string}[] = Object.keys(params.itemsToGive).map(id => ({ id }));
    for (const id in params.items) {
        if(params.itemsToGive[id]) continue;
        items.push({ id });
    }

    return items;
}

export const getSampleSet = (items: ReadonlyArray<Readonly<{ id: string, stock: number }>>, params: Readonly<Params>): { id: string, count: number }[] => {
    let neededCount = params.requiredTotalCount;
    let totalCount = 0;

    let giftPositionsLeft = Object.keys(params.itemsToGive).length;

    const res: {id: string, count: number}[] = [];
    for(const item of items) {
        if(item.stock <= 0) continue;

        if(params.items[item.id]) {
            if(params.itemsToGive[item.id]) {
                const giftCount = params.itemsToGive[item.id].requiredCount;
                if(item.stock < giftCount) return [];
                giftPositionsLeft--;

                const count = Math.min(neededCount - totalCount, item.stock - giftCount);
                totalCount += count;

                res.push({ id: item.id, count: count + giftCount });
            }
            else {
                const count = Math.min(neededCount - totalCount, item.stock);
                totalCount += count;

                res.push({ id: item.id, count });
            }
        }
        else if(params.itemsToGive[item.id]) {
            const count = params.itemsToGive[item.id].requiredCount;
            if(item.stock < count) return [];
            giftPositionsLeft--;

            res.push({ id: item.id, count });
        }
    }
    if(totalCount < neededCount || giftPositionsLeft > 0) return [];

    return res;
}

export const getItemsSet = (items: ReadonlyArray<Readonly<{ id: string, count: number, stock: number }>>, setsCount: number, params: Readonly<Params>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} => {

    let totalCount = 0;
    for(const item of items) {
        if(!params.items[item.id] && !params.itemsToGive[item.id]) continue;
        // Такой способ расчета годится в этом случае, потому что нам важно просто умножить/разделить количество
        // каждой позиции на setsCount. Нам неважно, подарок это или нет, находится ли эта позиция в обоих списках или нет.
        // Главное, что сумма count у item и itemsToGive составляющих кратна setsCount.
        totalCount += item.count;
    }

    let requiredTotalCount = params.requiredTotalCount;
    for(const id in params.itemsToGive)
        requiredTotalCount += params.itemsToGive[id].requiredCount;

    if(totalCount < requiredTotalCount)
        return { maxSetsCount: 0, items: [] };

    let maxSetsCount: null|number = null;
    const currentSetsCount = Math.floor(totalCount / requiredTotalCount);
    const result: {id: string, count: number}[] = [];
    for(const it of items) {
        if(!params.items[it.id] && !params.itemsToGive[it.id]) continue;

        const batch = Math.floor(it.count / currentSetsCount);
        const baseCount = batch * setsCount;
        if(it.stock < baseCount)
            return { maxSetsCount: 0, items: items.map(v => ({ id: v.id, count: v.count })) };


        if(maxSetsCount === null)
            maxSetsCount = Math.floor(it.stock / batch);
        else {
            maxSetsCount = Math.min(
                maxSetsCount,
                Math.floor(it.stock / batch),
            );
        }

        result.push({ id: it.id, count: baseCount });
    }

    return { items: result, maxSetsCount };
}

export const getTargetPrices = (items: ReadonlyArray<{ id: string, price: number }>, params: Readonly<Params>): {
    id: string,
    price: number,
}[] => {
    return items.map(v => ({id: v.id, price: params.itemsToGive[v.id]?.price || v.price }));
}


const DefinedAmountSet = (items: ReadonlyArray<Readonly<Item>>, params: Readonly<Params>): AlgorithmPassResult => {
    if(!items.length) return { affected: [], untouched: [], groupsCount: 0 };

    const untouched: Item[] = items.map(v => ({...v}));

    const neededItems = params.requiredTotalCount;
    let   neededGifts = 0;
    for(const id in params.itemsToGive)
        neededGifts += params.itemsToGive[id].requiredCount;

    const cnts: Record<number, number> = {};

    let itemsCount = 0;
    let giftsCount = 0;
    for(const item of items) {
        if(itemsCount >= neededItems && giftsCount >= neededGifts) break;
        if(!params.items[item.id] && !params.itemsToGive[item.id] || item.stock <= 0) continue;

        if(params.items[item.id]) {
            if(params.itemsToGive[item.id]) {
                if(item.count < params.itemsToGive[item.id].requiredCount)
                    continue;

                let giftCount = params.itemsToGive[item.id].requiredCount;

                const count = item.count - giftCount;
                const before = Math.floor(itemsCount / neededItems);
                const after = Math.floor((itemsCount + count) / neededItems);
                if(after == before) { // TODO: Проблема 3 + 8. Надо, чтобы порядок не имел значения
                    giftCount += count;
                }
                else { // TODO: Проблема 110 + 11. Пока решается повторным проходом
                    itemsCount += count;
                    giftCount += itemsCount % neededItems;
                }
                cnts[item.id] = giftCount;
                giftsCount += giftCount;
            }
            else {
                if(itemsCount < neededItems)
                    itemsCount += item.count;
            }
        }
        else {
            if(giftsCount < neededGifts)
                giftsCount += item.count;
        }
    }

    if(itemsCount < neededItems || giftsCount < neededGifts)
        return { affected: [], untouched, groupsCount: 0 };

    let setsCount = Math.floor(itemsCount / neededItems);
    for(const item of items) {
        if (!params.itemsToGive[item.id]) continue;

        if(params.items[item.id]) {
            setsCount = Math.min(
                setsCount,
                Math.floor((cnts[item.id] || 1) / params.itemsToGive[item.id].requiredCount),
            );
        }
        else {
            setsCount = Math.min(
                setsCount,
                Math.floor(item.count / params.itemsToGive[item.id].requiredCount),
            );
        }
    }

    const countedIndices: number[] = [];

    for(let idx = 0; idx < items.length; idx++) {
        const item = items[idx];
        if(!params.items[item.id] && !params.itemsToGive[item.id] || item.stock <= 0) continue;

        countedIndices.push(idx);
    }

    if(setsCount < 1)
        return { affected: [], untouched, groupsCount: 0 };

    let usedCount = 0;
    const affected: Item[] = [];
    let deletedCount = 0;
    for(let idx of countedIndices) {
        idx = idx - deletedCount;

        const item = untouched[idx];

        let affectedCount: number;
        if(params.items[item.id]) {
            // Позиция есть в списке товаров и возможно в списке подарочных товаров одновременно
            const itemToGiveCount = (params.itemsToGive[item.id]?.requiredCount || 0) * setsCount;
            const itemCount = Math.min((params.requiredTotalCount * setsCount) - usedCount, item.count - itemToGiveCount, item.stock);
            usedCount += itemCount;

            affectedCount = itemToGiveCount + itemCount;
        }
        else {
            // Позиция есть только в списке подарочных товаров
            affectedCount = params.itemsToGive[item.id].requiredCount * setsCount;
        }

        if(affectedCount > 0) {
            if(item.count - affectedCount > 0) {
                item.count -= affectedCount;
                item.stock -= affectedCount;
            }
            else {
                untouched.splice(idx, 1);
                deletedCount++;
            }

            affected.push({
                ...item,
                count: affectedCount,
            });
        }
    }

    const itemsToGive: Item[] = [];
    for(let i = 0; i < affected.length; i++) {
        const item = affected[i];

        if(params.items[item.id]) {
            if(params.itemsToGive[item.id]) {
                const giveCount = params.itemsToGive[item.id].requiredCount * setsCount;
                item.count -= giveCount;
                if(item.count <= 0) {
                    // Если позиции хватило только на подарок
                    affected.splice(i--, 1);
                }
                itemsToGive.push({
                    ...item,
                    count: giveCount,
                    price: params.itemsToGive[item.id].price,
                });
            }
            continue;
        }

        item.price = params.itemsToGive[item.id].price;
    }
    if(itemsToGive.length)
        affected.push(...itemsToGive);

    return { affected, untouched, groupsCount: setsCount };
};

export default DefinedAmountSet;