import {action, computed, makeObservable, observable} from "mobx";
import axios, {AxiosRequestConfig} from "axios";
import accessToken from "../services/accessToken";
import {createContext} from "react";
import fingerprint from "../services/fingerprint";
import {Store} from "./index";
import {request} from "../utils";

export type State = {
    readonly isLoggedIn?: boolean;
}


export class Auth {
    _store: Store;
    _pendingRequests = 0;
    _wasLoggedIn = false;
    _isLoggedIn?: boolean = undefined;

    private interceptorHandle: number;

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            isLoggedIn: computed,
            _isLoggedIn: observable,
            _setIsLoggedIn: action,
            _wasLoggedIn: observable,
            _setWasLoggedIn: action,
            wasLoggedIn: computed,
            _pendingRequests: observable,
            _updatePendingRequests: action,
            hasPendingRequests: computed,
            setState: action,
        });

        this.interceptorHandle = axios.interceptors.request.use(this.requestInterceptor.bind(this));
    }

    dispose() {
        axios.interceptors.request.eject(this.interceptorHandle);
        this.interceptorHandle = undefined;
    }

    private async requestInterceptor(config: AxiosRequestConfig) {
        if(config.withCredentials || this._isLoggedIn === false) return config;

        try {
            if(this._isLoggedIn === undefined)
                await this._obtainToken();
            config.headers.Authorization = await accessToken.get();
        }
        catch (e) {
            this.logOut();
            throw e;
        }

        return config;
    }


    setState(state: State | undefined) {
        if(state?.isLoggedIn !== undefined) {
            this._setIsLoggedIn(state.isLoggedIn);
        }
    }

    _updatePendingRequests(value: number) {
        this._pendingRequests = value;
    }

    get hasPendingRequests(): boolean {
        return !!this._pendingRequests;
    }

    _setWasLoggedIn(value: boolean) {
        this._wasLoggedIn = value;
    }

    get wasLoggedIn(): boolean {
        return this._wasLoggedIn;
    }

    private _tokenProcess: Promise<any>;
    private async _obtainToken() {
        if(this._tokenProcess) return this._tokenProcess;

        this._tokenProcess = accessToken.get()
            .then(token => {
                this._setIsLoggedIn(true);
                return token;
            })
            .catch(e => {
                this._setIsLoggedIn(false);
                return Promise.reject(e);
            })
            .finally(() => {
                this._tokenProcess = undefined;
            })
        ;

        return this._tokenProcess;
    }

    get isLoggedIn(): undefined|boolean {
        if(this._isLoggedIn === undefined) {
            this._obtainToken();
        }

        return this._isLoggedIn;
    }

    async logIn(login: string, password: string) {
        if(this._isLoggedIn === true) return;

        if(this._isLoggedIn === undefined) {
            // Чтобы убедиться, а не авторизованы ли мы уже часом?
            try {
                await this._obtainToken();
                return; // если авторизованы, то всё итак уже сделано.
            }
            catch (e) {
                // Либо мы не авторизованы, либо возникла какая-то ошибка
                console.error(e)
            }
        }

        const fd = new FormData();
        fd.append('login', login);
        fd.append('password', password);
        fd.append('fp', await fingerprint.get());


        const res = await request({
            method: 'POST',
            url: '/auth',
            data: fd
        });
        this._store.setState(res.data.data.state);
        this._setIsLoggedIn(true);
        this._setWasLoggedIn(true);
    }

    _setIsLoggedIn(value: boolean) {
        this._isLoggedIn = value;
    }

    async logOut() {
        if(this._isLoggedIn === undefined || this._isLoggedIn === false) return;

        this._setIsLoggedIn(false);
        this._setWasLoggedIn(false);
        accessToken.invalidate();
        try {
            await request({
                method: 'DELETE',
                url: '/auth/session',
                withCredentials: true,
                headers: {
                    Authorization: await fingerprint.get(),
                }
            });
        }
        catch (e) {
            console.error(e);
        }
        finally {
            this._store.reset({ 
                auth: { isLoggedIn: false }, 
                app: { theme: this._store.app.theme } 
            });
        }
    }
}

export const Context = createContext<Auth>(undefined);