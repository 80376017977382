import { FC } from "react";
import { status } from "../../..";
import TableCell from "../../../TableCell";
import { Typography } from "@mui/material";

type Props = {
    readonly status: number;
}

const sxByStatus = {
    0: { color: "#6C5DD3", backgroundColor: "#cfc8ff33" },
    1: { color: "#244CB2", backgroundColor: "#E7FAFF" },
    2: { color: "#22C232", backgroundColor: "#25D7361A" },
    3: { color: "#FF9A7B", backgroundColor: "#ffce7326" },
    4: { color: "#f70b0b", backgroundColor: "#ffe7e7" },
    5: { color: "#FF754C", backgroundColor: "#ff754c1a" },
    6: { color: "#FF754C", backgroundColor: "rgba(255, 117, 76, 0.10)" },
    7: { color: "#22C232", backgroundColor: "#25D7361A" },
    8: { color: "#FF754C", backgroundColor: "#ff754c1a" },
    9: { color: "#f70b0b", backgroundColor: "#ffce7326" },
};

const StatusTableCell: FC<Props> = (p) => {

    return (
        <TableCell
            sx={{
                '& .MuiTypography-root': {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: 140,
                    height: 50,
                    borderRadius: "12px",
                    py: "6px",
                    margin: "auto",
                    textAlign: "center"
                }
            }}
        >
            <Typography sx={sxByStatus[p.status]}>{status[p.status]}</Typography>
        </TableCell>
    );
}

export default StatusTableCell;