import {action, computed, makeObservable, observable} from "mobx";
import axios, {AxiosError} from "axios";
import {createContext, ReactNode, RefObject} from "react";
import {Store} from "./index";
import {AlertColor} from "@mui/material/Alert/Alert";
import EventEmitter from "wolfy87-eventemitter";
import {Breadcrumb} from "../components/Breadcrumbs";
import { isThemeLoaded, loadTheme } from "../theme";

export type APIResponseData<T=any> = {
    meta: {
        success: true;
        message?: string;
    },
    data?: T
};
export type APIError<T=any> = AxiosError<{
    meta: {
        success: false;
        message: string;
    },
}>

export type AppState = {
    readonly error?: {
        readonly code: number;
        readonly message: string;
    };
    readonly theme?: string;
    readonly siteName?: string;
}


const axiosEvents = new EventEmitter();

let pendingRequests = 0;

axios.interceptors.request.use(
    c => {
        pendingRequests++;
        axiosEvents.emit('request', pendingRequests, c);
        return c;
    }
);
axios.interceptors.response.use(
    c => {
        pendingRequests--;
        axiosEvents.emit('response', pendingRequests, c);
        return c;
    },
    r => {
        pendingRequests--;
        axiosEvents.emit('error-response', pendingRequests, r);
        return Promise.reject(r);
    },
);


export class App {
    _store: Store;
    _snackbar: {
        content: ReactNode,
        time: number|null,
        severity: AlertColor,
        opened: boolean,
        action: null|ReactNode,
    } = { content: '', time: 0, severity: 'info', opened: false, action: null };
    _dialog: {
        content: ReactNode,
        opened: boolean,
        fullWidth?: boolean,
    } = { content: '', opened: false };
    _redirect?: {
        status: 301|302;
        url: string;
    } = undefined;

    _pendingRequests = 0;
    _dialogRef?: RefObject<HTMLElement> = undefined;

    _title: string = '';
    _siteName: string = '';
    _breadcrumbs: Breadcrumb[] = [];
    _error: AppState['error'] = undefined;
    _theme: string|null = null;
    _themeLoaded = true;

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _siteName: observable,
            siteName: computed,
            _title: observable,
            setTitle: action,
            title: computed,
            _breadcrumbs: observable,
            breadcrumbs: computed,
            setBreadcrumbs: action,
            _snackbar: observable,
            _dialog: observable,
            _dialogRef: observable,
            dialog: computed,
            dialogRef: computed,
            showDialog: action,
            closeDialog: action,
            alert: action,
            snackbar: computed,
            closeSnackbar: action,
            _pendingRequests: observable,
            _updatePendingRequests: action,
            hasPendingRequests: computed,
            _error: observable,
            error: computed,
            setState: action,
            setDialogRef: action,
            _redirect: observable,
            redirect: computed,
            redirectTo: action,
            resetRedirect: action,
            _theme: observable,
            theme: computed,
            _themeLoaded: observable,
        });

        axiosEvents
            .addListener('request', val => {
                this._updatePendingRequests(val);
            })
            .addListener('response', val => {
                this._updatePendingRequests(val);
            })
            .addListener('error-response', (val, r) => {
                this._updatePendingRequests(val);

                if(r.response?.data?.state)
                    store.setState(r.response.data.state);
            })
        ;
    }

    dispose() {
        axiosEvents.removeEvent();
    }

    setState(state: AppState | undefined) {
        if(state?.error !== undefined) {
            this._error = state.error;
        }
        if(state?.theme !== undefined) {
            this._theme = state.theme;
            if(this._theme === null || isThemeLoaded(this._theme)) {
                this._themeLoaded = true;
            }
            else {
                this._themeLoaded = false;
                loadTheme(state.theme).then(action(() => {
                    this._themeLoaded = isThemeLoaded(this._theme);
                }));
            }
        }
        if(state?.siteName !== undefined) {
            this._siteName = state.siteName;
        }
    }

    get error() {
        return this._error;
    }

    _updatePendingRequests(value: number) {
        this._pendingRequests = value;
    }

    alert(content: ReactNode, opts?: Partial<Omit<typeof this._snackbar, 'content'|'opened'>> ) {
        const snackbar = {
            content,
            time: opts?.time === undefined ? 3000 : opts.time,
            severity: opts?.severity || 'info',
            opened: true,
            action: opts?.action || null,
        };

        if(this._snackbar.opened) {
            this._snackbar.opened = false;
            window.setTimeout(action(() => this._snackbar = snackbar), 300);
        }
        else
            this._snackbar = snackbar;
    }

    showDialog(content: ReactNode, opts?: Partial<Omit<typeof this._dialog, 'content'|'opened'>> ) {
        const dialog = {
            content,
            opened: true,
            ...(opts || {}),
        };

        this._dialog.opened = false;
        window.setTimeout(action(() => this._dialog = dialog), 300);
    }

    closeSnackbar() {
        this._snackbar.opened = false;
    }

    get snackbar() {
        return this._snackbar;
    }

    closeDialog() {
        this._dialog.opened = false;
    }

    get dialog() {
        return this._dialog;
    }

    get dialogRef() {
        return this._dialogRef;
    }

    setDialogRef(ref: RefObject<HTMLElement>) {
        this._dialogRef = ref;
    }

    get hasPendingRequests(): boolean {
        return !!this._pendingRequests;
    }

    get breadcrumbs() {
        return this._breadcrumbs;
    }

    setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
        this._breadcrumbs = breadcrumbs;
    }

    get siteName() {
        return this._siteName;
    }

    get title() {
        return this._title;
    }

    setTitle(title: string) {
        this._title = title;
    }

    redirectTo(url: string, status: 301|302 = 301) {
        this._redirect = { url, status };
    }

    get redirect() {
        return this._redirect;
    }

    resetRedirect() {
        this._redirect = undefined;
    }

    scheduledTask(key: string, predicate: () => boolean = null): undefined|boolean {
        const user = this._store.user.user;
        const isLoggedIn = this._store.auth.isLoggedIn;

        if(user === undefined) return user as undefined;
        if(isLoggedIn === undefined) return isLoggedIn as undefined;
        if(!isLoggedIn || predicate && !predicate()) return false;


        const needToShow = !window.localStorage.getItem(`ScheduledTask_${user.id}_${key}`);
        if(needToShow)
            window.localStorage.setItem(`ScheduledTask_${user.id}_${key}`, '1');

        return needToShow;
    }

    trueUntilCanceled(key: string, predicate: () => boolean = null): [undefined|boolean, () => void] {
        const cancelFn = () => {
            window.localStorage.setItem(`TrueUntilCanceled_${user.id}_${key}`, '1');
        }

        const user = this._store.user.user;
        const isLoggedIn = this._store.auth.isLoggedIn;

        if(user === undefined) return user as undefined;
        if(isLoggedIn === undefined) return isLoggedIn as undefined;
        if(!isLoggedIn || predicate && !predicate()) return [false, cancelFn];


        const needToShow = !window.localStorage.getItem(`TrueUntilCanceled_${user.id}_${key}`);
        return [needToShow, cancelFn];
    }

    get theme() {
        return this._theme;
    }

    get themeLoaded(): boolean {
        return this._themeLoaded;
    }

}

export const AppContext = createContext<App>(undefined);