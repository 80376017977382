import {createContext} from "react";
import {Store} from "./index";
import { Item as CatalogItem } from "../common/item";
import {Bucket} from "./base/bucket";

type Item = CatalogItem & {

}

export type State = {
    items?: {
        list: Item[];
    }
    summary?: {
        totalItems: number;
        priceWithoutDiscount: number;
        priceWithDiscount?: number;
    },
};

export class WaitList extends Bucket<State, 'waitList'> {

    constructor(store: Store) {
        super(store, {
            endpointUrl: '/waitlist',
            tableName: 'waitList',
        });
    }
}

export const Context = createContext<WaitList>(undefined);