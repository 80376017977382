import { FC, useContext } from "react";
import { AppContext } from "../../../../store";
import { observer } from "mobx-react-lite";
import ErrorView from "../../ErrorView";
import LayoutVariant from "./LayoutVariant";
type Props = {
};

const WebView: FC<Props> = () => {
    const app = useContext(AppContext);

    
    const appError = app.error;

    if (appError) return <ErrorView />;

    return (<LayoutVariant />);
};

export default observer(WebView);