import { FC } from "react";
import { Box, Button, Container, Paper, Stack, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import Logo from "../Logo";

type Props = {
    readonly code: number;
    readonly message: string;
};

const ErrorPageTemplate: FC<Props> = ({ code, message }) => {
    const theme = useTheme();

    return (
        <Container fixed>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 1, textAlign: 'center' }}>
                    <Logo size="small" disableLink />
                    <Stack component={Paper} elevation={1} spacing={2} padding={3} alignItems="center">
                        <Typography variant="h4" fontWeight="normal" color={theme.palette.error.main}>
                            {code}
                        </Typography>
                        <Typography variant="h5" fontWeight="normal" color={theme.palette.error.main}>
                            {message}
                        </Typography>
                        <Button
                            href="/"
                            variant="contained"
                            size="large"
                        >
                            На главную
                        </Button>
                        {/* <Button
                            variant="contained"
                            size="small"
                            onClick={() => setCallDialogOpened(true)}
                        >
                            Заказать звонок
                        </Button> */}
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
};

export default observer(ErrorPageTemplate);