import { FC, useContext, useState } from "react";
import { Item, ItemVariant } from "../../../common/item";
import WideCard from "../WideCard";
import WideCardStepperAction from "../WideCardStepperAction";
import { BonusContext, CatalogContext } from "../../../store";
import { observer } from "mobx-react-lite";

type Props = {
    readonly item: Item;
    readonly inCartCount: number;
    readonly favorite: boolean;
    readonly onAddToCartClick: (count: number, variant?: ItemVariant) => Promise<any>;
    readonly onFavoritesClick: () => void;
    readonly onClick?: () => void;
    readonly alwaysExpanded?: boolean;
}

const CatalogWideCard: FC<Props> = (p) => {
    const count = p.item.quantity || 1;

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: count || 1,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });

    
    const catalog = useContext(CatalogContext);
    const variants = catalog.getItemVariants(p.item.id);

    const [currentVariantId, setCurrentVariantId] = useState(p.item.variants ? 0 : undefined);

    return (
        <WideCard
            id={p.item.id}
            imgSrc={p.item.imgCard}
            label={p.item.label}
            count={count}
            stock={p.item.count}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            favorite={p.favorite}
            inCartCount={p.inCartCount}
            onFavoritesClick={p.onFavoritesClick}
            onClick={p.onClick}
            aliasFull={p.item.aliasFull}
            variants={p.item.variants}
            variantsFull={variants}
            currentVariantId={currentVariantId}
            onCurrentVariantIdChange={setCurrentVariantId}
            action={<>
                <WideCardStepperAction
                    isPromotional={!!p.item.isPromotional}
                    count={count}
                    price={p.item.price}
                    oldPrice={p.item.oldPrice}
                    stock={p.item.count}
                    inCart={!!p.inCartCount}
                    onAddToCartClick={() => {
                        const variant = currentVariantId ?
                            variants[currentVariantId] : undefined;

                        p.onAddToCartClick(count, variant);
                    }}
                    outOfStock={p.item.count <= 0}
                    bonusValue={bonusValue}
                    variants={p.item.variants}
                    variantsFull={variants}
                    currentVariantId={currentVariantId}
                />
            </>}
        />
    );

}

export default observer(CatalogWideCard);