import { FC, ReactNode, SyntheticEvent, useState } from "react";
import {
    Box,
    Card as MuiCard,
    CardActions,
    Chip,
    useMediaQuery, useTheme,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    IconButton,
} from "@mui/material";
import Stepper from "../../Stepper";
import Title from "../Title";
import InCartIcon from '@mui/icons-material/ShoppingBasket';
import ItemImage from "../../ItemImage";
import { Link } from "react-router-dom";
import { ItemVariant } from "../../../common/item";
import LoadingPaper from "../../LoadingPaper";
import { Property } from "../../../common/item";
import HeightIcon from '@mui/icons-material/HeightOutlined';
import WeightIcon from '@mui/icons-material/ScaleOutlined';
import BatchItemsPrice from "../BatchItemsPrice";
import RemoveIcon from "../../../icons/delete.svg";

type Props = {
    readonly id: string;
    readonly aliasFull: string;
    readonly imgSrc?: string;
    readonly label: string;
    readonly count: number;
    readonly stock: number;
    readonly price: number;
    readonly oldPrice?: number;
    readonly packageSize: number;
    readonly articl: string;
    readonly cnpCode: string;
    readonly action?: ReactNode;
    readonly inCartCount?: number;
    readonly outOfStock?: boolean;
    readonly favorite?: boolean;
    readonly expanded?: boolean;
    readonly onFavoritesClick?: () => void;
    readonly onClick?: (e: SyntheticEvent) => void;
    readonly alwaysExpanded?: boolean;
    readonly hasSimilarItems?: boolean;
    readonly bonusValue?: number;
    readonly variants?: string[];
    readonly variantsFull?: ItemVariant[];
    readonly currentVariantId?: number;
    readonly variantName?: string;
    readonly miniProps?: Property[];
    readonly onCurrentVariantIdChange?: (value: number) => void;
    readonly onRemoveFromCartClick?: () => void;

    readonly onCountChange?: (count: number) => void;
    readonly onHoverChange?: (hover: boolean) => void;
}

const MiniProps:FC<{ miniProps?: Property[] }> = (p) => {

    const weightProp = p.miniProps?.find(p => p.id === 'p5');
    const heightProp = p.miniProps?.find(p => p.id === 'p4');

    return (
        <Stack sx={{ 
            lineHeight: '30px',
            '.prop > *': { verticalAlign: 'middle' },
            'svg': {
                borderRadius: '3px',
                border: '1px solid black',
            },
            minHeight: '62px',
        }}>
            {heightProp && (<div className="prop">
                <HeightIcon /> <span>{heightProp.values[0].value}</span>
            </div>)}
            {weightProp && (<div className="prop">
                <WeightIcon /> <span>{weightProp.values[0].value}</span>
            </div>)}
        </Stack>
    );
}

const c = (e: SyntheticEvent, fn?: CallableFunction) => (e.stopPropagation(), e.preventDefault(), fn && fn());

const Card: FC<Props> = (p) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [hover, setHover] = useState(false);

    const expanded = isMobile || p.alwaysExpanded || hover;

    const currentLink = `/catalog/${p.id}`;

    const isLoading = p.variants && p.variantsFull === undefined && p.variants[p.currentVariantId] !== p.variantName;
    
    return (<>
        {isLoading ? <LoadingPaper /> : (
            <Box
                component={p.onClick ? undefined : Link}
                to={p.onClick ? undefined : currentLink}
                onClick={p.onClick && (e => c(e, p.onClick))}
                sx={{
                    display: 'block',
                    position: 'relative',
                    cursor: p.onClick && 'pointer',
                    flexGrow: 1,
                }}
            >
                <MuiCard
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    sx={{
                        width: "100%",
                        position: expanded && !isMobile ? 'absolute' : undefined,
                        zIndex: expanded && !isMobile ? 1 : undefined,
                        left: expanded && !isMobile ? '50%' : undefined,
                        transform: expanded && !isMobile ? 'translateX(-50%)' : undefined,
                    }}
                >
                    <Box sx={{ p: 1 }}>
                        <ItemImage variant="card" src={p.imgSrc} alt={p.label} />
                    </Box>
                    {p.hasSimilarItems && (
                        <Chip
                            size="small"
                            label="Есть аналоги"
                            color="info"
                            sx={{
                                cursor: 'inherit',
                                color: theme.palette.secondary.main,
                                position: 'absolute',
                                top: 0, left: 0,
                                m: 1,
                                zIndex: 50,
                            }}
                        />
                    )}
                    {(p.inCartCount || 0) > 0 && (
                        <InCartIcon
                            sx={{
                                position: 'absolute',
                                right: 11,
                                top: 0,
                                mt: 1,
                                color: theme.palette.warning.main,
                                zIndex: 50,
                            }}
                        />
                    )}
                    <Box sx={{ p: 1 }}>
                        <Title value={p.label} />

                        <Stack sx={{ height: p.variants ? undefined : 66 }} alignItems="flex-start">
                            <BatchItemsPrice
                                count={Math.min(p.count, p.stock)}
                                price={p.price}
                                oldPrice={p.oldPrice}
                            />
                        </Stack>
                        <MiniProps miniProps={p.miniProps} />
                        <Box
                            sx={{
                                display: expanded ? undefined : 'none',
                                mt: '15px',
                            }}
                        >
                            <Stack spacing={1}>
                                {p.variants && (
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel id="demo-select-small-label">Вариант</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={p.currentVariantId}
                                            label="Вариант"
                                            onChange={e => p.onCurrentVariantIdChange(+e.target.value)}
                                            onClick={c}
                                            MenuProps={{
                                                transformOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                                anchorOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }
                                            }}
                                        >
                                            {p.variants.map((v, i) => (
                                                <MenuItem key={i} value={i}>{v}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Stack>

                            <CardActions
                                sx={{
                                    justifyContent: 'space-between',
                                    padding: "10px 0 0 0",
                                    '& .MuiButtonBase-root': {
                                        borderRadius: "12px"
                                    }
                                }}
                                disableSpacing
                            >
                                {p.onCountChange ? (
                                    <Stepper
                                        value={p.count}
                                        onChange={p.onCountChange}
                                        onClick={e => c(e)}
                                    />
                                ) : (
                                    p.count > 1 ? <Chip label={p.count} /> : undefined
                                )}
                                {p.onRemoveFromCartClick && (
                                    <IconButton
                                        aria-label="remove"
                                        sx={{ p: 0 }}
                                        onClick={e => c(e, p.onRemoveFromCartClick)}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                )}
                                {p.action}
                            </CardActions>
                        </Box>
                    </Box>
                </MuiCard >
            </Box >
        )}
    </>);
}

export default Card;