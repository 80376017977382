import {FC, useContext, useEffect} from "react";
import {
    Unstable_Grid2 as Grid,
} from "@mui/material";
import {observer} from "mobx-react-lite";
import OrderBar from "../../../components/views/CartView/OrderBar";
import UnifiedView from "../../../components/views/CartView/UnifiedView";
import { AppContext } from "../../../store";
import HeadingView from "../../../components/views/HeadingView";
import Breadcrumbs from "../../../components/Breadcrumbs";

type Props = {

};


const CartView: FC<Props> = () => {
    const app = useContext(AppContext);

    useEffect(() => {
        app.setTitle('Корзина');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Корзина' },
        ])
    });

    return (<>
        <HeadingView />
        <Grid container columnSpacing={3} direction={{ xs: 'column-reverse', md: 'row' }} sx={{pb: 10}}>
            <Grid xs={12} md={8} lg={9}>
                <Breadcrumbs items={[ {name: 'Главная', to: '/'}, { name: 'Корзина' } ]} />
                <UnifiedView />
            </Grid>
            <Grid xs={12} md={4} lg={3}>
                <OrderBar />
            </Grid>
        </Grid>
    </>);
};

export default observer(CartView);