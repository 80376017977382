import { FC, useContext, useEffect, useState } from "react";
import { Badge, BadgeProps, Box, Button, ButtonProps, Grid, Link, Paper, Popper, Stack, Tooltip, TooltipProps, Zoom, styled, tooltipClasses, useMediaQuery, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import SearchBar from "../../../components/SearchBar";
import Logo from "../../../components/Logo";
import MenuBar, { MenuItem } from "../../../components/MenuBar";
import { AppContext, CartContext, CatalogContext } from "../../../store";
import CartIcon from '@mui/icons-material/ShoppingCart';
import CallIcon from '@mui/icons-material/LocalPhoneRounded';
import { Link as RouterLink, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import CallMeDialog from "../../../components/CallMeDialog";
import { TreeLeaf } from "../../../common/tree";

type Props = {

}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        color: 'white',
    },
}));

const RequestCallButton: FC<ButtonProps> = (p) => {
    const app = useContext(AppContext);

    return (
        <Button
            startIcon={<CallIcon />}
            children="Заказать звонок"
            variant="outlined"
            onClick={() => app.showDialog(<CallMeDialog />, { fullWidth: false })}
            {...p}
        />
    );
};

const ItemsWithSubs: FC<{ item: TreeLeaf }> = (p) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onEnter = (event: React.MouseEvent<HTMLElement>) => {
        if(isMobile) return;

        handleOpen(event);
    }

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if(!isMobile) return;

        handleOpen(event);
    }

    const children = Object.values(p.item.children);

    return (<>
        <MenuItem
            onMouseEnter={onEnter}
            onMouseLeave={() => setAnchorEl(null)}
            onClick={onClick}
            name={p.item.label}
            to={`/catalog/${p.item.aliasfull}`}
        >
            <Popper open={!!anchorEl} anchorEl={anchorEl} placement="bottom-start" sx={{zIndex: 2}}>
                <Paper
                    sx={{
                        borderRadius: '12px',
                    }}
                >
                    <MenuBar>
                        {children.map(v => (
                            <MenuItem
                                key={v.id}
                                name={v.label}
                                to={`/catalog/${v.aliasfull}`}
                            />
                        ))}
                    </MenuBar>
                </Paper>
            </Popper>
        </MenuItem>
    </>);
};


const HeadingView: FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');

    const catalog = useContext(CatalogContext);
    const cart = useContext(CartContext);
    const navigate = useNavigate();

    const topCategories = catalog.getTreeLevel()?.list;
    const count = cart.summary?.totalItems || 0;

    useEffect(() => {

        setSearchQuery(searchParams.get('q') || '')

    }, [searchParams.get('q')]);


    return (
        <Box>
            <Box
                sx={{ py: 2 }}
            >
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="space-between"
                            alignItems={{ xs: 'center', sm: undefined }}
                            spacing={1}
                        >
                            <Logo size="normal" />
                            <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                                <Link href="tel:+79688626690" children="8 (968) 862-66-90" variant="h5" underline="none" /><br />
                                <RequestCallButton size="medium" />
                            </Box>
                            <div />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Stack
                            spacing={2}
                            direction={{ xs: 'column', sm: 'row' }}
                            sx={{ mt: 1 }}
                            alignItems={{ sm: 'center' }}
                        >
                            <SearchBar
                                value={searchQuery}
                                onValueChange={v => setSearchQuery(v)}
                                onSearchClick={() => {
                                    const params = Object.fromEntries(searchParams.entries());
                                    if (searchQuery.length) {
                                        params.q = searchQuery;
                                        delete params.f;
                                    } else
                                        delete params.q;

                                    const search = Object.keys(params) ? `?${createSearchParams(params)}` : '';

                                    navigate({ pathname: '/catalog/', search });
                                }}
                                placeholder="Поиск по товарам"
                            />
                            <Stack direction={{ xs: 'row', sm: 'column' }} gap={1} justifyContent="space-between">
                                <RequestCallButton size="large" sx={{ display: { sm: 'none' } }} />
                                <Button
                                    startIcon={<CartIcon />}
                                    component={RouterLink}
                                    to="/cart"
                                    size="large"
                                    variant="outlined"
                                >
                                    <Zoom in={!!count && location.pathname !== '/cart'} unmountOnExit>
                                        <StyledBadge
                                            // badgeContent={count} убираем число товаров в корзине
                                            color="warning"
                                            variant="dot"
                                            sx={{
                                                position: "absolute",
                                                right: "7px",
                                                top: { lg: "13px", xs: "3px" },
                                                zIndex: 2
                                            }}
                                        />
                                    </Zoom>
                                    Корзина
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{ pb: 2 }}
            >
                <MenuBar horizontal>
                    {Object.values(topCategories || {}).map(v => (
                        <ItemsWithSubs key={v.id} item={v} />
                    ))}
                </MenuBar>
            </Box>
        </Box>
    );
}

export default observer(HeadingView);