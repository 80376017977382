import { Box, BoxProps, SxProps } from "@mui/material";
import NoImage from "../NoImage";

export type Props = {
    readonly src?: string;
    readonly alt?: string;
    readonly sx?: SxProps;
    readonly width?: BoxProps["width"];
    readonly height?: BoxProps["height"];
    
    readonly maxWidth?: BoxProps["maxWidth"];
    readonly maxHeight?: BoxProps["maxHeight"];
}

const ItemImageBase = (p: Props) => {

    if (!p.src) {
        return (
            <NoImage
                width={p.width}
                height={p.height}
                maxWidth={p.maxWidth}
                maxHeight={p.maxHeight}
            />
        );
    }

    return (
        <Box
            component="img"
            src={p.src}
            alt={p.alt}
            sx={{
                width: p.width,
                height: p.height,
                maxWidth: p.maxWidth,
                maxHeight: p.maxHeight,
                objectFit: 'contain',
                objectPosition: 'center',
                ...(p.sx || {}),
            }}
        />
    );
}

export default ItemImageBase;