import {createContext} from "react";
import {action, computed, makeObservable, observable, toJS} from "mobx";
import {merge, Store} from "./index";
import {request} from "../utils";
import { CacheableWithPagination } from "../common/caching";

type Item = {

}

export type State = {
    readonly items?: CacheableWithPagination & {
        list: Item[];
    }
};

export class Saleout {
    _store: Store;
    _state: State = {
        items: undefined,
    };

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            items: computed,
            setState: action,
        });
    }

    setState(state: State | undefined) {
        this._state = merge(this._state, state || {});
    }

    get items() {
        if(this._state.items === undefined) {

            request({
                method: 'GET',
                url: '/saleout',
                headers: {'App-Data-Only': 'yes'},
                baseURL: '',
            })
            .then(res => {
                this._store.setState(res.data.state);
            })
        }

        return this._state.items;
    }
    loadNextPage() {

        request({
            method: 'GET',
            url: '/saleout',
            headers: {'App-Data-Only': 'yes'},
            baseURL: '',
            params: {
                page: this._state.items.nextPage,
            }
        })
        .then(res => {
            this._store.setState(res.data.state);
        })
    }
}

export const Context = createContext<Saleout>(undefined);