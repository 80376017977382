import { createElement, FC, useContext } from "react";
import {
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { observer } from "mobx-react-lite";

import { ListItemItem, Patch } from "../../../../List";
import { BonusTransaction } from "../Imports";
import { BonusContext } from "../../../../../store";

type Props = {
    readonly items: ListItemItem<BonusTransaction>[];
    readonly component: FC<{ readonly item: BonusTransaction, readonly events?: Record<string, CallableFunction>, patch?: any }>;
    readonly events?: Record<string, CallableFunction>;
    readonly patch?: Patch;
    readonly keyResolver: (item: any) => number | string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ListTableBonus: FC<Props> = ({ items, component, events, patch, keyResolver }) => {

    const bonus = useContext(BonusContext);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead
                    sx={{
                        '& .MuiTableCell-root.MuiTableCell-head': {
                            backgroundColor: "rgba(108, 93, 211, 0.10)",
                            color: "#8F95B2",
                            fontSize: "14px",
                            fontWeight: "bold"
                        }
                    }}
                >
                    <TableRow >
                        <StyledTableCell sx={{ fontWeight: "bold" }} align="center">№</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: "bold" }} align="center">Номер заказа</StyledTableCell>
                        <StyledTableCell align="center">Дата</StyledTableCell>
                        {bonus.status?.enabled && (
                            <StyledTableCell align="center">Баллы</StyledTableCell>
                        )}
                        <StyledTableCell align="center">Комментарий</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map(item => {
                        const key = keyResolver(item);
                        const p = patch && patch[key];

                        return createElement(component, { item: item.item, key, events, patch: p });
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default observer(ListTableBonus);