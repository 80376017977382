import { observer } from "mobx-react-lite";
import { FC, useContext, useMemo } from "react";
import Heading from "../HeadingView";
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoadingPaper from "../../LoadingPaper";
import { AppContext, CartContext, CatalogContext } from "../../../store";
import Price from "../../Item/Price";
import Breadcrumbs from "../../Breadcrumbs";
import { range } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import CartIcon from '@mui/icons-material/ShoppingCart';
import QuickBuyDialog from "../../QuickBuyDialog";
import Carousel from "../../Carousel";
import Grid from '@mui/material/Unstable_Grid2';
import HTMLRenderer from "../../WYSIWYG/HTMLRenderer";
import { Item } from "../../../common/item";
import { ItemVariants } from "../../../store/catalog";

type Props = {

}

const getUrlParams = (fullPath: string) => {
    const [, , , , , path] = fullPath.match(new RegExp(`((\\/(_none_)(\\/|$))?\\/?((\\/\\w|\\w|\\d|-)+)?)?\\/?`, 'mi'));

    const basePath = '/catalog' + (path ? '/' + path : '');

    return [path, basePath];
}

const BuyPanel: FC<{ item?: Item, variants?: ItemVariants }> = (p) => {
    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    const navigate = useNavigate();

    return (
        <Paper sx={{ p: 2, my: { xs: 4, md: 0 } }} elevation={1}>
            <Stack spacing={2}>
                <Price value={p.item.price} />
                {p.variants && (
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Вариант</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={p.variants.findIndex(v => v.name == p.item.variantName)}
                            label="Вариант"
                            onChange={e => navigate(`/catalog/${p.variants[+e.target.value].id}`)}
                        >
                            {p.variants.map((v, i) => (
                                <MenuItem key={i} value={i}>{v.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <Stack direction="row" spacing={1}>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ flexGrow: 2 }}
                        onClick={() => {
                            app.showDialog(
                                <QuickBuyDialog item={p.item} />,
                                { fullWidth: false }
                            );
                        }}
                    >
                        Купить в 1 клик
                    </LoadingButton>
                    <IconButton
                        color="primary"
                        onClick={async e => {
                            e.stopPropagation();
                            e.preventDefault();

                            await cart.add(p.item);

                            app.alert(`Добавлено в корзину`);
                        }}
                    >
                        <CartIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Paper>
    );
}

const CatalogCardView: FC<Props> = () => {
    const theme = useTheme();
    const catalog = useContext(CatalogContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const location = useLocation();

    const [path] = useMemo(() => getUrlParams(location.pathname.substring('/catalog'.length)), [location.pathname]);

    const data = catalog.getItemByFullPath(path);
    const item = data?.item;
    const _breadcrumbs = data?.breadcrumbs;
    const breadcrumbs = _breadcrumbs?.concat();
    if (item && breadcrumbs) breadcrumbs.push({ name: item.label });

    const variants = catalog.getItemVariants(item.mainItemId);

    const images = [
        { src: item.imgFull || null },
    ];
    if (item.images)
        images.push(...item.images.map(v => ({ src: v.url })));

    return (<>
        <Heading />
        <Grid columnSpacing={3} direction={{ xs: 'column', md: 'row' }} container>
            <Grid xs={12} md={7} lg={9}>
                {item !== undefined ? (<>
                    <Typography variant="h1">
                        {item.label}
                    </Typography>
                    <Breadcrumbs items={breadcrumbs} />

                    <Carousel images={images} sx={{ mb: 1 }} />


                    {isMobile && <BuyPanel item={item} variants={variants} />}

                    <Stack spacing={2}>
                        <Typography variant="h6">
                            <strong>Все характеристики</strong>
                        </Typography>

                        {item.props?.length ? item.props.map(prop => (
                            <Box
                                key={prop.name}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    flexWrap: 'wrap',
                                    gap: 1,
                                }}
                            >
                                <strong>{prop.name}:</strong>
                                {prop.values.map(v => `${v.value}${prop.postfix || ''}`).join(', ')}
                            </Box>
                        )) : undefined}
                        {item.props?.length === 0 && (
                            <Typography variant="body1">
                                <strong>На данный момент список характеристик пуст</strong>
                            </Typography>
                        )}
                        {item.props === undefined && (
                            range(13).map(i => (
                                <Box key={i} sx={{
                                    display: 'flex',
                                    gap: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    <Skeleton
                                        variant="rounded"
                                        width={(10 + Math.random() * 15) + '%'}
                                        height={25}
                                    />
                                    <Skeleton
                                        variant="rounded"
                                        width={(80 - Math.random() * 25) + '%'}
                                        height={25}
                                    />
                                </Box>
                            ))
                        )}
                    </Stack>
                    {item.description && <HTMLRenderer sx={{ mt: 2 }} children={item.description} />}

                </>) : (
                    <LoadingPaper />
                )}
            </Grid>
            <Grid xs={12} md={5} lg={3}>
                {!isMobile && <BuyPanel item={item} variants={variants} />}

                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Stack spacing={1} sx={{ mt: 2 }}>
                        {item.groupedProps === undefined && (
                            range(4).map(i => (
                                <Box key={i} sx={{
                                    display: 'flex',
                                    gap: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    <Skeleton
                                        variant="rounded"
                                        width={(10 + Math.random() * 15) + '%'}
                                        height={25}
                                    />
                                    <Skeleton
                                        variant="rounded"
                                        width={(80 - Math.random() * 25) + '%'}
                                        height={25}
                                    />
                                </Box>
                            ))
                        )}
                        {item.groupedProps && item.groupedProps[1] ? item.groupedProps[1].map(prop => (
                            <Box
                                key={prop.name}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    flexWrap: 'wrap',
                                    gap: 1,
                                }}
                            >
                                <strong>{prop.name}:</strong>
                                {prop.values.map(v => `${v.value}${prop.postfix || ''}`).join(', ')}
                            </Box>
                        )) : undefined}
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    </>);
}

export default observer(CatalogCardView);