import './styles/app.scss';

import { createRoot, hydrateRoot } from "react-dom/client";
import axios from "axios";
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import {
    Context as StoreContext,
    StoreState, Store,
} from "./store";
import { PropsWithChildren, useState } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes, { Providers } from './routes';
///

try {
    // Sentry.init({
    //     dsn: "https://afadc4e80acc4dfbb046921acdbba39a@glitchtip.avtomasla.su/2",
    //     integrations: [new BrowserTracing()],
    
    //     // Set tracesSampleRate to 1.0 to capture 100%
    //     // of transactions for performance monitoring.
    //     // We recommend adjusting this value in production
    //     tracesSampleRate: 1.0,
    // });

}
catch (e) {
    // Не должно приложение падать из-за этого
    console.error(e);
}

axios.defaults.baseURL = '/api/v1';
dayjs.extend(timezone);
dayjs.locale('ru');
dayjs.tz.setDefault('Europe/Kirov');

const appRoot = document.getElementById('app-root');
const state: StoreState | undefined = JSON.parse(appRoot.dataset.initialState) ?? undefined;
setTimeout(() => appRoot.removeAttribute('data-initial-state'));

const StoreProvider = (p: PropsWithChildren) => {
    const [store] = useState(new Store(state));

    return (
        <StoreContext.Provider value={store}>
            {p.children}
        </StoreContext.Provider>
    );
}

const router = createBrowserRouter(routes);

hydrateRoot(appRoot, (
    <StoreProvider>
        <Providers>
            <RouterProvider 
                router={router}
            />
        </Providers>
    </StoreProvider>
));