import { Button, Stack, TextField, useTheme } from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid";
import { FC } from "react";

type Props = {
    readonly value: string;
    readonly onValueChange: (value: string) => void;
    readonly onSearchClick: () => void;
    readonly placeholder?: string;
};

const SearchBar: FC<Props> = (p) => {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            sx={{ position: "relative", flexGrow: 2 }}
            component="form"
            onSubmit={e => {
                e.preventDefault();
                p.onSearchClick();
            }}>
            <TextField
                fullWidth
                id="input-with-icon-textfield"
                placeholder={p.placeholder}
                color="primary"
                value={p.value}
                onChange={e => p.onValueChange(e.target.value)}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        display: "none"
                    },
                    '& .MuiOutlinedInput-input': {
                        padding: "10px"
                    },
                    '& .MuiTextField-root': {
                        padding: "25px"
                    },
                    // maxWidth: 390,
                    border: "1px solid #3F8CFF",
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 999,
                    borderRadius: "4px 0 0 4px",
                }}
            />
            <Button
                color="primary"
                type="submit"
                size="large"
                variant="contained"
                sx={{
                    boxShadow: 'none !important',
                    borderRadius: "0 4px 4px 0",
                }}
            >
                <GridSearchIcon />
            </Button>
        </Stack>
    );
}

export default SearchBar;