import { FC } from "react";
import SimpleStore from "../../../../layout/WebView/SimpleStore";
type Props = {
};

const LayoutVariant: FC<Props> = () => {

    return (<SimpleStore />);
};

export default LayoutVariant;