import {createContext} from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {merge, Store} from "./index";
import {request} from "../utils";
import { Cacheable, CacheableWithPagination } from "../common/caching";
import { Item } from "../common/item";

export type State = {
    readonly items: {
        readonly byCategoryId: Record<number, CacheableWithPagination & {
            list: Item[];
            nextPage: number|null;
            readonly pageCount: number;
        }>
    },
    readonly categories?: Cacheable & {
        readonly list: {id: number, name: string}[];
    };
};

export class Recommended {
    _store: Store;
    _state: State = {
        items: {
            byCategoryId: {}
        },
    };

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            setState: action,
            categories: computed,
        });
    }

    setState(state: State|undefined) {
        this._state = merge(this._state, state || {});
    }

    get categories() {

        if(this._state.categories?.list === undefined) {
            request({
                method: 'GET',
                url: `/recommended`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: '',
            }).then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state.categories?.list;
    }

    getItems(categoryId: null|number) {
        const params = categoryId ? { cat: categoryId+''} : undefined;

        if(this._state.items.byCategoryId[categoryId] === undefined) {
            request({
                method: 'GET',
                url: `/recommended`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: '',
                params,
            }).then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state.items.byCategoryId[categoryId];

    }

    loadNextPage(categoryId: null|number) {
        const params = {
            cat: categoryId+'',
            page: this._state.items.byCategoryId[categoryId].nextPage+'',
        };
        if(!categoryId) delete params.cat;

        request({
            method: 'GET',
            url: `/recommended`,
            headers: {'App-Data-Only': 'yes'},
            baseURL: '',
            params,
        }).then(res => {
            this._store.setState(res.data.state);
        });
    }
}

export const Context = createContext<Recommended>(undefined);