export function indexBy<T,K extends string|number>(
    array: ReadonlyArray<Readonly<T>>,
    callback: (item: T) => K,
    merge?: (prev: T, curr: T) => void,
): Record<K, T> {
    if(!array) return {} as Record<K, T>;

    const indexed = {} as Record<K,T>;

    if(merge) {
        for(const it of array) {
            const k = callback(it);
            if(indexed[k])
                merge(indexed[k], it);
            else
                indexed[k] = it;
        }
    }
    else {
        for(const it of array)
            indexed[callback(it)] = it;
    }

    return indexed;
}