import { themed } from "../../../../theme"
import { Button, Stack, Typography } from "@mui/material";
import CallMeDialog, { Data } from "../../../CallMeDialog";
import { useContext, useEffect, useState } from "react";
import { AppContext, UserContext } from "../../../../store";

type Props = {
    readonly variant?: 'login' | 'menu';
}

const CallMeButton = (p: { onClick: () => void }) => {

    return (
        <Button
            onClick={p.onClick}
            color="secondary"
            variant="outlined"
            sx={{ width: '100%', mt: 2 }}
            size="small">
            Заказать звонок
        </Button>
    );
}

const ExtraInfo = (p: Props) => {
    const variant = p.variant || 'menu';

    const app = useContext(AppContext);

    return (<>
        {variant === 'login' && <CallMeButton onClick={() => app.showDialog(<CallMeDialog />)} />}
        <Stack pt={1} alignItems="center">
            <Typography component="a" href="tel:+78005504370" variant="subtitle2" fontWeight="normal" color="#3F8CFF">
                +7-800-550-43-70
            </Typography>
            <Typography component="a" href="tel:+78332735770" variant="subtitle2" fontWeight="normal" color="#3F8CFF">
                +7-833-273-57-70
            </Typography>
        </Stack>
        {variant === 'menu' && <CallMeButton onClick={() => app.showDialog(<CallMeDialog />)} />}
    </>);
}

export default themed('ExtraInfo', ExtraInfo);