import { FC, SyntheticEvent, useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
    Alert,
    createTheme,
    CssBaseline,
    Dialog,
    DialogContent,
    Snackbar,
    ThemeProvider,
    Typography
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { } from '@mui/x-date-pickers/themeAugmentation';

import { AppContext, AuthContext } from "../store";
import { Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom";

type Props = {
};

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xsm: true,
        smm: true,
    }
}

const App: FC<Props> = (p) => {
    const theme = createTheme({
        palette: {
            background: {
                default: '#FAFBFF',
            }
        },
        typography: {
            h1: {
                fontSize: '2.5rem'
            },
            h2: {
                fontSize: '1.8rem'
            }
        },
        components: {
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        maxHeight: '250px !important',
                    }
                }
            }
        }
    });
    const app = useContext(AppContext);
    const auth = useContext(AuthContext);

    const snackbar = app.snackbar;
    const dialog = app.dialog;

    const dialogRef = useRef<HTMLElement>();

    const title = app.title;
    const siteName = app.siteName;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        app.setDialogRef(dialogRef);

        return () => app.setDialogRef(undefined);
    });

    const handleSnackbarClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;

        app.closeSnackbar();
    };

    const handleDialogClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;

        app.closeDialog();
    };

    useEffect(() => {
        if (!dialog) return;

        app.closeDialog();

    }, [location]);

    useEffect(() => {
        if (!title || !siteName) return;

        document.title = `${title} | ${siteName}`;

    }, [title, siteName]);

    if (app.redirect) {
        setTimeout(() => {
            app.resetRedirect();
            navigate(app.redirect.url);
        });
    }

    const isLoggedIn = auth.isLoggedIn;
    const themeLoaded = app.themeLoaded;

    if (isLoggedIn === undefined || !themeLoaded) return <></>;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Outlet />
                <Dialog
                    open={dialog.opened}
                    onClose={handleDialogClose}
                    scroll="body"
                    maxWidth="lg"
                    fullWidth={dialog.fullWidth === undefined ? true : dialog.fullWidth}
                    ref={(ref) => {
                        dialogRef.current = ref?.getElementsByClassName('MuiDialog-container')[0] as HTMLElement;
                    }}
                    keepMounted
                >
                    <DialogContent>
                        {typeof dialog.content == 'string' ? (
                            <Typography variant="subtitle2" children={dialog.content} />
                        )
                            : dialog.content}
                    </DialogContent>
                </Dialog>
                <Snackbar
                    open={snackbar.opened}
                    autoHideDuration={snackbar.time}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbar.severity}
                        action={snackbar.action}
                    >
                        {typeof snackbar.content == 'string' ? (
                            <Typography variant="subtitle2" children={snackbar.content} />
                        )
                            : snackbar.content}
                    </Alert>
                </Snackbar>
                <ScrollRestoration />
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default observer(App);