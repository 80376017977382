import { Box } from "@mui/material";
import bg from "../../../../images/loginbg.png";
import { themed } from "../../../../theme";
import { FC, PropsWithChildren } from "react";

type Props = PropsWithChildren & {}

const LoginBackground: FC<Props> = (p) => {

    return (
        <Box
            sx={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
            }}
        >
            {p.children}
        </Box>
    );
}

export default themed('LoginBackground', LoginBackground);