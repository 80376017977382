import {FC} from "react";
import {CircularProgress, Paper, PaperProps} from "@mui/material";
import * as React from "react";

type Props = PaperProps & {

};

const LoadingPaper: FC<Props> = ({ sx, ...p }) => {
    return (
        <Paper sx={{ py: 3, textAlign: 'center', ...sx }} {...p}>
            <CircularProgress disableShrink />
        </Paper>
    );
}

export default LoadingPaper;