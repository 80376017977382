import React, {FC} from "react";
import {List, ListItemButton, ListItemText} from "@mui/material";

type Category = {
    readonly id: number;
    readonly name: string;
};

type Props = {
    readonly categories: ReadonlyArray<Category>;
    readonly selectedId: number;
    readonly onSelect: (item?: Category) => void;
};

const CategorySelector: FC<Props> = ({ selectedId, onSelect, categories: items }) => {

    return (<>
        <List sx={{ p: { sx: 1, md: 0, lg: 1 } }}>
            <ListItemButton selected={selectedId === 0}
                onClick={() => onSelect(undefined)}
            >
                <ListItemText primary="Все категории" />
            </ListItemButton>
            {items.map(it => (
                <ListItemButton selected={selectedId == it.id} key={it.id}
                    onClick={() => onSelect(it)}
                >
                    <ListItemText primary={it.name} />
                </ListItemButton>
            ))}
        </List>
    </>);
};

export default CategorySelector;