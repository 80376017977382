import {Item} from "../../index";
import {AlgorithmPassResult} from "../index";

type ItemsRecord<T={}> = Record<number, T & {
    requiredCount: number;
}>;

export type Params = {
    readonly requiredTotalPrice: number;
    readonly items: Record<number, {}>;
    readonly itemsToGive: ItemsRecord<{
        readonly price: number;
    }>;
};

export const getIncludedItems = (params: Readonly<Params>): { id: string }[] => {

    const byId: Record<number, true> = {};
    for(const id in params.items)
        byId[id] = true;
    for(const id in params.itemsToGive)
        byId[id] = true;

    const items: {id: string}[] = Object.keys(params.itemsToGive).map(id => ({ id }));
    for (const id in params.items) {
        if(params.itemsToGive[id]) continue;
        items.push({ id });
    }

    return items;
}

export const getSampleSet = (items: ReadonlyArray<Readonly<{ id: string, stock: number, price: number }>>, params: Readonly<Params>): { id: string, count: number }[] => {
    let neededPrice = params.requiredTotalPrice;
    let totalPrice = 0;

    let giftPositions = {...params.itemsToGive};

    const res: {id: string, count: number}[] = [];
    for(const item of items) {
        if(totalPrice >= neededPrice && !Object.keys(giftPositions).length) break;
        if(item.stock <= 0) continue;

        if(params.items[item.id]) {
            if(params.itemsToGive[item.id]) {
                const giftCount = params.itemsToGive[item.id].requiredCount;
                if(item.stock < giftCount) return [];
                delete giftPositions[item.id];

                let count = 0;
                if(item.price) {
                    count = Math.min(item.stock - giftCount, Math.ceil((neededPrice - totalPrice) / item.price));
                    totalPrice += item.price * count;
                }

                res.push({ id: item.id, count: count + giftCount });
            }
            else {
                if(item.price) {
                    const count = Math.min(item.stock, Math.ceil((neededPrice - totalPrice) / item.price));
                    totalPrice += item.price * count;

                    res.push({ id: item.id, count });
                }
            }
        }
        else if(params.itemsToGive[item.id]) {
            const count = params.itemsToGive[item.id].requiredCount;
            if(item.stock < count) return [];
            delete giftPositions[item.id];

            res.push({ id: item.id, count });
        }
    }

    if(totalPrice < neededPrice || Object.keys(giftPositions).length) return [];


    return res;
}

export const getItemsSet = (items: ReadonlyArray<Readonly<{ id: string, price: number, count: number, stock: number }>>, setsCount: number, params: Readonly<Params>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} => {
    const requiredPrice = params.requiredTotalPrice * setsCount;
    let totalPrice = 0;

    let maxPrice = 0;
    const result: {id: string, count: number}[] = [];
    const giftPositions = {...params.itemsToGive};

    for(const item of items) {
        if(params.itemsToGive[item.id]) {
            delete giftPositions[item.id];
            const count = setsCount * params.itemsToGive[item.id].requiredCount;
            if(item.stock < count)
                return { maxSetsCount: 0, items: items.map(v => ({ id: v.id, count: v.count })) };

            result.push({ id: item.id, count });
        }
        else if(params.items[item.id] && item.price) {
            const count = Math.ceil(Math.max(0, requiredPrice - totalPrice) / item.price);
            const itemCount = Math.min(count, item.stock);
            totalPrice += itemCount * item.price;
            maxPrice += item.stock * item.price;

            result.push({id: item.id, count: itemCount});
        }

    }
    if(Object.keys(giftPositions).length)
        return { maxSetsCount: 0, items: items.map(v => ({ id: v.id, count: v.count })) };

    const maxSetsCount = Math.floor(maxPrice / params.requiredTotalPrice);
    if(setsCount > maxSetsCount) return { maxSetsCount: 0, items: items.map(v => ({ id: v.id, count: v.count })) };


    return { maxSetsCount, items: result };
}

export const getTargetPrices = (items: ReadonlyArray<{ id: string, price: number }>, params: Readonly<Params>): {
    id: string,
    price: number,
}[] => {
    return items.map(v => ({id: v.id, price: params.itemsToGive[v.id]?.price || v.price }));
}

const DefinedPriceSet = (items: ReadonlyArray<Readonly<Item>>, params: Readonly<Params>): AlgorithmPassResult => {
    const untouched: Item[] = items.map(v => ({...v}));
    if(!items.length) return { affected: [], untouched, groupsCount: 0 };

    let giftPositions = {...params.itemsToGive};

    let totalPrice = 0;
    let setsCount: number = null;
    for(const item of items) {
        if(item.stock <= 0) continue;

        if(params.itemsToGive[item.id]) {
            delete giftPositions[item.id];

            const count = Math.min(item.count, item.stock);
            if(setsCount === null)
                setsCount = Math.floor(count / params.itemsToGive[item.id].requiredCount);
            else
                setsCount = Math.min(setsCount, Math.floor(count / params.itemsToGive[item.id].requiredCount));
        }
        else if(params.items[item.id]) {
            if(item.price) {
                const count = Math.min(item.count, item.stock);
                totalPrice += item.price * count;
            }
        }
    }
    if(totalPrice < params.requiredTotalPrice || !setsCount || Object.keys(giftPositions).length)
        return { affected: [], untouched, groupsCount: 0 };

    setsCount = Math.min(setsCount, Math.floor(totalPrice / params.requiredTotalPrice));


    totalPrice = 0;
    const requiredPrice = params.requiredTotalPrice * setsCount;
    const affected: Item[] = [];
    for(let idx = 0; idx < untouched.length; idx++) {
        const item = untouched[idx];
        if(item.stock <= 0) continue;

        if(params.itemsToGive[item.id]) {

            const giftCount = params.itemsToGive[item.id].requiredCount * setsCount;
            if(giftCount < item.count)
                item.count -= giftCount;
            else
                untouched.splice(idx--, 1);

            affected.push({...item, count: giftCount, price: params.itemsToGive[item.id].price});
        }
        else if(params.items[item.id]) {
            if(item.price && totalPrice < requiredPrice) {
                const count = Math.min(Math.ceil((requiredPrice - totalPrice) / item.price), item.count, item.stock);

                if(count < item.count)
                    item.count -= count;
                else
                    untouched.splice(idx--, 1);

                affected.push({...item, count});

                totalPrice += item.price * count;
            }
        }
    }

    return { affected, untouched, groupsCount: setsCount };
};

export default DefinedPriceSet;