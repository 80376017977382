import { FC, useContext, useEffect, useState } from "react";
import {
    Button,
    Unstable_Grid2 as Grid,
    Paper,
    Stack, Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { AppContext, CartContext, FavoritesContext, UserContext } from "../../../store";
import Heading from "../HeadingView";
import LoadingPaper from "../../LoadingPaper";
import { APIError } from "../../../store/app";
import FavoritesWideCard from "../../Item/FavoritesWideCard";
import ListEmptyItem from "../../ListEmptyItem";

type Props = {

};


const FavoritesView: FC<Props> = () => {
    const favorites = useContext(FavoritesContext);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);
    const [count, setCount] = useState(1);

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Избранное');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Избранное' },
        ])
    });

    const summary = favorites.summary;
    const items = user.user?.id ? favorites.items : undefined;

    return (<>
        <Heading/>
        <Grid container columnSpacing={3} direction={{ xs: 'column-reverse', md: 'row' }}>
            <Grid xs={12} md={8} lg={9}>
                {!!items?.list?.length && (
                    <Stack spacing={1}>
                        {items?.list.map(item => (
                            <FavoritesWideCard
                                key={item.id}
                                item={item}
                                inCart={cart.includes(item.id)}
                                count={count}
                                onCountChange={setCount}
                                favorite={true}
                                onFavoritesClick={async () => {
                                    const { orderPrice, ...cartItem } = item;
                                    await favorites.remove(cartItem);
                                    app.alert(`Удалено из избранного`);
                                }}
                                onAddToCartClick={async () => {
                                    const { orderPrice, ...cartItem } = item;
                                    await cart.addWithAmount(cartItem, count);
                                    setCount(1);
                                    app.alert(`Добавлено в корзину`);
                                }}
                            />
                        ))}
                    </Stack>
                )}
                {!items && (
                    <LoadingPaper />
                )}
                {items && !items.list.length && (
                    <ListEmptyItem />
                )}
            </Grid>
            <Grid xs={12} md={4} lg={3}>
                {summary ? (
                    <Paper sx={{ p: 2, mb: { xs: 4, md: 0 } }} elevation={1}>
                        <Stack direction="column" spacing={2}>
                            <Button
                                size="large"
                                variant="text"
                                color="error"
                                disabled={!summary.totalItems}
                                onClick={() => {
                                    favorites
                                        .clear()
                                        .then(() => {
                                            app.alert('Список избранного очищен', { severity: 'info' });
                                        })
                                        .catch((e: APIError) => {
                                            app.alert(e.response.data.meta.message, { severity: 'error' });
                                        })
                                    ;
                                }}
                            >
                                Очистить всё
                            </Button>
                        </Stack>
                    </Paper>
                ) : (
                    <LoadingPaper />
                )}
            </Grid>
        </Grid>
    </>);
};

export default observer(FavoritesView);