import {App, AppState, AppContext} from "./app";
import {User, State as UserState, Context as UserContext} from "./user";
import {Bonus, State as BonusState, Context as BonusContext} from "./bonus";
import {Catalog, State as CatalogState, Context as CatalogContext} from "./catalog";
import {Cart, State as CartState, Context as CartContext} from "./cart";
import {Favorites, State as FavoritesState, Context as FavoritesContext} from "./favorites";
import {WaitList, State as WaitListState, Context as WaitListContext} from "./waitlist";
import {Order, State as OrderState, Context as OrderContext} from "./order";
import {Saleout, State as SaleoutState, Context as SaleoutContext} from "./saleout";
import {Profile, State as ProfileState, Context as ProfileContext} from "./profile";
import {Auth, State as AuthState, Context as AuthContext} from "./auth";
import {Notifications, State as NotificationsState, Context as NotificationsContext} from "./notifications";
import {Recommended, State as RecommendedState, Context as RecommendedContext} from "./recommended";
import {createContext} from "react";
import EventEmitter from "wolfy87-eventemitter";
import {isArray, isObject, mergeWith} from 'lodash';
import {action, makeObservable, observable} from "mobx";

export type StoreState = {
    readonly app?: AppState;
    readonly auth?: AuthState;
    readonly user?: UserState;
    readonly bonus?: BonusState;
    readonly catalog?: CatalogState;
    readonly cart?: CartState;
    readonly favorites?: FavoritesState;
    readonly waitList?: WaitListState;
    readonly order?: OrderState;
    readonly saleout?: SaleoutState;
    readonly profile?: ProfileState;
    readonly notifications?: NotificationsState;
    readonly recommended?: RecommendedState;
};

export { App, AppContext };
export { User, UserContext };
export { Bonus, BonusContext };
export { Catalog, CatalogContext };
export { Cart, CartContext };
export { Favorites, FavoritesContext };
export { WaitList, WaitListContext };
export { Order, OrderContext };
export { Saleout, SaleoutContext };
export { Profile, ProfileContext };
export { Auth, AuthContext }
export { Notifications, NotificationsContext }
export { Recommended, RecommendedContext }

type MergeParams = {
    array?: 'join'|'index-update';
}

export function merge<T>(object: T, source: any, params: MergeParams = null) {
    if(!params) params = {};

    return mergeWith(object, source, (dest, src, key, object, source) => {
        if(isObject(source) && '_merge_' in source) {
            if(key == '_merge_') return true;
            return merge(dest, src, (source as any)._merge_);
        }

        if(isArray(dest)) {
            if(params.array == 'join')
                return dest.concat(src);

            if(params.array == 'index-update') {
                const dst = dest.concat();
                dst[src[0]] = src[1];
                return dst;
            }

            return src;
        }
        else if(params.array == 'index-update' && isArray(src)) {
            // Первичная инициализация массива страниц. Например, если мы обновляем страницу не на первой странице.
            const dst = [];
            dst[src[0]] = src[1];
            return dst;
        }
    });
}

export class Store extends EventEmitter {

    _version = 1;

    app: App;
    auth: Auth;
    user: User;
    bonus: Bonus;
    catalog: Catalog;
    cart: Cart;
    favorites: Favorites;
    waitList: WaitList;
    saleout: Saleout;
    order: Order;
    profile: Profile;
    notifications: Notifications;
    recommended: Recommended;

    private stores: Record<keyof StoreState, Store[keyof StoreState]>;

    constructor(initialState?: StoreState) {
        super();
        this.createStores();
        this.setState(initialState || {});

        makeObservable(this, {
            _version: observable,
            _incrementVersion: action,
        });
    }

    detectReset() {
        return this._version;
    }

    _incrementVersion() {
        this._version++;
    }

    private createStores() {
        this.stores = {
            app: new App(this),
            auth: new Auth(this),
            user: new User(this),
            bonus: new Bonus(this),
            catalog: new Catalog(this),
            cart: new Cart(this),
            favorites: new Favorites(this),
            waitList: new WaitList(this),
            saleout: new Saleout(this),
            order: new Order(this),
            profile: new Profile(this),
            notifications: new Notifications(this),
            recommended: new Recommended(this)
        };

        for(const prop in this.stores)
            this[prop] = this.stores[prop];
    }

    setState(state: StoreState) {
        for(const key in state)
            this[key].setState(state[key]);
    }

    reset(initialState?: StoreState) {
        for(const prop in this.stores) {
            const store = this.stores[prop];
            if(typeof store.dispose === 'function')
                store.dispose();
        }

        this.createStores();
        this.setState(initialState || {});
        this._incrementVersion();
    }

}

export const Context = createContext<Store>(undefined);