import {
    Box, Button,
    Divider,
    Paper,
    Stack, TextField,
    Typography
} from "@mui/material";
import { formatPrice as $ } from "../../../../utils";
import TotalPrice from "../../../Order/TotalPrice";
import { LoadingButton } from "@mui/lab";
import { APIError, AppContext } from "../../../../store/app";
import LoadingPaper from "../../../LoadingPaper";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { CartContext, OrderContext } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

type Props = {
}

const OrderBar = (p: Props) => {
    const order = useContext(OrderContext);
    const theme = useTheme();
    const navigate = useNavigate();
    const [isCreatingOrder, setIsCreatingOrder] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');

    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    useEffect(() => {
        app.setTitle('Корзина');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Корзина' },
        ])
    });

    const checkedItems = cart.checkedItems || [];

    const summary = cart.checkedSummary;
    const totalItems = cart.summary?.totalItems || 0;

    const canCreateOrder = !!totalItems;

    const totalSum = summary ? (summary.priceWithDiscount || summary.priceWithoutDiscount) : 0;

    const totalDiscount = !summary ? 0 : Math.max(summary.priceWithoutDiscount - summary.priceWithDiscount, 0);

    return (
        <>
            {summary ? (
                <Paper sx={{ p: 2, mb: { xs: 4, md: 0 } }} elevation={1}>
                    <Stack
                        component="form"
                        direction="column" 
                        spacing={2}
                        onSubmit={e => {
                            if(!canCreateOrder) return;
                            e.preventDefault();
                            setIsCreatingOrder(true);
                            order.createOrder(
                                checkedItems.map(i => ({
                                    item: i, count: i.quantity,
                                }) as any),
                                phone, name
                            )
                                .then(res => {
                                    setName('');
                                    setPhone('');
                                    
                                    navigate(`/order/success/${res.data.data.orderId}`);
                                    app.alert('Заказ создан', { severity: 'success' });
                                })
                                .catch((e: APIError) => {
                                    const message = e.response.data?.meta?.message || 'Непредвиденная ошибка';
                                    app.alert(message, { severity: 'error' });
                                })
                                .finally(() => {
                                    setIsCreatingOrder(false);
                                })
                            ;

                        }}
                    >
                        <Typography variant="subtitle1">
                            Сумма заказа
                        </Typography>
                        <Box>
                            <Typography variant="body1" pb="8px">
                                Товаров: {summary.totalItems}
                            </Typography>
                            <Typography variant="body1" pb="8px">
                                На сумму: {$(summary.priceWithoutDiscount)}
                            </Typography>
                            {totalDiscount ? <Typography variant="body1">
                                Скидка: {$(totalDiscount)}
                            </Typography> : undefined}
                        </Box>
                        <Divider variant="fullWidth" />
                        <TotalPrice label="Итог" value={totalSum} />

                        <Divider variant="fullWidth" />
                        <TextField
                            label="Имя"
                            name="comment"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            disabled={!canCreateOrder}
                        />
                        <TextField required
                            label="Телефон"
                            name="comment"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            disabled={!canCreateOrder}
                        />
                        <LoadingButton
                            type="submit"
                            color="primary"
                            size="large"
                            variant="contained"
                            loading={isCreatingOrder}
                            disabled={!canCreateOrder}
                        >
                            Купить в 1 клик
                        </LoadingButton>
                        <Button
                            size="large"
                            variant="text"
                            color="error"
                            disabled={!canCreateOrder}
                            onClick={() => {
                                cart
                                    .clear()
                                    .then(() => {
                                        app.alert('Корзина очищена', { severity: 'info' });
                                    })
                                    .catch((e: APIError) => {
                                        app.alert(e.response.data.meta.message, { severity: 'error' });
                                    })
                                ;
                            }}
                        >
                            Очистить корзину
                        </Button>
                    </Stack>
                </Paper>
            ) : (
                <LoadingPaper />
            )}
        </>
    );
}

export default observer(OrderBar);
