import { FC, SyntheticEvent } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Stepper from "../../Stepper";
import BatchItemsPrice from "../BatchItemsPrice";
import * as React from "react";
import RemoveIcon from "../../../icons/delete.svg";

type Props = {
    readonly count: number;
    readonly stock: number;
    readonly inCart?: boolean;
    readonly price: number;
    readonly oldPrice?: number;
    readonly onCountChange?: (count: number) => void;
    readonly onAddToCartClick: () => void;
    readonly onRemoveFromCartClick: () => void;
};

const c = (e: SyntheticEvent, fn: CallableFunction) => (e.stopPropagation(), fn && fn());
const WideCardStepperDoubleAction: FC<Props> = (p) => {

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                gap: 1,
                flexDirection: "column"
            }}
        >
            <Box sx={{ display: 'flex', gap: 'inherit', alignItems: 'center' }}>
                <BatchItemsPrice
                    count={Math.min(p.count, p.stock)}
                    price={p.price}
                    oldPrice={p.oldPrice}
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                <Stepper
                    value={p.count}
                    onClick={e => e.stopPropagation()}
                    onChange={value => {
                        p.onCountChange && p.onCountChange(value);
                    }}
                />
                <Box>
                    {p.onAddToCartClick && (
                        <Button
                            sx={{
                                whiteSpace: 'nowrap',
                                backgroundColor: p.inCart && p.stock ? '#FF754C' : (p.stock > 0 ? '#3F8CFF' : 'red'),
                                border: "none",
                                '&:hover': {
                                    color: "white",
                                    border: "none",
                                    backgroundColor: "#FF754C"
                                }
                            }}
                            color="info"
                            size="small"
                            variant="outlined"
                            onClick={e => c(e, p.onAddToCartClick)}

                        >
                            {p.inCart && p.stock > 0 ? "В корзине" : (p.stock > 0 ? "В корзину" : "Заказать")}
                        </Button>
                    )}
                    {p.onRemoveFromCartClick && (
                        <IconButton
                            aria-label="remove"
                            onClick={e => c(e, p.onRemoveFromCartClick)}
                        >
                            <RemoveIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default WideCardStepperDoubleAction;