import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {AxiosRequestConfig} from "axios";
import {createContext} from "react";
import {merge, Store} from "./index";
import {request} from "../utils";
import { Cacheable, CacheableWithPagination } from "../common/caching";
import { UserChecker } from "../../ts-shared/predicates";

type Banner = {
    id: number;
    image: string;
    link?: string;
    showAtHomePage: boolean;
    userChecker?: null|UserChecker;
}

export type State = {
    readonly notify?: null|Cacheable & {
        enabled: boolean;
        email: string|null;
    };
    readonly banners?: CacheableWithPagination & {
        list: ReadonlyArray<Banner>;
    };
}


export class Notifications {
    _store: Store;
    _state: State = {};

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            notify: computed,
            banners: computed,
            setState: action,
        });
    }

    setState(state: State | undefined) {
        this._state = merge(this._state, state || {});
    }

    sendValidationEmail(email: string) {
        return request({
            method: 'POST',
            url: `/notifications/email/${encodeURIComponent(email)}`,
        });
    }

    async setNotificationsEnabled(enabled: boolean, opts?: AxiosRequestConfig) {
        const fd = new FormData();
        fd.append('enabled', enabled ? 'true' : 'false');

        await request({
            method: 'POST',
            url: 'notifications',
            data: fd,
            ...(opts || {})
        });

        runInAction(() => this._state.notify.enabled = enabled);
    }

    async checkEmail(email: string, opts?: AxiosRequestConfig) {

        try {
            await request({
                method: 'GET',
                url: `notifications/email/${encodeURIComponent(email)}`,
                ...(opts || {})
            });
            return true;
        }
        catch(e) {
            if(!e.response || e.response?.status >= 500)
                throw e;

            return false;
        }
    }

    get notify() {
        const isLoggedIn = this._store.auth.isLoggedIn;

        if(this._state.notify === undefined) {
            if(!isLoggedIn) {
                action(() => (this._state as any).notify = null)();
                return this._state.notify;
            }
            
            request({
                method: 'GET',
                url: '/profile/notifications',
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state.notify;
    }

    get banners() {
        if(this._state.banners === undefined) {
            request({
                method: 'GET',
                url: '/',
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state.banners;
    }

}

export const Context = createContext<Notifications>(undefined);