import {Grid, Skeleton} from "@mui/material";
import * as React from "react";

const PageContentSkeleton = () => (
    <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
            <Skeleton
                variant="rounded"
                width="100%"
                height={600}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <Skeleton
                variant="rounded"
                width="100%"
                height={300}
            />
        </Grid>
    </Grid>
);

export default PageContentSkeleton;