import {FC} from "react";
import Price from "../../Item/Price";
import {Typography} from "@mui/material";
import * as React from "react";

type Props = {
    readonly value: number;
    readonly label: string;
}

const TotalPrice: FC<Props> = ({ value, label }) => {

    return (
        <Price highlight
            value={value}
            before={
                <Typography variant="subtitle1" fontWeight="bold">
                    {label}
                </Typography>
            }
        />
    );
}

export default TotalPrice;