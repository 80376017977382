import {Item} from "../../index";
import {AlgorithmPassResult} from "../index";

type ItemsRecord<T={}> = Record<number, T & {
    requiredCount: number;
    giveCount: number;
    givePrice: number;
}>;

export type Params = {
    readonly items: ItemsRecord;
};

export const getIncludedItems = (params: Readonly<Params>): { id: string }[] => {
    return Object.keys(params.items).map(id => ({ id }));
}

export const getSampleSet = (items: ReadonlyArray<Readonly<{ id: string, stock: number }>>, params: Readonly<Params>): { id: string, count: number }[] => {
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;

        const reqCount = params.items[item.id].requiredCount + params.items[item.id].giveCount;
        if(item.stock < reqCount) continue;

        result.push({ id: item.id, count: reqCount });
        return result;
    }

    return result;
}

export const getItemsSet = (items: ReadonlyArray<Readonly<{ id: string, count: number, stock: number }>>, setsCount: number, params: Readonly<Params>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} => {

    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;

        const count = params.items[item.id].requiredCount + params.items[item.id].giveCount;
        if(item.stock < count * setsCount) continue;

        result.push({ id: item.id, count: count * setsCount });
        return { maxSetsCount: Math.floor(item.stock / count), items: result };
    }

    return { maxSetsCount: 0, items: [] };
}

export const getTargetPrices = (items: ReadonlyArray<{ id: string, price: number }>, params: Readonly<Params>): {
    id: string,
    price: number,
}[] => {
    return items.map(v => ({id: v.id, price: params.items[v.id]?.givePrice || v.price }));
}


const ItemsPlusOne = (items: ReadonlyArray<Readonly<Item>>, params: Readonly<Params>): AlgorithmPassResult => {
    if(!items.length) return { affected: [], untouched: [], groupsCount: 0 };

    const untouched: Item[] = items.map(v => ({...v}));
    for(let idx = 0; idx < items.length; idx++) {
        const item = items[idx];
        if(!params.items[item.id]) continue;

        const itemCount = Math.min(item.count, item.stock);

        const baseCount = params.items[item.id].requiredCount + params.items[item.id].giveCount;
        if(itemCount < baseCount) continue;

        const setsCount = Math.floor(itemCount / baseCount);
        if(!setsCount) continue;

        if(item.count - baseCount * setsCount > 0) {
            untouched[idx].count -= baseCount * setsCount;
            untouched[idx].stock -= baseCount * setsCount;
        }
        else
            untouched.splice(idx, 1);

        return {
            untouched,
            groupsCount: setsCount,
            affected: [
                { ...item, count: params.items[item.id].giveCount * setsCount, price: params.items[item.id].givePrice },
                { ...item, count: params.items[item.id].requiredCount * setsCount },
            ],
        };
    }

    return { affected: [], untouched, groupsCount: 0 };
};

export default ItemsPlusOne;