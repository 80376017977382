import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, Link, Stack, Typography, useTheme } from "@mui/material";
import { Navigate, Link as RouterLink, createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { CatalogContext, AppContext } from "../../../../store";
import TopCategoriesSelect from "../../../../components/views/CatalogView/TopCategoriesSelect";
import SortBlock from "../../../SortBlock";
import HTMLRenderer from "../../../WYSIWYG/HTMLRenderer";
import CatalogCardView from "../../CatalogCardView";
import HeadingView from "../../HeadingView";
import ItemsList from "../ItemsList";
import Breadcrumbs from "../../../Breadcrumbs";
import { toJS } from "mobx";

type Props = {

};

const getUrlParams = (fullPath: string) => {
    const [, , , , , path] = fullPath.match(new RegExp(`((\\/(_none_)(\\/|$))?\\/?((\\/\\w|\\w|\\d|-)+)?)?\\/?`, 'mi'));

    const basePath = '/catalog' + (path ? '/' + path : '');

    return [path, basePath];
}

const SearchView: FC<Props> = () => {
    const catalog = useContext(CatalogContext);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();


    const group = searchParams.get('g');
    const search = searchParams.get('q');
    const sort = searchParams.get('s');
    const page = +searchParams.get('page') || 1;

    const [path] = useMemo(() => getUrlParams(location.pathname.substring('/catalog'.length)), [location.pathname]);

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Поиск');
    });

    const treeLevel = catalog.getTreeLevel(path, undefined, search, group, sort, page);

    return (<>
        <HeadingView />
        <Grid container spacing={2}>
            <Grid item
                xs={12}
                sm={path ? 4 : 3}
                lg={path ? 3 : 12}
            >

            </Grid>
            <Grid item
                xs={12}
                sm={path ? 8 : 12}
                lg={path ? 9 : 12}
            >
                <Stack spacing={1}>
                    <Breadcrumbs items={[{ name: 'Главная', to: '/' }, { name: 'Поиск' }]} />
                    <ItemsList
                        key={path}
                        group={group}
                        itemsMode="grid"
                        path={path}
                        search={search}
                        sort={sort}
                        page={page}
                        treeLevel={treeLevel?.type == 'items' ? treeLevel : undefined}
                        onPageChange={page => {
                            const current = Object.fromEntries(searchParams.entries());
                            if (page == 1)
                                delete current.page;
                            else
                                current.page = '' + page;

                            setSearchParams(current);
                        }}
                    />
                </Stack>
                {treeLevel?.type == 'items' && treeLevel.category.description ?
                    <HTMLRenderer sx={{ mt: 2 }} children={treeLevel.category.description} />
                    : undefined}
            </Grid>
        </Grid>
    </>);
};

export default observer(SearchView);