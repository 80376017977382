import {createContext} from "react";
import {Store} from "./index";
import { Item as CatalogItem } from "../common/item";
import {Bucket} from "./base/bucket";

type Item = CatalogItem & {

}

export type State = {
    items?: {
        list: Item[];
    }
    summary?: {
        totalItems: number;
        priceWithoutDiscount: number;
        priceWithDiscount?: number;
    },
};

export class Favorites extends Bucket<State, 'favorites'> {

    constructor(store: Store) {
        super(store, {
            endpointUrl: '/favorites',
            tableName: 'favorites',
        });
    }

}

export const Context = createContext<Favorites>(undefined);