import {Paper} from "@mui/material";
import * as React from "react";


const ListEmptyItem = () => {

    return (
        <Paper sx={{ p: 6, textAlign: 'center' }}>
            <strong>Ничего не найдено</strong>
        </Paper>
    );
}

export default ListEmptyItem;