import { FC, useContext, useState } from "react";
import Card from "../Card";
import { Item } from "../../../common/item";
import { BonusContext, CatalogContext } from "../../../store";
import { observer } from "mobx-react-lite";
import { Box, Checkbox } from "@mui/material";

type Props = {
    readonly item: Item;
    readonly onRemoveFromCartClick?: () => void;
    readonly onFavoritesClick: () => void;
    readonly onCountChange?: (value: number) => void;
    readonly count: number;
    readonly disallowOverstock?: boolean;
    /** Указывается, если этот товар уже добавлен в корзину в рамках какой-либо группы */
    readonly baseCount?: number;
    readonly favorite: boolean;
    readonly checked?: boolean;
    readonly onCheckChange?: (checked: boolean) => void;
    readonly onClick?: () => void;
}

const CartCard: FC<Props> = (p) => {
    const baseCount = p.baseCount || 0;
    const realStock = Math.max(0, p.item.count - baseCount) || undefined;
    const absentCount = Math.max(p.count - realStock, 0) || undefined;

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: p.count,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });

    
    const catalog = useContext(CatalogContext);
    const variants = catalog.getItemVariants(p.item.id);

    const [currentVariantId, setCurrentVariantId] = useState(p.item.variants ? 0 : undefined);

    return (<Box sx={{ display: 'flex' }}>
        <Card
            id={p.item.id}
            imgSrc={p.item.imgList}
            label={p.item.label}
            onFavoritesClick={p.onFavoritesClick}
            favorite={p.favorite}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            stock={p.item.count}
            count={p.count}
            onClick={p.onClick}
            aliasFull={p.item.aliasFull}
            currentVariantId={currentVariantId}
            onCurrentVariantIdChange={setCurrentVariantId}
            price={p.item.price}
            onCountChange={p.onCountChange}
            onRemoveFromCartClick={p.onRemoveFromCartClick}
        />
        {p.checked !== undefined && (
            <Checkbox
                sx={{ alignSelf: 'center' }}
                onClick={e => e.stopPropagation()}
                checked={p.checked}
                onChange={p.onCheckChange && (e => {
                    p.onCheckChange(e.target.checked);
                })}
            />
        )}
    </Box>);

}

export default observer(CartCard);