import {ReactNode, useState} from "react";
import {
    Box, BoxProps,
    Popover,
} from "@mui/material";
import { BoxTypeMap } from "@mui/system";
import * as React from "react";
import {OverridableComponent} from "@mui/material/OverridableComponent";

type OwnProps = {
    readonly content?: ReactNode;
};

type Props = BoxProps<'div', OwnProps>;

const ContextPopover: OverridableComponent<BoxTypeMap<OwnProps>> = ({ content, onClick, ...boxProps }: Props) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(e.currentTarget);

        if(onClick) onClick(e);
    };

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setAnchorEl(null);
    };


    return (<>
        <Box onClick={handleClick} {...boxProps}/>
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={e => e.stopPropagation()}
            sx={{ mt: 1 }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            children={content}
        />
    </>);
}

export default ContextPopover;