import { Box, Popover, Typography } from "@mui/material"
import BonusIcon from "../../../icons/PointsWhite.svg"
import { useContext, useState } from "react";
import { BonusContext } from "../../../store";
import { observer } from "mobx-react-lite";

type Props = {
    readonly mode: 'catalogItem' | 'order' | 'transaction';
    readonly value: number;
    readonly faded?: boolean;
    readonly customMessage?: string;
}

const BonusBadge = (p: Props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const bonus = useContext(BonusContext);
    if (!bonus.status?.enabled) return <></>;

    const value = Math.floor(p.value);

    const backgroundColor = (() => {
        const style = value;
        return style < 0 ? "#FF754C" : "#3F8CFF"
    })();

    return (<>
        {p.value >= 0.01 && (
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                    '& .MuiPopover-paper': {
                        width: 170
                    }
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                elevation={1}
            >
                <Typography sx={{ p: 1 }}>
                    {p.customMessage || (<>
                        {p.mode == 'catalogItem' ? `За покупку данного товара вам начислится ${value} баллов!` : ''}
                        {(p.mode === 'order' || p.mode === 'transaction') && (<>
                            {p.faded
                                ? `После оплаты заказа вам начислится ${value} баллов!`
                                : `За этот заказ вам начислено ${value} баллов!`
                            }
                        </>)}
                    </>)}
                </Typography>
            </Popover>
        )}
        <Box
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: { backgroundColor },
                // если списание то бэк "#FF754C"
                borderRadius: "12px",
                px: "6px",
                mr: "5px",
                alignItems: "center",
                gap: "3px",
                color: "white",
                height: 23,
                opacity: p.faded ? '0.3' : undefined,
            }}
        >
            <Box component={BonusIcon} />
            <Typography fontSize="12px" fontWeight="bold">
                {p.mode === 'transaction' && p.value >= 0.01 && '+ '}
                {p.value <= -0.01 && '- '}
                {Math.abs(value)}
            </Typography>
        </Box>
    </>)
}
export default observer(BonusBadge);