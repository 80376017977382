import {FC} from "react";
import {Stack, Typography} from "@mui/material";

type Props = {
    readonly value: string;
    readonly centered?: boolean;
    readonly expanded?: boolean;
};

const smallSx = {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
};

const Title: FC<Props> = (p) => {

    return (
        <Stack
            sx={{ height: 45 }}
            justifyContent="center"
        >
            <Typography
                variant="body1"
                sx={p.expanded ? {} : smallSx}
            >
                {p.value}
            </Typography>
        </Stack>
    );
}

export default Title;