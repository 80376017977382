import {Checkbox, ListItem, ListItemButton, ListItemIcon} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {BaseProp} from "../";
import {FC} from "react";

type CheckboxValue = {
    id: string;
    value: string;
    num: number;
    checked: boolean;
};

interface ItemClickEvent extends React.MouseEvent {
    itemId: string;
}

export type CheckboxProp = BaseProp & {
    type: 'checkbox';
    values?: CheckboxValue[];
};

type Props = {
    readonly prop: CheckboxProp;
    readonly onItemClick?: (e: ItemClickEvent) => void;
    readonly onScroll?: () => void;
    readonly onInputComplete?: () => void;
};

const CheckBox: FC<Props> = (props) => {
    const {values} = props.prop;
    const postfix = props.prop.postfix || '';

    return (
        <List 
            dense 
            component="div" 
            disablePadding
            sx={{
                maxHeight: '300px',
                overflow: 'auto',
            }}
            onScroll={props.onScroll}
        >
            {values.map(v => (
                <ListItem dense key={v.id} disablePadding>
                    <ListItemButton
                        dense
                        disableRipple
                        onClick={_e => {
                            if (!props.onItemClick) return;

                            const e: ItemClickEvent = _e as any;
                            e.itemId = v.id;
                            props.onItemClick(e);
                            props.onInputComplete && props.onInputComplete();
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={!!v.checked}
                                sx={{
                                    '& .MuiSvgIcon-root': {fontSize: 20},
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={`${v.value}${postfix}`}/>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default CheckBox;