import { Box, BoxProps, Button, Container, Grid, IconButton, Link, List, ListItem, Stack, Typography, useTheme } from "@mui/material";
import { FC, useContext } from "react";
import { AppContext, CatalogContext } from "../../../store";
import { Link as RouterLink } from "react-router-dom";
import CallMeDialog from "../../CallMeDialog";
import CallIcon from '@mui/icons-material/LocalPhoneRounded';
import vkIcon from '../../../icons/vk.webp';
import whatsAppIcon from '../../../icons/whatsapp.webp';

type Props = BoxProps & {

}

const FooterView: FC<Props> = ({ sx }) => {
    const theme = useTheme();
    const app = useContext(AppContext);
    const catalog = useContext(CatalogContext);
    const topCategories = catalog.getTreeLevel()?.list;

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.grey[300],
                pt: 2, pb: 8,
                ...sx,
            }}
        >
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item lg={9}>
                        <Typography variant="h6">Каталог</Typography>
                        <List sx={{ pl: 0 }}>
                            {Object.values(topCategories || {}).map(v => (
                                <ListItem key={v.id} sx={{ pl: 0 }}>
                                    <Link component={RouterLink} to={`/catalog/${v.aliasfull}`} children={v.label} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item lg={3}>
                        <Stack alignItems="flex-start" spacing={2}>
                            <Typography variant="h6">
                                Контакты
                            </Typography>
                            <Button
                                startIcon={<CallIcon />}
                                children="Заказать звонок"
                                variant="outlined"
                                onClick={() => app.showDialog(<CallMeDialog />, { fullWidth: false })}
                            />
                            <Link href="tel:+79688626690" children="8 (968) 862-66-90" variant="body1" underline="none" />
                            <Link href="mailto:sales@rus-mebel.com" children="sales@rus-mebel.com" variant="body1" underline="none" />
                            <Box>
                                <Typography variant="body1">Давайте дружить</Typography>
                                <Stack direction="row" sx={{ transform: 'translateX(-8px)' }}>
                                    <IconButton href="https://vk.com/public203834656" target="_blank">
                                        <img style={{ width: 24, height: 24 }} src={vkIcon} alt="vk" />
                                    </IconButton>
                                    <IconButton href="https://wa.me/79688626690" target="_blank">
                                        <img style={{ width: 24, height: 24 }} src={whatsAppIcon} alt="whatsapp" />
                                    </IconButton>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default FooterView;