import {Item} from "../../index";
import {AlgorithmPassResult} from "../index";
import {gcd as _gcd} from "mathjs";

/**
 * TODO: странная нестыковка с типами
 * https://mathjs.org/docs/reference/functions/gcd.html
 */
const gcd = _gcd as any as (a:number, b:number) => number;

export type Params = {
    readonly items: Record<number, {
        readonly discountPercent: number;
    }>;
};

export const getIncludedItems = (params: Readonly<Params>): { id: string }[] => {
    return Object.keys(params.items).map(id => ({ id }));
}

export const getSampleSet = (items: ReadonlyArray<Readonly<{ id: string, stock: number }>>, params: Readonly<Params>): { id: string, count: number }[] => {
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;
        if(item.stock < 1) continue;

        result.push({ id: item.id, count: 1 });
        break;
    }

    return result;
}

export const getItemsSet = (items: ReadonlyArray<Readonly<{ id: string, count: number, stock: number }>>, setsCount: number, params: Readonly<Params>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} => {
    let divisor: number|null = null;

    for(const item of items) {
        if(!params.items[item.id] || item.stock <= 0 || item.count <= 0) continue;

        divisor = divisor ? gcd(divisor, item.count) : item.count;
    }

    let maxSetsCount: number|null = null;
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;

        const itemCount = Math.min(item.count, item.stock);
        const baseCount = item.count / divisor;
        if(item.stock < baseCount * setsCount) continue;

        if(maxSetsCount === null)
            maxSetsCount = Math.floor(item.stock / baseCount);
        else
            maxSetsCount = Math.min(maxSetsCount, Math.floor(item.stock / baseCount))

        result.push({ id: item.id, count: baseCount * setsCount });
    }

    return { maxSetsCount, items: result };
}

const SingleDiscountGroup = (items: ReadonlyArray<Readonly<Item>>, params: Readonly<Params>): AlgorithmPassResult => {
    if(!items.length) return { affected: [], untouched: [], groupsCount: 0 };

    const untouched: Item[] = items.map(v => ({...v}));
    const countedIndices: number[] = [];

    let setsCount: number|null = null;

    for(let idx = 0; idx < items.length; idx++) {
        const item = items[idx];
        if(!params.items[item.id] || item.stock <= 0 || item.count <= 0) continue;

        setsCount = setsCount ? gcd(setsCount, item.count) : item.count;

        countedIndices.push(idx);
    }

    const affected: Item[] = [];
    let deletedCount = 0;
    for(let idx of countedIndices) {
        idx = idx - deletedCount;

        const item = untouched[idx];
        let affectedCount: number;
        if(item.count > item.stock) {
            affectedCount = item.stock;
            item.count -= affectedCount;
            item.stock -= affectedCount;
        }
        else {
            affectedCount = item.count;
            untouched.splice(idx, 1);
            deletedCount++;
        }

        const discount = params.items[item.id].discountPercent / 100;
        affected.push({
            ...item,
            price: item.price * (1 - discount),
            count: affectedCount,
        });
    }

    return { affected, untouched, groupsCount: setsCount };
};

export const getTargetPrices = (items: ReadonlyArray<{ id: string, price: number }>, params: Readonly<Params>): {
    id: string,
    price: number,
}[] => {

    return items.map(v => {

        let k = 1;
        if(params.items[v.id])
            k = (100 - params.items[v.id].discountPercent) / 100;

        return {
            id: v.id,
            price: v.price * k,
        };
    });
}

export default SingleDiscountGroup;