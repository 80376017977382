import ItemImageBase, { Props as BaseProps } from "../ItemImageBase";

type Props = BaseProps;

const ItemImageThumb = (p: Props) => {

    return (
        <ItemImageBase
            alt={p.alt || "Предпросмотр"}
            src={p.src}
            width={p.width || '100%'}
            height={p.height || 100}
            maxWidth={p.maxWidth}
            maxHeight={p.maxHeight}
            sx={{
                ...(p.sx || {}),
            }}
        />
    );
}

export default ItemImageThumb;