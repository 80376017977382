import * as React from "react";
import {FC, useContext, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {AppContext} from "../../../store";
import ErrorPageTemplate from "../../ErrorPageTemplate";

type Props = {

};

const ErrorView: FC<Props> = () => {
    const app = useContext(AppContext);

    useEffect(() => {
        app.setTitle('Ошибка');
    });

    return <ErrorPageTemplate code={app.error!.code} message={app.error!.message} />;
};

export default observer(ErrorView);