import PromoPageGroup from "../../../Item/PromoPageGroup";
import List from "../../../List";
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {AppContext, CartContext, FavoritesContext, UserContext} from "../../../../store";
import PromoPageWideCard from "../../../Item/PromoPageWideCard";
import { Item } from "../../../../common/item";
import {Group} from "../../../../store/cart";

type Props = {
    readonly groups: ReadonlyArray<Readonly<Group>>;
    readonly items: ReadonlyArray<Readonly<Item>>;

    readonly onGroupRemoveClick?: (g: Readonly<Group>) => void;
    readonly onSetsChange: (g: Readonly<Group>, count: number) => void;
    readonly onItemClick?: (it: Readonly<Item>) => void;
}

const MyWideCard = observer((p: { item }) => {
    const app = useContext(AppContext);
    const user = useContext(UserContext);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);

    const favorite = favorites.includes(p.item.id);
    const inCart = cart.includes(p.item.id);

    return (
        <PromoPageWideCard
            item={p.item}
            inCart={inCart}
            favorite={favorite}
            count={p.item.quantity}
            onFavoritesClick={async () => {
                const cartItem = {...p.item};
                if(favorite) {
                    await favorites.remove(cartItem);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem);
                    app.alert(`Добавлено в избранное`);
                }

            }}
            onAddToCartClick={async () => {
                const cartItem = {...p.item};

                await cart.addWithAmount(cartItem, p.item.quantity);
                app.alert(`Добавлено в корзину`);
            }}
        />
    );
});

const GroupsView = (p: Props) => {

    return (<>
        {p.groups.map((group, index) => (
            <PromoPageGroup
                key={`${group.promoId}_${index}`}
                group={group}
                onRemoveClick={p.onGroupRemoveClick && (() => p.onGroupRemoveClick(group))}
                onSetsChange={c => p.onSetsChange(group, c)}
                onItemClick={p.onItemClick}
            />
        ))}
        <List noLoader noEmptyMessage
          items={{ list: p.items }}
          component={MyWideCard}
        />
    </>);
}

export default observer(GroupsView);