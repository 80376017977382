import {createContext} from "react";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {merge, Store} from "./index";
import {request} from "../utils";
import * as Sentry from "@sentry/browser";
import { UserType } from "../../ts-shared/user";

export type DebtInfo = {
    readonly debt: number;
    readonly overdebt: number|null;
    readonly paydate: string|null;
    readonly paysum: number|null;
    readonly updatedAt: string;
};

export type CallMeData = {
    readonly phone: string;
    readonly name?: string;
    readonly from?: number;
    readonly to?: number;
};


export type UserData = {
    id: number;
    fullName: string;
    login: string;
    type: UserType;
    inn: string;
    matrixCode: number;
    parentPriceCode: string|null;
    childrenCount: number;
};

export type State = {
    readonly user?: null|Readonly<UserData>;
    readonly debtInfo?: null|DebtInfo;
    readonly manager?: null|{
        readonly id: number;
        readonly name: string;
        readonly description: string;
        readonly phone: string;
        readonly email: string;
        readonly imgFull: null|string;
    }
};

export class User {
    _store: Store = undefined;
    _state: State = {};

    _callMeProcess: Promise<any>;

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            id: computed,
            login: computed,
            type: computed,
            fullName: computed,
            inn: computed,
            debtInfo: computed,
            setState: action,
            manager: computed,
            invalidateUser: action,
        })
    }

    setState(state: State | undefined) {
        try {
            if(state?.user !== undefined) {
                if(this._state.user !== state.user) {
                    // Sentry.setUser(state.user ? {
                    //     id: state.user.login,
                    //     username: state.user.fullName,
                    //     segment: state.user.type,
                    // } : null);
                }
            }
        }
        catch (e) {
            // Отправка в сентри не должна ломать сайт
            console.error(e)
        }


        this._state = merge(this._state, state || {});
    }

    invalidateUser() {
        (this._state.user as any) = undefined;
    }

    sendReconciliation(file: File) {
        const fd = new FormData();
        fd.append('file', file);

        return request({
            method: 'POST',
            url: '/reconciliation',
            data: fd,
        });
    }

    requestCall(data: CallMeData) {
        if(this._callMeProcess) return this._callMeProcess;

        const fd = new FormData();
        fd.append('phone', data.phone);
        if(data.name)
            fd.append('name', data.name);
        if(data.from)
            fd.append('timestart', `${data.from}`);
        if(data.to)
            fd.append('timeend', `${data.to}`);

        return request({
            method: 'POST',
            url: '/callme',
            data: fd,
        });
    }

    get user() {
        if(this._state.user === undefined) {
            request({
                method: 'GET',
                url: `/user`,
            })
            .then(res => {
                this._store.setState(res.data.data.state);
            });
        }

        return this._state.user;
    }

    /**
     * @deprecated Используйте this.user.id
     */
    get id() {
        const user = this.user;
        if(user === undefined)
            return user as undefined;

        return user?.id;
    }

    /**
     * @deprecated Используйте this.user.login
     */
    get login() {
        const user = this.user;
        if(user === undefined)
            return user as undefined;

        return user?.login;
    }

    /**
     * @deprecated Используйте this.user.type
     */
    get type() {
        const user = this.user;
        if(user === undefined)
            return user as undefined;

        return user?.type;
    }

    /**
     * @deprecated Используйте this.user.matrixCode
     */
    get matrixCode() {
        const user = this.user;
        if(user === undefined)
            return user as undefined;

        return user?.matrixCode;
    }

    /**
     * @deprecated Используйте this.user.fullName
     */
    get fullName() {
        const user = this.user;
        if(user === undefined)
            return user as undefined;

        return user?.fullName;
    }

    /**
     * @deprecated Используйте this.user.inn
     */
    get inn() {
        const user = this.user;
        if(user === undefined)
            return user as undefined;

        return user?.inn;
    }

    get debtInfo() {
        const user = this.user;
        if(user === undefined)
            return this.user as undefined;

        if(this.user === null) {
            runInAction(() => {
                (this._state as any).debtInfo = null;
            });
        }

        if(this._state.debtInfo === undefined) {
            request({
                method: 'GET',
                url: `/user/debtInfo`,
            })
            .then(res => {
                this._store.setState(res.data.data.state);
            });
        }

        return this._state.debtInfo;
    }

    get manager() {
        if(this._state.manager === undefined) {
            request({
                method: 'GET',
                url: `/profile`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: '',
            })
                .then(res => {
                    this._store.setState(res.data.state);
                });
        }

        return this._state.manager;
    }
}

export const Context = createContext<User>(undefined);