import {createContext} from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {merge, Store} from "./index";
import { request } from "../utils";
import { getBonusToGiveForItem, Item } from "../../ts-shared/bonus";
import { BonusTransaction } from "../components/views/ProfileView/BonusView/Imports";
import { CacheableWithPagination } from "../common/caching";
import dayjs from "dayjs";

type Status = {
    readonly enabled: boolean;
    readonly balance: number;
    readonly inactive: number;
    readonly totalBalance: number;
}

export type State = {
    readonly status?: Status|null;
    readonly transactions?: CacheableWithPagination & {
        readonly list: BonusTransaction[],
    }
};

export class Bonus {
    _store: Store = undefined;
    _state: State = {};

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            status: computed,
            transactions: computed,
            invalidate: action,
        })
    }

    setState(state: State | undefined) {
        if(state?.transactions?.list) {
            const trxs = state.transactions.list;
            for(const trx of trxs) {
                (trx as any).date = dayjs(trx.date);
            }
        }

        this._state = merge(this._state, state || {});
    }

    invalidate() {
        (this._state as any).status = undefined;
        (this._state as any).transactions = undefined;
    }

    get status() {
        const isLoggedIn = this._store.auth.isLoggedIn;

        if(this._state.status === undefined) {
            if(!isLoggedIn) {
                action(() => (this._state as any).status = null)();
                return this._state.status;
            }

            request({
                method: 'GET',
                url: `/bonus`,
            })
            .then(res => {
                this._store.setState(res.data.data.state);
            });
        }

        return this._state.status;
    }

    get transactions() {

        if(this._state?.transactions === undefined) {
            request({
                method: 'GET',
                url: `/profile/bonus`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            }).then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state?.transactions;
    }

    transactionsLoadNextPage() {

        request({
            method: 'GET',
            url: `/profile/bonus`,
            headers: {'App-Data-Only': 'yes'},
            baseURL: '',
            params: {
                page: this._state.transactions.nextPage,
            }
        }).then(res => {
            this._store.setState(res.data.state);
        });
    }

    countBonusForItem(item: Item): number|undefined {
        return getBonusToGiveForItem(item);
    }
}

export const Context = createContext<Bonus>(undefined);