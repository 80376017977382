import { Navigate } from "react-router-dom";
import CartView from "./components/views/CartView";
import OrderSuccessView from "./components/views/OrderSuccessView";
import App from "./components/App";
import { routeMode as $ } from "./utils";
import CatalogCardView from "./components/views/CatalogCardView";
import CatalogView from "./components/views/CatalogView";
import FavoritesView from "./components/views/FavoritesView";
import LoginView from "./components/views/LoginView";
import NotFoundView from "./components/views/NotFoundView";
import OrderItemView from "./components/views/OrderItemView";
import OrdersView from "./components/views/OrdersView";
import ProfileView from "./components/views/ProfileView";
import PromoPageView from "./components/views/PromoPageView";
import RecommendedView from "./components/views/RecommendedView";
import SaleoutView from "./components/views/SaleoutView";
import WaitListView from "./components/views/WaitListView";
import WebView from "./components/views/layout/WebView";
import { observer } from "mobx-react-lite";
import { FC, PropsWithChildren, useContext } from "react";
import { UserContext, BonusContext, CatalogContext, CartContext, FavoritesContext, WaitListContext, SaleoutContext, OrderContext, NotificationsContext, ProfileContext, AppContext, RecommendedContext,
    Context as StoreContext,
    AuthContext, } from "./store";

export const Providers: FC<PropsWithChildren> = observer((p) => {
    const store = useContext(StoreContext);
    store.detectReset();

    return (
        <UserContext.Provider value={store.user}>
            <BonusContext.Provider value={store.bonus}>
                <CatalogContext.Provider value={store.catalog}>
                    <CartContext.Provider value={store.cart}>
                        <FavoritesContext.Provider value={store.favorites}>
                            <WaitListContext.Provider value={store.waitList}>
                                <SaleoutContext.Provider value={store.saleout}>
                                    <OrderContext.Provider value={store.order}>
                                        <NotificationsContext.Provider value={store.notifications}>
                                            <ProfileContext.Provider value={store.profile}>
                                                <AppContext.Provider value={store.app}>
                                                    <AuthContext.Provider value={store.auth}>
                                                        <RecommendedContext.Provider value={store.recommended}>
                                                            {p.children}
                                                        </RecommendedContext.Provider>
                                                    </AuthContext.Provider>
                                                </AppContext.Provider>
                                            </ProfileContext.Provider>
                                        </NotificationsContext.Provider>
                                    </OrderContext.Provider>
                                </SaleoutContext.Provider>
                            </WaitListContext.Provider>
                        </FavoritesContext.Provider>
                    </CartContext.Provider>
                </CatalogContext.Provider>
            </BonusContext.Provider>
        </UserContext.Provider>
    );
});

const routes = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <WebView />,
                children: [
                    { path: 'catalog/item/:code', element: <CatalogCardView /> },
                    { path: 'catalog/*', element: <CatalogView /> },
                    { path: 'cart', element: <CartView /> },
                    { path: 'favorites', element: <FavoritesView /> },
                    { path: 'waitlist', element: <WaitListView /> },
                    { path: 'order/success/:orderId', element: <OrderSuccessView /> },
                    { path: 'order/:orderId', element: <OrderItemView /> },
                    { path: 'order', element: <OrdersView /> },
                    { path: 'saleout', element: <SaleoutView /> },
                    { path: 'recommended', element: <RecommendedView /> },
                    { path: 'promo/:promoId', element: <PromoPageView /> },
                    { path: 'profile/:tabItem?', element: <ProfileView /> },
                    { path: '/', element: <CatalogView /> },
                ]
            },
            { path: 'login', element: $(<LoginView />, 'public-only') },
            { path: '*', element: <NotFoundView /> }
        ]
    }
];

export default routes;