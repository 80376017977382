import ItemImageBase, { Props as BaseProps } from "../ItemImageBase";

type Props = BaseProps;

const ItemImageFull = (p: Props) => {

    return (
        <ItemImageBase
            alt={p.alt || "Детальное изображение"}
            src={p.src}
            width={p.width || '100%'}
            height={p.height || {
                xs: '70vw',
                md: 500,
            }}
            sx={{
                ...(p.sx || {}),
            }}
        />
    );
}

export default ItemImageFull;