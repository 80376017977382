// noinspection JSUnusedGlobalSymbols

import {
    pass as _promoPass,
    checkItems as _promoCheckItems,
    getIncludedItems as promoGetIncludedItems,
    getSummary as promoGetSummary,
    Rule as PromoRule,
} from "../ts-shared/promo";
import {
    renderRequest as ssrRenderRequest,
} from "./ssr";

import dayjs from "dayjs";
import { getPermissions as adminGetPermissions } from "../ts-shared/admin";
import { calculateOrderBonusInfo as bonusCalculateOrderBonusInfo } from "../ts-shared/bonus";

const promoPass: typeof _promoPass = (items, user, context) => {

    const newPromoList: Record<number, PromoRule> = {};
    for(const id in context.promoList) {
        const promo = context.promoList[id];
        newPromoList[id] = {
            ...promo,
            validSince: dayjs(promo.validSince as any as string),
            validUntil: dayjs(promo.validUntil as any as string),
        };
    }

    return _promoPass(items, user, {
        ...context,
        promoList: newPromoList,
    })
}

const promoCheckItems: typeof _promoCheckItems = (itemIds, user, context) => {

    const newPromoList: Record<number, PromoRule> = {};
    for(const id in context.promoList) {
        const promo = context.promoList[id];
        newPromoList[id] = {
            ...promo,
            validSince: dayjs(promo.validSince as any as string),
            validUntil: dayjs(promo.validUntil as any as string),
        };
    }

    return _promoCheckItems(itemIds, user, {
        ...context,
        promoList: newPromoList,
    })
}

export { 
    promoPass, 
    promoGetSummary, 
    promoGetIncludedItems, 
    adminGetPermissions, 
    bonusCalculateOrderBonusInfo, 
    promoCheckItems,
    ssrRenderRequest,
}