import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, Link, Stack, Typography, useTheme } from "@mui/material";
import { Navigate, Link as RouterLink, createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import FiltersBlock from "../../../components/FiltersBlock";
import ItemsList from "../../../components/views/CatalogView/ItemsList";
import { CatalogContext, AppContext } from "../../../store";
import HeadingView from "../../../components/views/HeadingView";
import SortBlock from "../../../components/SortBlock";
import TopCategoriesSelect from "./TopCategoriesSelect";
import CatalogCardView from "../../../components/views/CatalogCardView";
import Breadcrumbs from "../../../components/Breadcrumbs";
import HTMLRenderer from "../../../components/WYSIWYG/HTMLRenderer";
import { TreeLeaf } from "../../../common/tree";
import { toJS } from "mobx";
import SearchView from "../../../components/views/CatalogView/SearchView";

type Props = {

};

const getUrlParams = (fullPath: string) => {
    const [, , , , , path] = fullPath.match(new RegExp(`((\\/(_none_)(\\/|$))?\\/?((\\/\\w|\\w|\\d|-)+)?)?\\/?`, 'mi'));

    const basePath = '/catalog' + (path ? '/' + path : '');

    return [path, basePath];
}

const SubCategories = (p: { leaves: Record<string, TreeLeaf>, level: number }) => {
    const theme = useTheme();
    const leaves = Object.values(p.leaves);

    const style = {
        color: "#8F95B2",
        borderRadius: "0 16px 16px 0",
        '&:hover': { color: theme.palette.info.contrastText },
        justifyContent: "start",
        textAlign: "start",
        width: "max-content",
        maxWidth: "200px",
    }

    return (<>
        {leaves.map(c => (
            <Box
                key={c.id}
                sx={{
                    pl: 1,
                    borderLeft: "2px solid #8f95b2",
                    py: 1,
                }}>
                <Link underline="none" component={RouterLink} to={`/catalog/${c.aliasfull}`} sx={style}>{c.label}</Link>
                {c.children && <SubCategories leaves={c.children} level={p.level + 1} />}
            </Box>
        ))}
    </>);
}

const getDefaultPath = (leaf: TreeLeaf): string => {
    const keys = Object.keys(leaf.children);
    if(!keys.length) return leaf.alias;


    return leaf.alias+'/'+getDefaultPath(leaf.children[keys[0]]);
}

const CatalogView: FC<Props> = () => {
    const catalog = useContext(CatalogContext);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();


    const group = searchParams.get('g');
    const search = searchParams.get('q');
    const sort = searchParams.get('s');
    const page = +searchParams.get('page') || 1;

    const filters: Record<number, any> = useMemo(() => searchParams.has('f') ? JSON.parse(searchParams.get('f')) : undefined, [searchParams.get('f')]);

    let [path] = useMemo(() => getUrlParams(location.pathname.substring('/catalog'.length)), [location.pathname]);

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Каталог');
    });

    const rootLevel = catalog.getTreeLevel();
    const defaultPath = rootLevel ? getDefaultPath(Object.values(rootLevel.list)[0]) : undefined;
    path = path || defaultPath;

    const treeLevel = catalog.getTreeLevel(path, filters, search, group, sort, page);
    
    const _filterProps = treeLevel?.type == 'items' ? treeLevel.filterProps : undefined;
    const [filterProps, setFilterProps] = useState(_filterProps);
    useEffect(() => {
        if(_filterProps === undefined) return;

        setFilterProps(_filterProps);

    }, [ _filterProps ]);

    const _filterPresets = treeLevel?.type == 'items' ? treeLevel.filterPresets : undefined;
    const [filterPresets, setFilterPresets] = useState(_filterPresets);
    useEffect(() => {
        if(_filterPresets === undefined) return;

        setFilterPresets(_filterPresets);

    }, [ _filterPresets ]);
    

    const _breadcrumbs = treeLevel?.type == 'items' ? treeLevel.breadcrumbs : undefined;
    const [breadcrumbs, setBreadcrumbs] = useState(_breadcrumbs);
    useEffect(() => {
        if(_breadcrumbs === undefined) return;

        setBreadcrumbs(_breadcrumbs);

    }, [ _breadcrumbs ]);

    if(search) {
        return <SearchView />;
    }

    if (treeLevel?.type == 'item') {
        return <CatalogCardView />;
    }

    if(treeLevel?.type == 'categories') {
        return <Navigate to={defaultPath} />;
    }

    return (<>
        <HeadingView />
        <Grid container spacing={2}>
            <Grid item
                xs={12}
                sm={path ? 4 : 3}
                lg={path ? 3 : 12}
            >
                <FiltersBlock
                    selected={filters}
                    props={toJS(filterProps)}
                    onSelect={selected => {
                        const current = Object.fromEntries(searchParams.entries());
                        delete current.page;

                        if (selected) current.f = JSON.stringify(selected);
                        else delete current.f;

                        navigate({
                            pathname: `/catalog/${treeLevel.category.aliasFull}`,
                            search: `?${createSearchParams(current)}`,
                        });
                    }}
                />
            </Grid>
            <Grid item
                xs={12}
                sm={path ? 8 : 12}
                lg={path ? 9 : 12}
            >
                <Stack spacing={1}>
                    <TopCategoriesSelect presets={filterPresets}>
                        <Stack
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: "12px"
                                }
                            }}
                            spacing={1} direction={{ xs: 'column', sm: 'row', }}>
                            <SortBlock
                                sort={searchParams.get('s') || undefined}
                                onChange={s => {
                                    const current = Object.fromEntries(searchParams.entries());
                                    delete current.page;

                                    if (s) current.s = s;
                                    else delete current.s;

                                    navigate({
                                        pathname: `/catalog/${treeLevel.category.aliasFull}`,
                                        search: `?${createSearchParams(current)}`,
                                    });
                                }}
                            />
                        </Stack>
                    </TopCategoriesSelect>
                    <Breadcrumbs items={breadcrumbs} />
                    <ItemsList
                        key={path}
                        filters={filters}
                        group={group}
                        itemsMode="grid"
                        path={path}
                        search={search}
                        sort={sort}
                        page={page}
                        treeLevel={treeLevel}
                        onPageChange={page => {
                            const current = Object.fromEntries(searchParams.entries());
                            if (page == 1)
                                delete current.page;
                            else
                                current.page = '' + page;

                            setSearchParams(current);
                        }}
                    />
                </Stack>
                {treeLevel?.type == 'items' && treeLevel.category.description ?
                    <HTMLRenderer sx={{ mt: 2 }} children={treeLevel.category.description} />
                    : undefined}
            </Grid>
        </Grid>
    </>);
};

export default observer(CatalogView);