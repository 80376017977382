import { createElement, useContext } from "react";
import { AppContext } from "../store";
import { ReactComponentLike } from "prop-types";


export type Theme = {
    readonly components: Readonly<Record<string, ReactComponentLike>>;
};

const themes: Record<string, Theme> = {};

export const loadTheme = async (id: string): Promise<any> => {
    if(themes[id]) return themes[id];
    
    return await import(`./${id}`).then(t => (themes as any)[id] = t);
}

export function themed<T> (key: string, comp: T ): T {
    return ((p: any) => {
        const app = useContext(AppContext);

        if(app.theme === null) return createElement(comp as any, p);

        const theme = themes[app.theme];
        if(!theme) throw new Error('Тема не найдена');

        const component = theme.components[key] || comp;
        if(!component) throw new Error("Компонент темы не найден");

        return createElement(component as any, p);
    }) as any;
};

export function isThemeLoaded(id: string): boolean {
    return !!themes[id];
}