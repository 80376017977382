import * as React from "react";
import {FC} from "react";
import {FormControl, FormControlProps, InputLabel, MenuItem, Select} from "@mui/material";

type Props = {
    readonly sort?: string;
    readonly onChange?: (sort?: string) => void;
} & FormControlProps;

const SortBlock: FC<Props> = ({ sort, onChange, ...props }) => {

    return (
        <FormControl sx={{ minWidth: 170 }} size="small" {...props}>
            <InputLabel id="sort-block-sort-label">Сортировка</InputLabel>
            <Select
                labelId="sort-block-sort-label"
                value={sort || ''}
                label="Сортировка"
                onChange={e => {
                    onChange && onChange(e.target.value);
                }}
            >
                <MenuItem value="">
                    <em>По умолчанию</em>
                </MenuItem>
                <MenuItem value={1}>Дешевле</MenuItem>
                <MenuItem value={2}>Дороже</MenuItem>
            </Select>
        </FormControl>
    );
};

export default SortBlock;