import { FC } from "react";
import { Link } from "react-router-dom";
import logoIcon from '../../icons/logo.webp';
import { Box } from "@mui/material";

export type Props = {
    readonly size?: 'large' | 'normal' | 'small' | 'extra-small';
    readonly disableLink?: boolean;
};

const Logo: FC<Props> = (p) => {
    const size = p.size || 'normal';

    return (
        <Box to="/" component={p.disableLink ? undefined : Link}>
            <Box
                component="img" 
                src={logoIcon}
                sx={{ width: ({
                    normal: 300,
                    large: 300,
                    small: 200,
                    'extra-small': 150,
                }[size]) }}
            />
        </Box>
    );
}

export default Logo;