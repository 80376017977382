import ListEmptyItem from "../ListEmptyItem";
import {
    Box,
    Grid,
    Skeleton,
    Stack,
} from "@mui/material";
import { createElement, FC, RefObject, useEffect, useMemo, useState } from "react";
import { Item } from "../../common/item";
import ListTable from "../Order/ListTable";
import ListTableBonus from "../views/ProfileView/BonusView/ListTable";
import { Order } from "../Order";
import LoadingPaper from "../LoadingPaper";
import { observer } from "mobx-react-lite";
import { fill, range } from "lodash";
import { CacheableWithPagination } from "../../common/caching";
import { LoadingButton } from "@mui/lab";

export type Patch<K extends string | number = string | number, T = {}> = Record<K, null | T>;

type Props = {
    readonly items?: CacheableWithPagination & { list: ReadonlyArray<any> };
    readonly loadNext?: (page: number) => void;
    readonly compact?: boolean;
    readonly groups?: ((item: any) => { text: string, condition: () => boolean })[];
    readonly onItemClick?: (item: any) => void;
    readonly onDelete?: (item: any) => void;
    readonly onAmountChange?: (item: any, amount: number) => void;
    readonly component: FC<{ item: any }>;
    readonly variant?: 'grid' | 'table' | 'bonus';
    readonly scrollParentRef?: RefObject<HTMLElement>;
    readonly keyResolver?: (item: any) => string | number;
    readonly noLoader?: boolean;
    readonly noEmptyMessage?: boolean;
    readonly events?: Record<string, CallableFunction>;
    readonly patch?: Patch;
};

export type ListItemItem<T = any> = { type: 'item', item: T };
export type ListItemGroup = { type: 'group', text: string };
export type ListItem = ListItemItem | ListItemGroup;

const ItemGroup = (p: { item: ListItemGroup }) => {
    return (
        <Grid item xs={12}>
            <strong>{p.item.text}</strong>
        </Grid>
    );
}

const TableWrap = (p: { list: ListItem[], component: FC<{ item: Item | Order, events?: Record<string, CallableFunction>, patch?: any }>, keyResolver: (item: any) => number | string, compact?: boolean, events?: Record<string, CallableFunction>, patch?: Patch }) => {

    return (
        <ListTable
            items={p.list as ListItemItem[]}
            component={p.component as any}
            events={p.events}
            patch={p.patch}
            keyResolver={p.keyResolver}
        />
    );
}

const TableBonus = (p: { list: ListItem[], component: FC<{ item: Item | Order, events?: Record<string, CallableFunction>, patch?: any }>, keyResolver: (item: any) => number | string, compact?: boolean, events?: Record<string, CallableFunction>, patch?: Patch }) => {

    return (
        <ListTableBonus
            items={p.list as ListItemItem[]}
            component={p.component as any}
            events={p.events}
            patch={p.patch}
            keyResolver={p.keyResolver}
        />
    );
}

const SimpleWrap = (p: { list: ListItem[], component: FC<{ item: Item | Order, events?: Record<string, CallableFunction>, patch?: any }>, keyResolver: (item: any) => number | string, compact?: boolean, events?: Record<string, CallableFunction>, patch?: Patch }) => {

    return (
        <Stack spacing={2}>
            {p.list?.map(item => {
                if (item.type === 'group')
                    return <ItemGroup item={item} key={item.text} />;

                const key = p.keyResolver(item.item);
                const patch = p.patch && p.patch[key];

                return createElement(p.component, { item: item.item, key, events: p.events, patch });
            })}
        </Stack>
    );
}

const GridWrap = (p: { list: ListItem[], component: FC<{ item: Item | Order, events?: Record<string, CallableFunction>, patch?: any }>, keyResolver: (item: any) => number | string, compact?: boolean, events?: Record<string, CallableFunction>, patch?: Patch }) => {

    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 0, md: 2 }}>
            {p.list?.map(item => {
                if (item.type === 'group') {
                    return (
                        <Grid item xs={12} key={item.text}>
                            <ItemGroup item={item} />
                        </Grid>
                    );
                }

                const key = p.keyResolver(item.item);
                const patch = p.patch && p.patch[key];

                return (
                    <Grid item xs={12} xsm={6} sm={12} smm={p.compact ? 4 : 6} lg={p.compact ? 2 : 4} key={p.keyResolver(item.item)}>
                        {createElement(p.component, { item: item.item, events: p.events, patch })}
                    </Grid>
                );
            })}
        </Grid>
    );
}

const pageSize = 24; // TODO: Убрать этот хардкод пагинации

const makeItems = (outerItems: Props['items']): Props['items']['list']|undefined => {
    if (!outerItems?.list) return undefined;

    return outerItems.list.slice(0, pageSize);
};

const List: FC<Props> = ({
    variant,
    component,
    items: outerItems,
    loadNext,
    groups,
    scrollParentRef,
    keyResolver,
    compact,
    noLoader,
    noEmptyMessage,
    events,
    patch,
}) => {
    if (!keyResolver)
        keyResolver = (item: any) => item.id;

    const [nextPage, setNextPage] = useState(null);
    const [items, setItems] = useState(makeItems(outerItems));

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        if (!outerItems?.list) return;

        if (items) {
            setItems(outerItems.list);
            setNextPage(outerItems.nextPage);
        }
        else {
            const newItems = makeItems(outerItems);
            setItems(newItems);
            setNextPage(outerItems.list.length > newItems.length ? 2 : outerItems.nextPage);
        }
        setPageLoading(false);

    }, [outerItems?.list]);


    const loadNextProxy = () => {
        if (!nextPage) return;

        if (outerItems.list.length > items.length) {
            const newItems = outerItems.list.slice(0, pageSize * nextPage);
            setItems(newItems);
            if (newItems.length < outerItems.list.length)
                setNextPage(nextPage + 1);
            else
                setNextPage(outerItems.nextPage);
        }
        else if (loadNext) {
            loadNext(nextPage);
        }
    };


    const list: ListItem[] = useMemo(() => {
        setPageLoading(false);
        
        if (!items) return undefined;
        if (!groups) return items.map(item => ({ type: 'item', item }));

        const result: ListItem[] = [];

        const states = fill(Array(groups.length), {}) as { val?: boolean, text?: string }[];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            for (let j = 0; j < groups.length; j++) {
                const fn = groups[j];
                const g = fn(item);

                const val = g.condition();
                if (val) {
                    if (states[j].val !== val || g.text !== states[j].text) {
                        result.push({ type: 'group', text: g.text });
                        states[j] = { val, text: g.text };
                    }
                }
                else if (states[j].val !== val) {
                    states[j] = { val, text: null };
                }
            }
            result.push({ type: 'item', item });
        }

        return result;

    }, [items, groups]);

    const wrap = {
        'grid': GridWrap,
        'table': TableWrap,
        'bonus': TableBonus,
    }[variant] || SimpleWrap;

    return (
        <Stack spacing={2} sx={{ mb: variant == 'grid' ? 17 : 2 }}>
            {list ? (
                list.length ? (<>
                    {createElement(wrap, { list, component, keyResolver, compact, events, patch })}
                    {!nextPage ? undefined : (
                        <LoadingButton
                            loading={pageLoading}
                            children="Загрузить еще..."
                            onClick={() => {
                                setPageLoading(true);
                                loadNextProxy();
                            }}
                        />
                    )}
                </>) : (<>
                    {noEmptyMessage ? <></> : <ListEmptyItem />}
                </>)
            ) : (<>
                {noLoader ? <></> : (
                    variant == 'grid' ? (
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 0, md: 2 }}>
                            {range(24).map(i => (
                                <Grid key={i} item xs={12} xsm={6} sm={12} smm={compact ? 4 : 6} lg={compact ? 2 : 4}>
                                    <Stack spacing={1}>
                                        <Skeleton
                                            variant="rounded"
                                            height={220}
                                        />
                                        <Skeleton
                                            variant="rounded"
                                            height={35}
                                        />
                                        <Skeleton
                                            variant="rounded"
                                            height={15}
                                            width="30%"
                                        />
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <LoadingPaper />
                    )
                )}
            </>)}
        </Stack>
    );
}

export default observer(List);