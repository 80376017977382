import { Box, BoxProps, useTheme } from "@mui/material";
import noImageIcon from "../../../icons/noimage.webp";
import { isNumber } from "mathjs";

export type Props = {
    readonly alt?: string;
    readonly width?: BoxProps["width"];
    readonly height?: BoxProps["height"];
    
    readonly maxWidth?: BoxProps["maxWidth"];
    readonly maxHeight?: BoxProps["maxHeight"];
}

const NoImage = (p: Props) => {
    const theme = useTheme();

    const ratio = 0.5;
    const imgMaxWidth = isNumber(p.width) ? p.width * ratio : `calc(${p.width} * ${ratio})`;
    const imgMaxHeight = isNumber(p.height) ? p.height * ratio : `calc(${p.height} * ${ratio})`;

    return (
        <Box
            sx={{
                width: p.width,
                height: p.height,
                maxWidth: p.maxWidth,
                maxHeight: p.maxHeight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: theme.palette.grey[300],
            }}
        >
            <Box
                component="img"
                src={noImageIcon}
                sx={{
                    maxWidth: imgMaxWidth,
                    maxHeight: imgMaxHeight,
                }}
            />
        </Box>
    );
}

export default NoImage;