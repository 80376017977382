import { createElement, FC, forwardRef, PropsWithChildren, ReactElement } from "react";
import {
    Badge,
    BadgeProps,
    Box,
    BoxProps,
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemText,
    styled,
    useMediaQuery,
} from "@mui/material";
import { NavLink } from "react-router-dom";

export type Props = {
    readonly name: string;
    readonly to?: (string | (() => void));
    readonly icon?: ReactElement;
    readonly end?: boolean;
    readonly reload?: boolean;
    readonly badge?: string | number;
    readonly wrapper?: FC;
} & BoxProps;

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        color: 'white',
        height: '100%',
        right: '-25px',
        top: '13px',
    },
}));

const Child: FC<PropsWithChildren & {
    readonly name: string;
    readonly icon?: ReactElement;
    readonly badge?: string | number;
    readonly onClick?: ListItemButtonProps['onClick'];
}> = (p) => {
        const isSmallPhone = useMediaQuery('(max-width: 425px)', { noSsr: true });

        return (
            <ListItemButton
                onClick={p.onClick}
                disableRipple={!p.onClick}
            >
                {p.icon && (
                    <ListItemIcon sx={{ width: 24, height: 24 }}>
                        {p.icon}
                    </ListItemIcon>
                )}
                <StyledBadge invisible={!p.badge} badgeContent={p.badge} color="warning">
                    <ListItemText
                        sx={{
                            color: '#8F95B2',
                            '& .MuiTypography-root': {
                                fontSize: isSmallPhone ? '13px' : 'inherit'
                            }
                        }}
                        primary={p.name}
                    />
                </StyledBadge>
                {p.children}
            </ListItemButton>
        );
    };

const MenuItem: FC<Props> = forwardRef(({ name, to, icon, end, reload, badge, wrapper, children, ...boxProps }: any, ref) => {

    let el: ReactElement = null;
    if(typeof to === 'string') {
        el = (
            <Box
                ref={ref}
                component={NavLink}
                to={to}
                end={end}
                reloadDocument={reload}
                children={<Child
                    children={children}
                    name={name}
                    badge={badge}
                    icon={icon}
                />}
                {...boxProps}
            />
        );
    }
    else {
        el = (
            <Box
                ref={ref}
                component="span"
                children={<Child
                    children={children}
                    name={name}
                    badge={badge}
                    icon={icon}
                    onClick={to}
                />}
                {...boxProps}
            />
        );
    }

    if (wrapper)
        return createElement(wrapper, null, el);

    return el;
}) as any as FC<Props>;

export default MenuItem;