import {Item} from "../../index";
import {AlgorithmPassResult} from "../index";

type DiscountPercentParams = {
    readonly discountPercent: number;
};
type FixedPriceParams = {
    readonly fixedPrice: number;
};

export type Params = DiscountPercentParams & {
    readonly items: Record<number, {
        readonly requiredCount: number;
    }>;
};

export const getIncludedItems = (params: Readonly<Params>): { id: string }[] => {
    return Object.keys(params.items).map(id => ({ id }));
}

export const getSampleSet = (items: ReadonlyArray<Readonly<{ id: string, stock: number }>>, params: Readonly<Params>): { id: string, count: number }[] => {
    let neededCount = 0;
    for(const id in params.items)
        neededCount += params.items[id].requiredCount;

    let totalCount = 0;
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;

        if(item.stock < params.items[item.id].requiredCount) continue;
        result.push({ id: item.id, count: params.items[item.id].requiredCount });

        totalCount += params.items[item.id].requiredCount;
    }
    if(totalCount < neededCount) return [];

    return result;
}

export const getItemsSet = (items: ReadonlyArray<Readonly<{ id: string, count: number, stock: number }>>, setsCount: number, params: Readonly<Params>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} => {
    let maxSetsCount: null|number = null;

    let neededCount = 0;
    for(const id in params.items)
        neededCount += params.items[id].requiredCount;
    neededCount *= setsCount;

    let totalCount = 0;
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;

        const baseCount = params.items[item.id].requiredCount;
        if(item.stock < baseCount * setsCount) continue;

        result.push({ id: item.id, count: baseCount * setsCount });

        if(maxSetsCount === null) {
            maxSetsCount = Math.floor(item.stock / baseCount);
        }
        else {
            maxSetsCount = Math.min(
                maxSetsCount,
                Math.floor(item.stock / baseCount),
            );
        }

        totalCount += baseCount * setsCount;
    }
    if(totalCount < neededCount)
        return { maxSetsCount: 0, items: [] };

    return { maxSetsCount, items: result };
}

export const getTargetPrices = (items: ReadonlyArray<{ id: string, price: number }>, params: Readonly<Params>): {
    id: string,
    price: number,
}[] => {
    const k = (100 - params.discountPercent) / 100;
    return items.map(v => ({id: v.id, price: v.price * k }));
}

const ItemsSetDiscount = (items: ReadonlyArray<Readonly<Item>>, params: Readonly<Params>): AlgorithmPassResult => {
    if(!items.length) return { affected: [], untouched: [], groupsCount: 0 };

    const untouched: Item[] = items.map(v => ({...v}));

    let minNeededCount = 0;
    for(const id in params.items)
        minNeededCount += params.items[id].requiredCount;

    let minTotalCount = 0;
    const countedIndices: number[] = [];
    let setsCount: number = null;
    for(let idx = 0; idx < items.length; idx++) {
        const item = items[idx];
        if(!params.items[item.id]) continue;

        const baseCount = params.items[item.id].requiredCount;
        const itemCount = Math.min(item.count, item.stock);

        if(itemCount < baseCount) continue;

        if(setsCount === null)
            setsCount = Math.floor(itemCount / baseCount);
        else
            setsCount = Math.min(setsCount, Math.floor(itemCount / baseCount));

        countedIndices.push(idx);
        minTotalCount += baseCount;
    }
    if(minTotalCount < minNeededCount)
        return { affected: [], untouched, groupsCount: 0 };

    const discount = 1 - params.discountPercent / 100;

    const affected: Item[] = [];
    let deletedCount = 0;
    for(let idx of countedIndices) {
        idx = idx - deletedCount;

        const item = untouched[idx];
        const requiredItemCount = setsCount * params.items[item.id].requiredCount;
        if(item.count > requiredItemCount) {
            item.count -= requiredItemCount;
            item.stock -= requiredItemCount;
        }
        else {
            untouched.splice(idx, 1);
            deletedCount++;
        }

        affected.push({
            ...item,
            price: item.price * discount,
            count: requiredItemCount,
        });
    }

    return { affected, untouched, groupsCount: setsCount };
};

export default ItemsSetDiscount;