import { Box, Button, Pagination, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppContext, CartContext, FavoritesContext, WaitListContext, NotificationsContext, UserContext, CatalogContext } from "../../../../store";
import { ItemsListViewMode } from "../../../../store/catalog";
import { CatalogCard } from "../../../Item";
import CatalogWideCard from "../../../Item/CatalogWideCard";
import NotificationEmailSetup from "../../../NotificationEmailSetup";
import { ViewState } from "../../CatalogTreeView";
import List from "../../../List";
import { Item } from "../../../../common/item";
import { useLocation, useNavigate } from "react-router-dom";
import { TreeLevelItems } from "../../../../common/tree";




const StockWarningDialog = (p: {
    readonly stock: number;
    readonly inCart: number;
    readonly onAcceptClick: () => void;
}) => {

    return (
        <Box>
            <Typography variant="h6" color="error" >
                Внимание!
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>На складе осталось товаров: {p.stock} <br /></strong>
                В корзину добавлено: {p.inCart} <br />
                Товаров под заказ: {p.inCart - p.stock} <br /><br />
                По стоимости и срокам доставки недостающих единиц уточняйте у оператора
            </Typography>
            <Button
                sx={{ mt: 2 }}
                variant="contained"
                onClick={p.onAcceptClick}
                children="Хорошо"
            />
        </Box>
    );
};

const _createCard = observer((p: { mode: ItemsListViewMode, item: { item } }) => {
    const mode = p.mode; const item = p.item;

    const app = useContext(AppContext);
    const navigate = useNavigate();

    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);
    const waitList = useContext(WaitListContext);
    const notifications = useContext(NotificationsContext);
    const catalog = useContext(CatalogContext);

    const notify = notifications.notify;

    const variants = catalog.getItemVariants(item.item.mainItemId);

    const [currentVariantId, setCurrentVariantId] = useState(item.item.variants ? (item.item.variants.indexOf(item.item.variantName)) : undefined);

    const itemId = variants ? variants[currentVariantId].id : item.item.id;
    const variant = catalog.getItemByIdForList(itemId);

    const favorite = favorites.includes(variant?.id || item.item.id);
    const inCartCount = cart.itemsCount(variant?.id || item.item.id);

    return (
        <Box
            component={mode === 'grid' ? CatalogCard : CatalogWideCard}
            variant={variant}
            item={item.item}
            inCartCount={inCartCount}
            favorite={favorite}
            currentVariantId={currentVariantId}
            onCurrentVariantIdChange={setCurrentVariantId}
            onFavoritesClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                if (favorite) {
                    await favorites.remove(cartItem);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem);
                    app.alert(`Добавлено в избранное`);
                }

            }}
            onAddToCartClick={async (count, variant: Item) => {
                if(inCartCount) {
                    navigate('/cart');
                    return;
                }

                await cart.add(variant);

                if (variant.count <= 0) {
                    await waitList.add(variant);
                    if (notify && !notify.email)
                        app.showDialog(<NotificationEmailSetup />);
                    app.alert(`Добавлено в корзину и лист ожидания`);
                }
                else {
                    if (inCartCount + count > item.item.count) {
                        app.showDialog(<StockWarningDialog
                            stock={item.item.count}
                            inCart={inCartCount + count}
                            onAcceptClick={() => app.closeDialog()}
                        />, { fullWidth: false });
                    }

                    app.alert(`Добавлено в корзину`);
                }

            }}
        />
    );
})
const MyCatalogCard = (item: any) => <_createCard item={item} mode="grid" />;
const MyCatalogWideCard = (item: any) => <_createCard item={item} mode="row" />;


type Props = {
    readonly path?: string;
    readonly filters?: Record<number, any>;
    readonly search?: string;
    readonly group?: string;
    readonly sort?: string;
    readonly filterDisabled?: boolean;
    readonly page?: number;
    readonly onPageChange?: (page: number) => void;
    readonly pageCount?: number;
    readonly itemsMode?: ItemsListViewMode;
    readonly treeLevel?: TreeLevelItems;

    readonly onViewChange?: (s: ViewState) => void;
};

const ItemsList: FC<Props> = (p) => {
    const page = p.page || 1;
    const catalog = useContext(CatalogContext);
    const location = useLocation();

    const treeLevel = p.treeLevel;
    const _pageCount = treeLevel?.pageCount;
    const [pageCount, setPageCount] = useState(_pageCount);
    useEffect(() => {
        if(_pageCount === undefined) return;

        setPageCount(_pageCount);

    }, [ _pageCount ]);

    return (<>
        <List
            variant={p.itemsMode === 'grid' ? 'grid' : undefined}
            component={p.itemsMode === 'grid' ? MyCatalogCard : MyCatalogWideCard}
            key={(p.itemsMode || '') + location.search}
            items={treeLevel}
            loadNext={page => catalog.loadNextPage(page, p.path, p.filters, p.search, p.group, p.sort)}
            groups={[
                item => ({ text: `В наличии: ${item.count > 0 ? 'Есть' : 'Нет'}`, condition: () => p.group == '1' }),
                item => ({ text: `Бренд: ${item.brand}`, condition: () => p.group == '2' }),
            ]}
        />
        {pageCount > 1 && (
            <Pagination
                count={pageCount} 
                page={page} 
                onChange={(_, page) => p.onPageChange && p.onPageChange(page)}
            />
        )}
        


        {/* {!!p.treeLevel.similar?.list?.length && (<>
            <Typography sx={{ mt: 2, mb: 1 }} variant="h6">
                Аналоги
            </Typography>
            <List
                variant="grid"
                component={MyCatalogCard}
                key={location.search + '_c'}
                items={p.treeLevel.similar}
            />
        </>)} */}
    </>);
}

export default observer(ItemsList);