import { FC } from "react";
import { Dayjs } from "dayjs";
import { BoxProps, Stack, Typography } from "@mui/material";

type Props = BoxProps & {
    readonly date: Dayjs;
};

const Date: FC<Props> = ({ date, component = 'span', ...props }) => {

    return (
        <Stack component={component} direction={{ xs: 'row', lg: 'column' }} alignItems="center" {...props}>
            <Typography component="span" variant="body1">
                {date.format('DD.MM.YYYY')}
            </Typography>
            <Typography 
                component="span" 
                variant="body2" 
                color="text.secondary"
                sx={{
                    ml: { xs: 1, md: undefined }
                }}
            >
                {date.format('HH:mm')}
            </Typography>
        </Stack>
    );
}

export default Date;