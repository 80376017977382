import { Typography, TypographyProps } from "@mui/material";
import { FC } from "react";

type Props = {
    readonly sx?: TypographyProps['sx'];
    readonly variant?: TypographyProps['variant'];
    readonly children?: string;
};

const HTMLRenderer: FC<Props> = (p) => {

    return (
        <Typography
            sx={p.sx}
            variant={p.variant}
            dangerouslySetInnerHTML={{ __html: p.children }}
        />
    );
}

export default HTMLRenderer;