import { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";
import { formatPrice as $ } from "../../../utils";
import {
    Box,
    Button,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import SuccessImg from '../../../icons/orderSuccess.svg';
import ReserveImg from '../../../icons/orderReserve.svg';
import { UserContext } from "../../../store";

type Props = {

};

const OrdersSuccessView: FC<Props> = () => {

    const theme = useTheme();
    const user = useContext(UserContext);
    const { orderId } = useParams();

    return (<>
        {user.debtInfo?.overdebt ? (
            <>
                <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
                    <Box component={ReserveImg} />
                    <Typography fontWeight="bold" variant="h2" sx={{ padding: "30px 0 60px 0" }}>Заказ зарезервирован!</Typography>
                    <Stack>
                        <Box sx={{ pb: 1 }} textAlign="center">
                            <Typography component="div" variant="body1" color={theme.palette.warning.main}>
                                Заказ будет исполнен после оплаты:
                            </Typography>
                            <Typography component="span" variant="h6" fontWeight="bold" color={theme.palette.warning.main}>
                                {$(user.debtInfo.overdebt)}
                            </Typography>{" "}
                            <Typography color="#8F95B2">C вами свяжется клиентский менеджер</Typography>
                        </Box>
                    </Stack>
                    <Stack flexDirection="row" gap={2} py={6}>
                        <Link to="/catalog">
                            <Button
                                sx={{
                                    background: "#8F95B2"
                                }}
                                variant="contained">
                                Вернуться в каталог
                            </Button>
                        </Link>
                    </Stack>
                </Stack>
            </>
        ) : (
            <>
                <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
                    <Box component={SuccessImg} />
                    <Typography fontWeight="bold" variant="h2" pt={3}>Заказ оформлен!</Typography>
                    <Stack flexDirection="row" gap={2} py={6}>
                        <Link to="/catalog">
                            <Button
                                sx={{
                                    background: "#8F95B2"
                                }}
                                variant="contained">
                                Вернуться в каталог
                            </Button>
                        </Link>
                    </Stack>
                </Stack >
            </>
        )
        }
    </>);
};

export default observer(OrdersSuccessView);