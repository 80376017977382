import { observer } from "mobx-react-lite";
import CartPromoGroup from "../../../Item/CartPromoGroup";
import { getItemsSet } from "../../../../../ts-shared/promo";
import { indexBy } from "../../../../../ts-shared/utils";
import { Box, Checkbox, Stack, useMediaQuery, useTheme } from "@mui/material";
import { AppContext, CartContext, CatalogContext, FavoritesContext } from "../../../../store";
import { createElement, useContext, useMemo } from "react";
import { CartWideCard } from "../../../Item";
import { Item } from "../../../../common/item";
import LoadingPaper from "../../../LoadingPaper";
import ListEmptyItem from "../../../ListEmptyItem";
import { Group } from "../../../../store/cart";
import ItemCardModal from "../../../ItemCardModal";
import CartCard from "../../../Item/CartCard";

type Props = {

}

const UnifiedView = (p: Props) => {
    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const promoGroups = cart.promoGroups;
    const promoItems = cart.promoItems;
    const hasItems = (promoGroups?.length || 0) + (promoItems?.length || 0) > 0;

    const catalog = useContext(CatalogContext);
    const context = catalog.promoContext;


    const checkedItemsById = cart.checkedItemsById || {};
    const checkedItemsCounter = { ...checkedItemsById };

    const items = cart.items?.list;
    const itemsById = useMemo(() => {
        if (!items) return;

        return indexBy(items.map(v => ({...v, quantity: 1})), v => v.id);
    }, [items]);

    const list: (Item | Group)[] = [];
    if (promoGroups && promoItems) {
        const itemsByIds: Record<string, Record<string, Item>> = {};
        const groupsByItemIds: Record<number, Group[]> = {};

        for (const group of promoGroups) {
            const disableGrouping = context.promoList[group.promoId].display?.variant === 'items';
            if (disableGrouping) {
                for (const it of group.items) {
                    if (!itemsByIds[it.id]) itemsByIds[it.id] = {};

                    const key = (it.price || 0).toFixed(2);
                    if (itemsByIds[it.id][key])
                        itemsByIds[it.id][key].quantity += it.quantity;
                    else
                        itemsByIds[it.id][key] = { ...it };
                }
            }
            else {
                for (const it of group.items) {
                    if (!groupsByItemIds[it.id]) groupsByItemIds[it.id] = [];

                    if (groupsByItemIds[it.id].findIndex(v => v == group) === -1)
                        groupsByItemIds[it.id].push(group);
                }
            }
        }
        for (const it of promoItems) {
            if (!itemsByIds[it.id]) itemsByIds[it.id] = {};

            const key = (it.price || 0).toFixed(2);
            if (itemsByIds[it.id][key])
                itemsByIds[it.id][key].quantity += it.quantity;
            else
                itemsByIds[it.id][key] = { ...it };
        }

        const addedItems: Record<number, true> = {};
        const addedGroups: Record<number, Group[]> = {};
        for (const it of items) {
            if (groupsByItemIds[it.id]) {
                for (const g of groupsByItemIds[it.id]) {
                    if (!addedGroups[g.promoId]) addedGroups[g.promoId] = [];

                    if (addedGroups[g.promoId].findIndex(v => v == g) === -1) {
                        addedGroups[g.promoId].push(g);
                        list.push({ ...g });
                    }
                }
            }
            if (itemsByIds[it.id]) {
                if (!addedItems[it.id]) {
                    addedItems[it.id] = true;
                    list.push(...Object.values(itemsByIds[it.id]).map(v => ({ ...v })));
                }
            }
        }

    }

    return (
        <>
            {hasItems && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Checkbox
                        onClick={e => e.stopPropagation()}
                        checked={cart.isAllChecked}
                        indeterminate={!cart.isAllChecked && !cart.isAllUnchecked}
                        onChange={(e => {
                            const checked = e.target.checked;
                            if (checked)
                                cart.checkAll();
                            else
                                cart.uncheckAll();
                        })}
                    />
                </Box>
            )}

            <Stack spacing={2}>
                {list.map((v, index) => {
                    if ('promoId' in v) {
                        const group = v;

                        const quantityById: Record<number, number> = {};

                        for (const v of group.items) {
                            if (!quantityById[v.id]) quantityById[v.id] = 0;
                            quantityById[v.id] += v.quantity;
                        }

                        let isChecked = true;
                        for (const id in quantityById) {
                            if (checkedItemsCounter[id] < quantityById[id]) {
                                isChecked = false; break;
                            }
                        }

                        if (isChecked) {
                            for (const id in quantityById)
                                checkedItemsCounter[id] -= quantityById[id];
                        }

                        return (
                            <CartPromoGroup
                                key={`${group.promoId}_${index}`}
                                group={group}
                                checked={isChecked}
                                onItemClick={({ id }) => {
                                    app.showDialog(<ItemCardModal item={itemsById[id]} />)
                                }}
                                onCheckChange={c => {
                                    const list: { id: string, checked: number }[] = [];
                                    for (const id in quantityById)
                                        list.push({ id: id, checked: c ? checkedItemsById[id] + quantityById[id] : checkedItemsById[id] - quantityById[id] });

                                    cart.checkItems(list);
                                }}
                                onRemoveClick={() => {
                                    cart.removeManyWithAmount(group.items.map(v => ({
                                        item: v, amount: v.quantity,
                                    })));
                                }}
                                onSetsChange={count => {
                                    const promoItems: { id: string, count: number, stock: number, price: number }[] = [];
                                    const byId: Record<number, { id: string, count: number, stock: number, price: number }> = {};
                                    for (const item of group.items) {
                                        if (byId[item.id]) {
                                            byId[item.id].count += item.quantity;
                                            continue;
                                        }
                                        byId[item.id] = { id: item.id, count: item.quantity, stock: item.count, price: item.price };
                                        promoItems.push(byId[item.id]);
                                    }

                                    const prevItems = getItemsSet(group.promoId, promoItems, group.count, context).items;
                                    const currentItems = getItemsSet(group.promoId, promoItems, count, context).items;

                                    const prevCountById = indexBy(
                                        prevItems,
                                        v => v.id,
                                    );
                                    const countById = indexBy(
                                        currentItems,
                                        v => v.id,
                                    );

                                    if (count - group.count > 0) {
                                        cart.addManyWithAmount(currentItems.map(v => ({
                                            item: {...itemsById[v.id]},
                                            amount: countById[v.id].count - prevCountById[v.id].count,
                                        })));
                                    }
                                    else {
                                        cart.removeManyWithAmount(currentItems.map(v => ({
                                            item: {...itemsById[v.id]},
                                            amount: prevCountById[v.id].count - countById[v.id].count,
                                        })));
                                    }
                                }}
                            />
                        );
                    }

                    const item = v;

                    const isFavorite = favorites.includes(item.id);
                    
                    const comp = isMobile ? CartCard : CartWideCard;

                    return (
                        createElement(comp, {
                            key: item.id,
                            item: item,
                            count: item.quantity,
                            baseCount: (item as any).baseCount,  // TODO: Типизировать
                            favorite: isFavorite,
                            checked: checkedItemsCounter[item.id] > 0,
                            onCheckChange: c => cart.checkItem(
                                item.id,
                                c ? checkedItemsById[item.id] + item.quantity : checkedItemsById[item.id] - item.quantity
                            ),
                            onFavoritesClick: async () => {
                                if (isFavorite) {
                                    await favorites.remove(item);
                                    app.alert(`Удалено из избранного`);
                                }
                                else {
                                    await favorites.add(item);
                                    app.alert(`Добавлено в избранное`);
                                }
                            },
                            onCountChange: count => {
                                const diff = count - item.quantity;
                                if (diff > 0)
                                    cart.addWithAmount(item, diff);
                                else
                                    cart.removeWithAmount(item, -diff);
                            },
                            onRemoveFromCartClick: async () => {
                                await cart.removeWithAmount(item, item.quantity);
                                app.alert(`Удалено из корзины`);
                            },
                        })
                    );

                })}
            </Stack>

            {(!promoItems || !promoGroups) ? (
                <LoadingPaper />
            ) : (
                hasItems ? undefined : (
                    <ListEmptyItem />
                )
            )}
        </>
    );
}

export default observer(UnifiedView);