import {FC, useContext, useState} from "react";
import WideCard from "../WideCard";
import { Item } from "../../../common/item";
import WideCardStepperAction from "../WideCardStepperAction";
import {Box, Checkbox, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {BonusContext, CatalogContext} from "../../../store";

type Props = {
    readonly item: Item;
    readonly onRemoveFromCartClick?: () => void;
    readonly onFavoritesClick: () => void;
    readonly onCountChange?: (value: number) => void;
    readonly count: number;
    readonly disallowOverstock?: boolean;
    /** Указывается, если этот товар уже добавлен в корзину в рамках какой-либо группы */
    readonly baseCount?: number;
    readonly favorite: boolean;
    readonly checked?: boolean;
    readonly onCheckChange?: (checked: boolean) => void;
    readonly onClick?: () => void;
}

const CartWideCard: FC<Props> = (p) => {
    const baseCount = p.baseCount || 0;
    const realStock = Math.max(0, p.item.count - baseCount) || undefined;
    const absentCount = Math.max(p.count - realStock, 0) || undefined;

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: p.count,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });

    
    const catalog = useContext(CatalogContext);
    const variants = catalog.getItemVariants(p.item.id);

    const [currentVariantId, setCurrentVariantId] = useState(p.item.variants ? 0 : undefined);

    return (<Box sx={{ display: 'flex' }}>
        <WideCard
            sx={{ flexGrow: 1 }}
            id={p.item.id}
            isPromotional={!!p.item.isPromotional}
            imgSrc={p.item.imgCard}
            label={p.item.label}
            onFavoritesClick={p.onFavoritesClick}
            favorite={p.favorite}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            stock={p.item.count}
            inCartCount={baseCount}
            count={p.count}
            onClick={p.onClick}
            aliasFull={p.item.aliasFull}
            currentVariantId={currentVariantId}
            onCurrentVariantIdChange={setCurrentVariantId}
            action={
                <WideCardStepperAction
                    isPromotional={!!p.item.isPromotional}
                    disallowOverstock={p.disallowOverstock}
                    count={p.count}
                    baseCount={baseCount}
                    price={p.item.price}
                    oldPrice={p.item.oldPrice}
                    stock={p.item.count}
                    onCountChange={p.onCountChange}
                    onRemoveFromCartClick={p.onRemoveFromCartClick}
                    outOfStock={p.item.count <= 0}
                    bonusValue={bonusValue}
                    variants={p.item.variants}
                    variantsFull={variants}
                    currentVariantId={currentVariantId}
                />
            }
        >
            {realStock && absentCount && (<Box sx={{ mt: 1 }}>
                <Typography color="error">В наличии {realStock}, под заказ {absentCount} </Typography>
                <Typography>
                    По стоимости и срокам доставки недостающих единиц уточняйте у оператора
                </Typography>
            </Box>)}
            {!!p.item.isPromotional && (
                <Typography color="text.secondary">
                    Этот товар не подлежит продаже вне рамок акции.
                </Typography>
            )}
        </WideCard>
        {p.checked !== undefined && (
            <Checkbox
                sx={{ alignSelf: 'center' }}
                onClick={e => e.stopPropagation()}
                checked={p.checked}
                onChange={p.onCheckChange && (e => {
                    p.onCheckChange(e.target.checked);
                })}
            />
        )}
    </Box>);
}

export default observer(CartWideCard);