import * as React from "react";
import {FC, useContext, useEffect, useState} from "react";
import {
    Unstable_Grid2 as Grid,
    Paper,
    Stack,
    Divider, Button, Typography,
} from "@mui/material";
import {observer} from "mobx-react-lite";
import {
    AppContext,
    CartContext,
    WaitListContext,
    UserContext,
    FavoritesContext,
    NotificationsContext
} from "../../../store";
import Heading from "../HeadingView";
import WaitListWideCard from "../../Item/WaitListWideCard";
import {APIError} from "../../../store/app";
import List from "../../List";
import {Link} from "react-router-dom";

type Props = {

};

const MyCard = observer((item: { item }) => {
    const favorites = useContext(FavoritesContext);
    const waitList = useContext(WaitListContext);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);
    const [count, setCount] = useState(1);

    const app = useContext(AppContext);

    const favorite = favorites.includes(item.item.code);

    return (
        <WaitListWideCard
            key={item.item.id}
            item={item.item}
            inCart={cart.includes(item.item.code)}
            count={count}
            onCountChange={setCount}
            favorite={favorite}
            onFavoritesClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                if(favorite) {
                    await favorites.remove(cartItem);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem);
                    app.alert(`Добавлено в избранное`);
                }
            }}
            onRemoveFromCartClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                await waitList.remove(cartItem);
                app.alert(`Удалено из листа ожидания`);
            }}
            onAddToCartClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                await cart.addWithAmount(cartItem, count);
                setCount(1);
                app.alert(`Добавлено в корзину`);
            }}
        />
    );
});

const WaitListView: FC<Props> = () => {
    const waitList = useContext(WaitListContext);
    const user = useContext(UserContext);
    const notifications = useContext(NotificationsContext);

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Лист ожидания');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Лист ожидания' },
        ])
    });

    const notify = notifications.notify;
    const items = user.user?.id ? waitList.items : undefined;
    const summary = waitList.summary;

    return (<>
        <Heading />
        <Grid container columnSpacing={3} direction={{ xs: 'column-reverse', md: 'row' }}>
            <Grid xs={12} md={8} lg={9}>
                <List
                    items={items}
                    component={MyCard}
                />
            </Grid>
            <Grid xs={12} md={4} lg={3}>
                <Paper sx={{ p: 2, mb: { xs: 4, md: 0 } }} elevation={1}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="subtitle1">
                            Уведомления
                        </Typography>
                        <Typography variant="body1">
                            {notify?.email && notify?.enabled ? (<>
                                Вы получаете информацию о поступлении на склад интересующих вас товаров на почту{" "}
                                <Link to={`mailto:${notify.email}`}>{notify.email}</Link>.
                            </>) : (<>
                                Вы можете включить уведомления, чтобы получать информацию о поступлении на склад интересующих вас товаров.
                            </>)}
                        </Typography>
                        <Link to="/profile/notifications">Настроить уведомления</Link>
                        <Divider variant="fullWidth" />
                        <Button
                            size="small"
                            variant="text"
                            color="error"
                            disabled={!summary?.totalItems}
                            onClick={() => {
                                waitList
                                    .clear()
                                    .then(() => {
                                        app.alert('Лист ожидания очищен', { severity: 'info' });
                                    })
                                    .catch((e: APIError) => {
                                        app.alert(e.response.data.meta.message, { severity: 'error' });
                                    })
                                ;
                            }}
                        >
                            Очистить всё
                        </Button>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    </>);
};

export default observer(WaitListView);