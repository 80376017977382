import { FC, useContext, useEffect, useState } from "react";
import {
    Box,
    Container,
    IconButton,
    InputAdornment, OutlinedInput,
    Paper,
    Stack,
    TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { AppContext, AuthContext } from "../../../store";
import { LoadingButton } from "@mui/lab";
import { APIError } from "../../../store/app";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Logo from "../../Logo";
import LoginBackground from "./LoginBackground";
import ExtraInfo from "./ExtraInfo";

type Props = {

};

const LoginView: FC<Props> = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const app = useContext(AppContext);
    const auth = useContext(AuthContext);
    const [progress, setProgress] = useState(false);

    const [isPasswordShown, setIsPasswordShown] = useState(false);


    useEffect(() => {
        app.setTitle('Вход');
    });

    return (
        <LoginBackground>
            <Container fixed>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                    <Logo size="large" disableLink />
                    <Paper sx={{ p: 2, mt: 2, width: 257 }} elevation={1}>
                        <Stack
                            component="form"
                            aria-autocomplete="both"
                            spacing={2}
                            onSubmit={async e => {
                                e.preventDefault();
                                setProgress(true);
                                auth.logIn(login, password)
                                    .catch((e: APIError) => {
                                        app.alert(e.response.data.meta.message, { severity: 'error' });
                                    })
                                    .finally(() => setProgress(false));
                            }}
                        >
                            <h2>Вход</h2>
                            <TextField autoComplete="username" placeholder="Логин" value={login} onChange={e => setLogin(e.target.value)} required />
                            <OutlinedInput
                                autoComplete="current-password"
                                placeholder="Пароль"
                                type={isPasswordShown ? 'text' : 'password'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setIsPasswordShown(!isPasswordShown)}
                                            edge="end"
                                        >
                                            {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                            />
                            <LoadingButton
                                children="Войти"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                loading={progress}
                            />
                        </Stack>
                        <ExtraInfo variant="login" />
                    </Paper>
                </Box>
            </Container>
        </LoginBackground>
    );
};

export default observer(LoginView);