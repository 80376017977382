import * as React from "react";
import {FC, lazy, useContext, useEffect} from "react";
import {observer} from "mobx-react-lite";
import Heading from "../HeadingView";
import {AppContext} from "../../../store";
import PageContentSkeleton from "../../Skeleton/PageContentSkeleton";

const ProfileContentView = lazy(() => import("./ProfileContentView"));


type Props = {

};

const ProfileView: FC<Props> = () => {
    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Профиль');
        app.setBreadcrumbs([])
    });

    return (<>
        <Heading />
        <React.Suspense fallback={<PageContentSkeleton />}>
            <ProfileContentView />
        </React.Suspense>
    </>);
};

export default observer(ProfileView);