import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Typography, Breadcrumbs as MuiBreadcrumbs, Skeleton, Stack } from "@mui/material";
import { range } from "lodash";

export type Breadcrumb = {
    readonly name: string;
    readonly to?: string;
};

type Props = {
    readonly items?: Breadcrumb[];
}

const Breadcrumbs = (p: Props) => {

    return (
        <MuiBreadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ py: 2, display: 'inline-flex' }}
        >
            {p.items ? undefined : (
                range(3).map(i => (
                    <Skeleton
                        variant="rounded"
                        width={62}
                        height={15}
                        key={i}
                    />
                )).concat(
                    <Skeleton
                        key={100}
                        variant="rounded"
                        width={180}
                        height={15}
                    />
                )
            )}

            {p.items?.map((b, index) => {
                return b.to ? (
                    <Link key={index} to={b.to}>
                        {b.name}
                    </Link>
                ) : (
                    <Typography key={index} color="text.primary">
                        {b.name}
                    </Typography>
                )
            })}
        </MuiBreadcrumbs>
    );
}

export default Breadcrumbs;