import { Stack, Chip } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { FilterPresets } from "../../../../store/catalog";

type Props = PropsWithChildren & {
    readonly presets?: FilterPresets;
};

const TopCategoriesSelect: FC<Props> = (p) => {

    return (
        <Stack direction="row" flexWrap="wrap" gap={1} alignItems="center">
            {p.children}
            {p.presets?.list.map(v => (
                <NavLink key={v.id} to={v.url}>
                    {({ isActive }) => (
                        <Chip
                            label={v.name}
                            clickable={!isActive}
                            color={isActive ? 'primary' : undefined}
                        />
                    )}

                </NavLink>
            ))}
        </Stack>
    );
}

export default observer(TopCategoriesSelect);